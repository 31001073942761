import {Button} from "antd";
import React from "react";
import style from "./style.module.scss";

const ActionButton = props => {
    const {
        children,
        className,
        ...buttonProps
    } = props

    return (
        <Button shape="circle" className={`${className} ${style['action-button']}`} {...buttonProps}>
            { children }
        </Button>
    )
}

export default ActionButton