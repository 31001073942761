import { FunctionPlugin } from "hyperformula";

export class NumberValue extends FunctionPlugin {
    numberValue(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('NUMBERVALUE'), (...args) => {
          const [text = '', decimal_separator = '.', group_separator = ','] = args
          return Number(String(text).replace(decimal_separator, '.').replace(group_separator, ''));
        })
      }
}

NumberValue.implementedFunctions = {
    'NUMBERVALUE': {
        method: 'numberValue',
        parameters: [
          {argumentType: "STRING"}
        ],
        repeatLastArgs: 1,
    }
}