import React from "react";
import Button from "react-bootstrap/Button";
// import style from "../style.module.scss";

const formatDeleteAction = onDeleteItem => (cell, row) => {
    return (
        <Button 
            size="xs" 
            variant="flat" // link
            // className={`far fa-trash-alt ${style['btn-action']}`} 
            className="text-primary far fa-trash-alt" 
            onClick={() => onDeleteItem(row)}
        />
    )
}

export default formatDeleteAction;