import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {Modal, Button, Form} from "react-bootstrap";
import Flex from "../../../Flex";
import { Form as FormWrapper, Formik } from "formik";
import * as Yup from "yup";
import RenderField from "../../../form/RenderField";
import IconPicker from "../../../IconPicker";
import useFormActions from "@utils/hooks/useFormActions";
import SIDEBAR_ICONS from "@constants/SIDEBAR_ICONS";
import { initRequest } from "@utils/actionMethods";
import message from "@utils/message";
import * as appMenuActions from "@modules/ApplicationMenu/_redux/applicationMenuActions";
import { mapGroupOptionsTree } from "@utils/Q";
import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";

const RenderForm = ({ formId, onCreate, formType }) => {
  const { loading, mlServices = [], getMLServices, mlProviders = [], getMLProviders } = useFormActions();
  
  const dispatch = useDispatch();
  const { trees, treesLoading } = useSelector(
    (state) =>  ({
      trees: state.applicationMenu.trees,
      treesLoading: state.applicationMenu.listLoading
    })    
  );
  const groupTree = useMemo(()=>{
    return mapGroupOptionsTree(trees);
  },[trees]);

  const [reservedModuleNames, setReservedModuleNames] = useState([]);
  const INIT_ICON = "fa-browser";
  const formikProps = {
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required")
           .max(40, "Name cannot exceed 40 characters")
           .test("name", "Name cannot start with number", (val) => !(/^\d/.test(val)))
           .test("name", "Name cannot contain special characters", (val) => !(SPECIAL_CHAR_REGEX.test(val)))
           .test("name", "Name cannot start with a space", (val) => !(/^ /.test(val))),
      description: Yup.string().max(250, "Value can not exceed 250 characters")
    }),
    initialValues: {
      name: "", // formName
      description: "",
      categoryId: "", // formCategories
    },
    onSubmit: (values, fn) => {
      // const payload = {...values};
      // fn.setSubmitting(false);
      if (reservedModuleNames.includes(values['name'])) {
        message.error("Name: " + values['name'] + ' is reserved system name, please use another name')
        fn.setSubmitting(false);
      } else {
        onCreate && onCreate(values, fn); // payload
      }
    },
  };

  useEffect(() => {
    let init = false;
    if (!init) {
      if (trees.length <=1 ) dispatch(appMenuActions.fetchTrees());
      if (mlProviders.length === 0) getMLProviders();
    }
    return () => {
      init = true;
    };
    // eslint-disable-next-line
  }, []);

  const request = initRequest()
  const getReservedModuleNames = async () => {
      try {
          const response = await request('reservedModuleNames')
          const { data, success } = response || {}
          if (success && data) {
            setReservedModuleNames(data)
          }
      } catch (e) {
          console.error(e)
      }
  }
  
  useEffect(() => {
    getReservedModuleNames()
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Formik {...formikProps}>
        {(formik) => (
          <FormWrapper noValidate id={formId}>
            {formik.isSubmitting && (
              <div className="spinner-border text-primary w-50px h-50px position-absolute m-auto position-full zi-upModal cwait" />
            )}

            <fieldset
              className={"mt-3-next" + (formik.isSubmitting ? " backdrop-after after-absolute cwait" : "")}
              disabled={formik.isSubmitting}
            >
              <RenderField
                name="name" // formName
                // id="formName" // If props / attribute name available id not required (formik docs, choose name or id)
                label="Name"
                placeholder="Enter name" // Enter process name
                required={true}
                type="text"
              />
              <RenderField
                name="description"
                label="Description"
                rows="5"
                placeholder="Enter description (optional)" // Enter process description (optional)
                type="textarea"
              />              
              <RenderField
                name="categoryId"
                type="treeselect"
                label="Group"
                options={groupTree}
                isLoading={treesLoading}
              />
            { formType === "APP" && <>
                <RenderField
                  name="ocrProviderId"
                  type="dropdown"
                  label="Machine Learning Provider"
                  options={mlProviders}
                  isLoading={loading.getMLProviders}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  closeMenuOnScroll={(e) => !e?.target?.classList?.contains("fs-r-select__menu-list")}
                  menuPlacement="auto"
                  className="selectCategoryModule"
                  classNamePrefix="fs-r-select"
                  onChange={(val) =>{
                    formik.setFieldValue("machineLearningServiceId",null);
                    const providerName = mlProviders.find(p => p.value === val)?.label;
                    formik.setFieldValue("ocrProviderName", providerName);
                    getMLServices({providerId:val});
                  }}
                />     
                {
                  formik.values.ocrProviderId &&
                  <RenderField
                    name="machineLearningServiceId"
                    type="dropdown"
                    label="Machine Learning Service"
                    options={mlServices}
                    isLoading={loading.getMLServices}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    closeMenuOnScroll={(e) => !e?.target?.classList?.contains("fs-r-select__menu-list")}
                    menuPlacement="auto"
                    className="selectCategoryModule"
                    classNamePrefix="fs-r-select"
                    isMulti={formik.values.ocrProviderName !== "OpenAI"}
                  />
                }
                   
              <div className="row">
                <label className="col-md-4 col-form-label">
                    App Icon
                </label>
                <div className="col-md-8"> 
                  <Flex dir="row">
                  
                    <Flex
                      align="center"
                      className={"px-2 bg-white border rounded-left"}
                    >
                      <i
                        className={`far ${
                          formik.values.appIcon
                            ? formik.values.appIcon
                            : INIT_ICON
                        }`}
                      />
                    </Flex>
                    <Form.Control
                      type="text"
                      spellCheck="false"
                      placeholder="Select Icon"
                      id="appIcon"
                      className="rounded-0"
                      value={formik.values.appIcon ? formik.values.appIcon : ""}
                      onChange={formik.handleChange}
                      disabled
                    />
                    <IconPicker
                      dropDownProp={{
                        alignRight: true,
                        drop: 'up',
                      }}
                      dropDownToggleProps={{
                        variant: "secondary",
                        className: "round-left-0",
                      }}
                      autoFocus
                      copy={false}
                      storageKey="sidebar_navigation_src"
                      type="font"
                      url={SIDEBAR_ICONS}
                      label="Icon"
                      onClickIcon={({ icon }) => {
                        let selectedIcon = icon?.path || icon?.name;
                        formik.setFieldValue("appIcon", selectedIcon);
                      }}
                    />
                  </Flex>
                </div>
              </div>       
              <RenderField
                name="addToQuickLaunch"
                id="addToQuickLaunch"
                label="Quick Launch - App"
                type="switch"
                checked={formik?.values?.addToQuickLaunch}
                style={{
                  marginTop: 10
                }}
              />
              </>}       
            </fieldset>
          </FormWrapper>
        )}
      </Formik>

      {/* <Modal
        centered
        scrollable
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
        show={modal}
        onHide={() => setModal(false)}
      >
        <Formik
          enableReinitialize
          validationSchema={Yup.object({
            category_name: Yup.string().required("This field is required"),
          })}
          initialValues={{
            category_name: "",
            category_description: "",
            category_icon: "",
          }}
          onSubmit={onSaveCategory}
        >
          {(formik) => (
            <>
              <Modal.Header closeButton className="align-items-center py-2">
                <Modal.Title as="h5">Create new group</Modal.Title>
              </Modal.Header>

              <Modal.Body className="bg-light">
                <FormWrapper noValidate id={formId + "_newCategory"}>
                  <fieldset disabled={formik.isSubmitting} className="mt-3-next">
                    <div>
                      <label htmlFor="category_name">Group name</label>
                      <Form.Control
                        type="text"
                        isInvalid={formik.errors.category_name && formik.touched.category_name}
                        id="category_name"
                        value={formik.values.category_name}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.category_name && formik.touched.category_name && (
                        <div className="invalid-feedback">{formik.errors.category_name}</div>
                      )}
                    </div>

                    <div>
                      <label htmlFor="category_description">Group description</label>
                      <Form.Control
                        as="textarea"
                        id="category_description"
                        value={formik.values.category_description}
                        onChange={formik.handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="category_icon">Group icon</label>
                      <Flex dir="row">
                        <Flex align="center" 
                          style={{ minWidth: 35 }} 
                          className={"before-fs-2 select-icon-nav px-2 bg-white border rounded-left " + formik.values.category_icon} 
                        />
                        <Form.Control
                          type="text"
                          spellCheck="false"
                          placeholder="Select icon"
                          id="category_icon"
                          // className={"form-control form-control-sm round-right-0" + formikValidClass(formik, "category_icon")}
                          className="rounded-0" // round-right-0
                          value={formik.values.category_icon}
                          onChange={formik.handleChange}
                        />
                        <IconPicker
                          dropDownProp={{
                            alignRight: true,
                            // bsPrefix: "btn-group"
                          }}
                          dropDownToggleProps={{
                            // size: "sm",
                            variant: "secondary", // light
                            className: "round-left-0",
                          }}
                          dropDownMenuProp={{
                            popperConfig: {
                              strategy: "fixed",
                            }
                          }}
                          autoFocus
                          copy={false}
                          // cache={false}
                          storageKey="sidebar_navigation_src"
                          type="font"
                          url={SIDEBAR_ICONS}
                          label="Icon"
                          // onHoverIcon={(icon) => { // , e
                          //   console.log('onHoverIcon icon: ', icon);
                          //   // console.log('onHoverIcon e: ', e);
                          // }}
                          onClickIcon={({ icon }) => {
                            // console.log('onClickIcon icon: ', icon);
                            // let selectedIcon = icon?.path || icon?.name;
                            formik.setFieldValue("category_icon", icon?.path || icon?.name);
                          }}
                        />
                      </Flex>
                    </div>
                  </fieldset>
                </FormWrapper>
              </Modal.Body>

              <Modal.Footer as="fieldset" disabled={formik.isSubmitting} className="py-1">
                <Button onClick={() => setModal(false)} size="sm" variant="secondary">
                  Cancel
                </Button>
                <Button form={formId + "_newCategory"} type="submit" size="sm">
                  Save
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal> */}
    </>
  );
};

const CreateFromScratchModal = ({
  onClose,
  onCreate,
  title = "Create App",
  show = false,
  formType
}) => {
  const formId = "form-create-from-scratch_" + Date.now();

  return (
    <Modal
      centered
      scrollable
      backdrop="static"
      keyboard={false} 
      size="lg" 
      show={show} // true
      // onHide={onClose} 
    >
      <Modal.Header 
        // closeButton 
        className="align-items-center py-2"
      >
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="position-static">
        <RenderForm onCreate={onCreate} formId={formId} formType={formType} />
      </Modal.Body>

      <Modal.Footer className="py-1">
        <Button onClick={onClose} size="sm" variant="danger">
          Cancel
        </Button>
        <Button form={formId} type="submit" size="sm" variant="success">
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateFromScratchModal.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
};

export default CreateFromScratchModal;
