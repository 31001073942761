import React from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { message, Button } from "antd";
import * as Yup from "yup";
import { Form as FormWrapper, Formik, useField } from "formik";
import useApiRequest from "@utils/hooks/useApiRequest";
import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";

const RenderField = prop => {
    const [field, meta, helpers] = useField(prop)
    const { touched, error } = meta
    const typeProp = prop.type === 'text'
        ? { type: 'text' }
        : { as: prop.type }
    const onSelect = selected => {
        const selectedValues = selected.map(({ value }) => value)
        helpers.setValue(prop.isMulti ? selectedValues : selected.value)
    };
    const selectedValue = (prop.options || []).filter(({ value }) => {
        return (field.value || []).includes(value) || field.value === value
    });

    return (
        <div>
            <label>{prop.label} {prop.required && <sup className="font-weight-bold text-danger"> *</sup>}</label>
            {
                prop.type === 'dropdown'
                    ? (
                        <Select
                            {...prop}
                            onChange={onSelect}
                            value={selectedValue}
                        />
                    )
                    : (
                        <Form.Control
                            isInvalid={touched && error}
                            {...prop}
                            {...field}
                            {...typeProp}
                        />
                    )
            }
            {
                touched && error
                    ? (
                        <Form.Control.Feedback type="invalid">
                            { error }
                        </Form.Control.Feedback>
                    )
                    : null
            }
        </div>
    );
}

const RenderForm = ({ formId, request, loading, data }) => {
    const history = useHistory()
    const isAdvance = data?.url == "" ? 'advance' : 'simple';
    const formikProps = {
        enableReinitialize: true,
        validationSchema: Yup.object({
            newConnectorName: Yup.string().required("Connector Name is required")
                .max(40, "Connector Name cannot exceed 40 characters")
                .test("newConnectorName", "Connector Name cannot contain special characters", (val) => !(SPECIAL_CHAR_REGEX.test(val)))
                .test("newConnectorName", "Connector Name cannot start with a space", (val) => !(/^ /.test(val))),
            newConnectorDescription: Yup.string()
                .test("newConnectorDescription", "Connector Description cannot contain special characters", (val) => !(SPECIAL_CHAR_REGEX.test(val)))
                .test("newConnectorDescription", "Connector Description cannot start with a space", (val) => !(/^ /.test(val)))
                .test("newConnectorDescription", "Connector Description cannot start with number", (val) => !(/^\d/.test(val)))
                .nullable(),
        }),
        initialValues: {
            newConnectorName: '',
            newConnectorDescription: ''
        },
        onSubmit: async (values, fn) => {
            const payload = {
                data: {
                    connector_name: values.newConnectorName,
                    connector_description: values.newConnectorDescription,
                    data_connector_id: data?.id
                }
            }
            fn.setSubmitting(true);
            await request(
                'duplicateDataConnector',
                payload,
                ({ data, success, message: responseMessage }) => {
                    message[success ? 'success' : 'error'](responseMessage)
                    if (success) {
                        history.push({
                            pathname: `/data-connector/${isAdvance}/${data?.id}`,
                            state: {
                                modal: {},
                            },
                        })
                    }
                }
            )
            fn.setSubmitting(false)
        }
    }

    return (
        <Formik {...formikProps}>
            {
                formik => (
                    <FormWrapper noValidate id={formId}>
                        <fieldset className="mt-3-next" disabled={formik.isSubmitting}>
                            <RenderField
                                name="newConnectorName"
                                label="Connector Name"
                                placeholder="Enter new connector name"
                                required={true}
                                disabled={loading.duplicateDataConnector}
                                type="text"
                            />
                            <RenderField
                                name="newConnectorDescription"
                                label="Connector Description"
                                placeholder="Enter new connector description"
                                required={false}
                                disabled={loading.duplicateDataConnector}
                                type="textarea"
                            />
                        </fieldset>
                    </FormWrapper>
                )
            }
        </Formik>
    );
}

const DuplicateDataConnector = props => {
    const { request, loading } = useApiRequest()
    const { onClose, show = false, data } = props;
    const formId = 'form-duplicate-connector';

    return (
        <Modal
            centered
            scrollable
            backdrop="static"
            keyboard={false}
            show={show}
            aria-labelledby="fs-modal-create-from-scratch"
        >
            <Modal.Header className="align-items-center py-4">
                <Modal.Title as="h5" id="fs-modal-create-from-scratch">Duplicate Connector</Modal.Title>
                <button onClick={onClose} className="close ml-0" title="Close" type="button">&times;</button>
            </Modal.Header>

            <Modal.Body>
                <RenderForm formId={formId} data={data} request={request} loading={loading} />
            </Modal.Body>

            <Modal.Footer className="py-4 justify-content-end">
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" loading={loading.duplicateDataConnector} form={formId} htmlType="submit">Duplicate</Button>
            </Modal.Footer>
        </Modal>
    );
}

DuplicateDataConnector.propTypes = {
    onClose: PropTypes.func,
};

export default DuplicateDataConnector;
