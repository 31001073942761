const TABLE_FILTER_OPERATORS = [
  { i: 0, value: "contains", label: "Contains" },
  { i: 1, value: "doesNotContain", label: "Does not contain" },
  { i: 2, value: "eq", label: "Equal to" },
  { i: 3, value: "neq", label: "Not equal to" },
  { i: 4, value: "lt", label: "Less than" },
  { i: 5, value: "gt", label: "Greater than" },
  { i: 6, value: "lte", label: "Less than or equal to" },
  { i: 7, value: "gte", label: "Greater than or equal to" },
  { i: 8, value: "beginswith", label: "Begins with" },
  { i: 9, value: "endswith", label: "Ends With" },
  { i: 10, value: "doesnotbeginwith", label: "Does not begin with" },
  { i: 11, value: "doesnotendwith", label: "Does not end with" },
  { i: 12, value: "isempty", label: "Is empty" },
  { i: 13, value: "isnotempty", label: "Is not empty" },
  { i: 14, value: "in", label: "In" },
  { i: 15, value: "notin", label: "Not in" },
  { i: 16, value: "between", label: "Between" },
  { i: 17, value: "notbetween", label: "Not between" }
];

const FILTERED_TEXT = [0,1,2,3,8,9,10,11,12,13]; // text
const FILTERED_DATETIME = [4,5,6,7,16,17]; // datetime
const FILTERED_NUMBER = [2,3,4,5,6,7,12,13,16,17]; // number
const FILTERED_LOOKUP = [2,3,12,13]; // lookup
const FILTERED_EXTERNAL = [0,1,12,13]; // external lookup
const FILTERED_USER = [0,1,12,13]; // external lookup

const FILTERED_OPERATORS = {
  text: FILTERED_TEXT,
  number: FILTERED_NUMBER,
  datetime: FILTERED_DATETIME,
  lookup: FILTERED_LOOKUP,
  external: FILTERED_EXTERNAL,
  user: FILTERED_USER
};

const TABLE_FILTER_CONDITION = {
  operator: TABLE_FILTER_OPERATORS,
  foperator: FILTERED_OPERATORS
};

export default TABLE_FILTER_CONDITION