export const getSectionSchemas = (sections = []) => {
    return sections.reduce((acc, sh) => {
        let sectionName = sh.name
        if (acc.hasOwnProperty(sectionName)) {
            sectionName = `${sh.name}_1`
        }
        return {
            ...acc,
            [sectionName]: sh.rows?.reduce((shRows, row) => {
                return [
                    ...shRows,
                    ...row.fields?.reduce((rFields, field) => {
                        if (field?.fieldType === 'empty-col') {
                            return rFields
                        }
                        return [
                            ...rFields,
                            field.id,
                        ]
                    }, [])
                ]
            }, [])
        }
    }, {})
}

export default {
    getSectionSchemas,
}