import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import style from "./style.module.scss";

const FooterDropdownBody = (props, ref, onSetFooterKey) => {
    const { style: customToggleStyle, className, 'aria-labelledby': labeledBy } = props

    return (
        <div
            ref={ref}
            style={customToggleStyle}
            className={`${className} ${style['dropdown-menu']}`}
            aria-labelledby={labeledBy}
        >
            <ListGroup>
                <ListGroup.Item action onClick={() => onSetFooterKey('count')}>
                    Count
                </ListGroup.Item>
                <ListGroup.Item action onClick={() => onSetFooterKey('blank')}>
                    Blank
                </ListGroup.Item>
                <ListGroup.Item action onClick={() => onSetFooterKey('nonBlank')}>
                    Non blank
                </ListGroup.Item>
            </ListGroup>
        </div>
    );
};

export default FooterDropdownBody