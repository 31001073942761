import React, { useEffect, useState } from "react";
import {Input, InputNumber} from "antd";
import isNull from "lodash/isNull";

export const addThousandSeparator = (value, separator = ',', decimalSeparator = '.') => {
    const [mainValue, decimal] = String(value).split(decimalSeparator)
    const suffix = decimal 
        ? `${decimalSeparator}${decimal}`
        : ''
    const processedMainValue = mainValue.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
    return `${processedMainValue}${suffix}`
}

export const getRawValue = ({ rawValue, decimalPlaces }) => {
    const decimalSeparator = '.'
    const [mainValue, decimal = ''] = String(rawValue).split(decimalSeparator)
    return decimalPlaces > 0 && decimal.length < decimalPlaces
        ? `${mainValue}${decimalSeparator}${decimal.padEnd(decimalPlaces, '0')}`
        : rawValue
}

export const formatter = (rawValue, info, format, currencySign = '$', decimalPlaces = 0, name = '') => {
    if (info?.userTyping) {
        return info?.input;
    }
    if(rawValue === "" || isNull(rawValue) || isNaN(rawValue)) return "";
    const value = getRawValue({ rawValue, decimalPlaces, name })
    switch (format) {
        case 'Thousand Separated':
            return addThousandSeparator(value)
        case 'Currency':
            return addThousandSeparator(`${currencySign ?? "$"} ${value}`)
        case 'Percentage':
            return `${value}%`
        case 'Accounting':
            const absoluteValue = Math.abs(value)
            return `${value < 0 ? `(${absoluteValue})` : value}`
        case 'None':
        default:
            return value
    }
}

export const parser = (rawValue, format) => {
    const value = rawValue
    switch (format) {
        case 'Thousand Separated':
            return Number(value.replace(/(,*)/g, ''))
        case 'Currency':
            return Number(value.replace(/\$|\£\s?|(,*)/g, ''))
        case 'Percentage':
            return Number(value.replace('%', ''))
        case 'Accounting':
            const original = value.replace(/\(|\)|(,*)/g, '')
            return value.match(/\((.*)\)/g)
                ? Number(original) * -1
                : Number(original)
        case 'None':
        default:
            return Number(value)
    }
}

const NumberInput = (props) => {
    const {config, value, name, disabled} = props;
    const [val, setValue] = useState(value);
    const [hasFocused, setHasFocused] = useState(false);
    const {format, currencySign, decimalPlaces} = config;

    const onChange = (val) => {
        setValue(val);
    }

    useEffect(() => {
        setValue(
            [undefined, null].includes(value) 
                ? "" 
                : value
        )
    }, [value])

    if(value && isNaN(value) && config.fieldType !== 'calculation-number'){
        return <Input value={"Invalid Number"} onChange={() => {props.onChange("")}} allowClear/>
    }
    return (
        <InputNumber
            {...props}
            onChange={onChange}
            tabIndex={1}
            onBlur={(e) => {
                e.persist()
                props?.onBlur && props.onBlur(e)
                if (val !== value) {
                    setHasFocused(true)
                    props?.onChange && props.onChange(val)
                }
            }}
            value={val}
            onClick={() => {}}
            formatter={(hasFocused || !isNaN(val) || disabled) ? (value, info) => formatter(value, info, format, currencySign, decimalPlaces, name) : () => {}}
            parser={
                props.value
                    ? value => parser(value, format, decimalPlaces)
                    : undefined
            }
        />
    )
}

export default React.memo(NumberInput);

