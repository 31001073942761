import { useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import usePrevious from "./usePrevious";
import parseValue from "../parse-value";
import {calculatePercentageValue} from "../number";
import {INIT_FIELD_TYPES} from "../../app/components/form-editor/const";
import calculateAggregation, { encodeFilters } from "../calculateAggregation";
import { textWithinFormula, replaceNotWrappedByDoubleQuotes, replaceSingleQuotesThatWrapAText } from "../sheetRegex";
import { useParams } from "react-router-dom";
import useTimeZone from "@utils/hooks/useTimeZone";
import { findDuplicates } from "@utils/Q";

const numberFields = INIT_FIELD_TYPES.filter(f => f.value === 0)

const useSheet = (props) => {
    const { user } = useSelector(({ auth }) => ({ user: auth?.config?.userInfo }));
	const timezone = useTimeZone();
    const { toLocalDateTimeStr, timezoneId: userTimezone, datetime_format } = timezone;
    const { recordId } = useParams();

    const {
        setValues,
        setSequenceValues,
        values,
        fields,
        tables,
        moduleInfo = {},
        moduleStates = {},
        dispatch,
        permission,
        activityId,
    } = props
    const [parsedFormulas, setParsedFormulas] = useState({})
    const previousParsedFormulas = usePrevious(parsedFormulas)
    const [previousValues, setPreviousValues] = useState({})
    const sequenceFieldSuffix = '_sequence_formula'
    const {sheetsMap: fieldsOnSheets, hfInstance} = moduleStates
    const [ dependencyFieldsMap, setDependencyFieldsMap ] = useState({})
    const [ firstEvaluate, setFirstEvaluate ] = useState(true)
    const valuesRef = useRef()

    const GenerateDependencyFieldsMap = (fields) => {
        // generate dependency map between fields
        // e.g formula:
        // net = price * quantity
        // tax = net * 10%
        // gross = net + tax
        // expected result: {"price": ["net"], quantity: ["net"], net: ["tax", "gross"], tax: ["gross"]}
        // note: result arrays should be in `field` type instead of string
        const relatedFields = {}
        for (let index = 0; index < fields.length; index++) {
            const field = fields[index];
            if(field.formula) {
                for (let j = 0; j < fields.length; j++) {
                    if(field.formula.includes(fields[j].id)) {
                        relatedFields[fields[j].id] = [...(relatedFields[fields[j].id] || []), field]
                    }
                }
            }
        }
        return relatedFields
    }

    const setIsCalculating = (state, callback) => {
        const execute = () => {
            dispatch({
                type: 'SET_IS_CALCULATING',
                payload: {
                    isCalculating: state,
                },
            })
            callback && hfInstance.batch(() => {
                callback()
            })
        }
        setTimeout(execute, state === false ? 500 : 0)
    }
    const tableFields = tables.reduce((acc, t) => {
        return [
            ...acc,
            ...(t?.fields ||[]),
        ]
    }, [])
    const allFields = [
        ...fields,
        ...tableFields,
    ]

    useEffect(()=> {
        const relatedFields = GenerateDependencyFieldsMap(allFields)
        setDependencyFieldsMap(relatedFields)
    }, [])

    const systemFields = [
        'id',
        'created_on',
        'created_at',
        'created_by',
        'creator_email',
        'modified_on',
        'modified_at',
        'modified_by',
        'user_email',
        'user_name',
        'workflow_status'
    ]
    const tableSystemFields = [
        'row_id'
    ]
    const fieldAliases = allFields.reduce((acc, f) => {
        return {
            ...acc,
            [f.id]: f.id.replace(/(\d)/g, '_$1'),
        }
    }, {})
    const coerceStringValue = text => {
        return typeof text === 'string' && !textWithinFormula(text)
            ? `"${text}"`
            : text
    }
    const setNamedExpression = ({field, value, table, rowIndex = 0}) => {
        let safeFieldId = fieldAliases?.[field.id] || field.id
        const isTableRow = table && typeof rowIndex !== 'undefined'

        let expressionName = isTableRow
            ? `table__${table}__${safeFieldId}__${rowIndex}`
            : safeFieldId

        let expressionValue = value
        function resolveExpressionValue () {
            try {
                const cellAddress = getCellAddress(field.id, rowIndex)
    
                // Named expressions from system fields are registered using setSystemFieldExpression.
                if (systemFields.includes(field.id) && !tableSystemFields.includes(field.id)) {
                    return
                }
    
                if (cellAddress?.sheetName && hfInstance.doesSheetExist(cellAddress?.sheetName)) {
                    const cellAddressString = hfInstance.simpleCellAddressToString(cellAddress, cellAddress?.sheet)
                    if (cellAddressString) {
                        const absoluteAddr = cellAddressString.replace(/([A-Z]+)(\d+)/g, (match, c, r) => `$${c}$${r}`)
                        expressionValue = `=${cellAddress?.sheetName}!${absoluteAddr}`
                    }
                }
            } catch (e) {
                console.error(e)
            }
        }
        resolveExpressionValue()
        try {
            if (hfInstance.isItPossibleToAddNamedExpression(expressionName)) {
                hfInstance.addNamedExpression(expressionName, expressionValue)
            } else if (hfInstance.isItPossibleToChangeNamedExpression(expressionName)) {
                hfInstance.changeNamedExpression(expressionName, expressionValue)
            }
        } catch (e) {
            console.error(e)
        }
    }
    const setSystemFieldExpression = () => {
        systemFields.forEach(key => {
            const dateKeys = [
                'created_on',
                'created_at',
                'modified_on',
                'modified_at',
            ]
            let moduleInfoValue = null
            if (moduleInfo) {
                switch(key) {
                    case 'created_at':
                    case 'created_on':
                        moduleInfoValue = moduleInfo['created_on']
                        break;
                    case 'modified_at':
                    case 'modified_on':
                        moduleInfoValue = moduleInfo['modified_on'] || moduleInfo['created_on']
                        break;
                    case 'created_by':
                        moduleInfoValue = moduleInfo['record_created_by'] || user?.fullname
                        break;
                    case 'modified_by':
                        moduleInfoValue = moduleInfo['record_modified_by'] || user?.fullname
                        break;
                    case 'creator_email':
                        moduleInfoValue = moduleInfo['record_created_email'] || user?.email
                        break;
                    case 'user_email':
                        moduleInfoValue = user?.email
                        break;
                    case 'user_name':
                        moduleInfoValue = user?.username
                        break;
                    default:
                        moduleInfoValue = moduleInfo[key]
                        break;
                }
            }
            try {
                let expressionValue = moduleInfoValue
                if (moduleInfoValue) {
                    expressionValue = dateKeys.includes(key)
                        ? toLocalDateTimeStr(moduleInfoValue)
                        : moduleInfoValue
                }
                setNamedExpression({
                    field: { id: key },
                    value: expressionValue || '""',
                })
            } catch (e) {
                console.error(e)
            }
        })
    }
    const validFormula = formulaValue => {
        return (
            /^=(.*)/.test(formulaValue)
                ? formulaValue
                : `=${formulaValue}`
        )
    }
    const validFormulaValue = (value, formula, isSequenceNumber) => {
        const rawFormula = String(formula).replace(/[="]/g, '')
        const mayBeFormulaValue = values?.[rawFormula]
        const fallbackValue = isSequenceNumber
            ? coerceStringValue(rawFormula)
            : mayBeFormulaValue || ''
        return ([undefined, null].includes(value) || value?.type === 'ERROR')
            ? fallbackValue
            : value
    }
    const transformFormulaByField = field => {
        switch (field.fieldType) {
            case 'calculation-text':
                const formulaField = allFields.find(f => f.id === field.formula)
                const formulaFieldIsNumber = formulaField && numberFields.find(f => f.fieldType === formulaField.fieldType)
                const formulaIsText = /^=?TEXT\((.*)/gi.test(field.formula)
                return formulaFieldIsNumber && !formulaIsText
                    ? validFormula(`TEXT(${field.formula}, "0")`)
                    : field.formula
            default:
                return field.formula
        }
    }
    const normalizeExternalFormula = (formula, field, row) => {
        let normalized = formula || ''
        try{
            const allFieldsIds = allFields.map(f => f.id)
            const isTableField = [...tableFields, ...tableSystemFields].includes(field);
            const regs = [{
                pattern: /(\w+).extract[T|t]ext\(['"]+\$[.]*([\w\[\].]+)['"]+[,]*[ 0-9]*\)/gi,
                replacement: `EXTRACTTEXT($1,"$2","${field?.fieldType}")`,
            }, {
                pattern: /JSON.parse\((\w+)\)\.['"]*([\w\s\[\].]+)['"]*/gi,
                replacement: `EXTRACTTEXT($1,"$2","${field?.fieldType}")`,
            }, {
                pattern: /SUM\(([\w_]+)\.+([\w_]+)[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, tableName, columnName, summaryType = 'sum') => {
                    const columnValues = (values?.[tableName] || []).map(r => {
                        const cellValue = r?.[columnName]
                        return coerceStringValue(cellValue)
                    })
                    return `GETSUMMARY("${summaryType}",${columnValues})`
                },
            }, {
                pattern: /SUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, moduleId, tableName, columnName, summaryType = 'sum') => {
                    return `GETSUMMARYAPP("${moduleId}","${tableName}","${columnName}","${summaryType}")`
                },
            }, {
                pattern: /DSUM\(([\w_]+)\.+([\w_]+)[, ]*(\w+=["]*\w+["]*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, tableName, columnName, filter, summaryType = 'sum') => {
                    const columnValues = (values?.[tableName] || [])
                        .filter((r, index, array) => {
                            const criterias = filter && filter.match(/(\w+)+([=]+)"*(\w+)"*/i)
                            if (criterias) {
                                const [, cField, cOperator, cValue] = criterias
                                switch (cOperator) {
                                    case '=':
                                    default:
                                        // dynamic matcher is when the matcher is of a field id.
                                        // value needs to be evaluated first before being used as filter.
                                        const isDynamicMatcher = allFieldsIds.includes(cValue)
                                        // if row is not undefined, it is a cell value
                                        const filterValue = row !== undefined ? array?.[row]?.[cValue] : values?.[cValue]
                                        let matcher = isDynamicMatcher ? filterValue : cValue                                    
                                        if(typeof r?.[cField] === 'number')
                                            matcher = Number(matcher);
    
                                        return r?.[cField] === matcher
                                }
                            }
                            return true
                        })
                        .map(r => {
                            const cellValue = r?.[columnName]
                            return coerceStringValue(cellValue)
                        })
                    return `GETSUMMARY("${summaryType}",${columnValues})`
                },
            }, {
                pattern: /DUPLICATE_CHECK\(([\w_]+)\.+([\w_]+)[, "]*(insensitive)*[ "]*\)/gi,
                replacement: (_, tableName, columnName, summaryType) => {
                    const isInsensitive = summaryType === 'insensitive'
                    const isField = fields.find(f => f?.id === field?.id) !== undefined
                    const columnValues = compact((values?.[tableName] || [])
                    .map(r => {
                        const cellValue = r?.[columnName]
                        return coerceStringValue(cellValue)
                    }))
                    const duplicated = findDuplicates(columnValues, isInsensitive)
                    const resultColumn = columnValues.map(val => {
                        const value = isInsensitive ? val.toLowerCase() : val
                        if (duplicated.includes(value)) {
                            return `TRUE()`
                        }else{
                            return `FALSE()`
                        }
                    })
                    if(isField){
                        return `DUPLICATECHECK(${duplicated.length > 0 ? duplicated : ['""']})`
                    }
                    return resultColumn[row]
                },
            },{
                pattern: /DSUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, ]*([\w_.]+[!=<>]+["]*[\w_ ]+["]*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, moduleId, tableName, columnName, filter, summaryType = 'sum') => {
                    const fieldValueMap = isTableField? values?.[tableName][row]: generateFieldValueMap(fields)
                    const { encodedFilter: filterConfig } = encodeFilters(filter, fieldValueMap)
                    return `GETSUMMARYAPP("${moduleId}","${tableName}","${columnName}","${summaryType}"${filterConfig})`
                },
            },{
                pattern: /DSUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, ]*((and|or)*\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+[, ]*)+\)*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, moduleId, tableName, columnName, filter, matchType, matchField1, matchField2, summaryType = 'sum') => {
                    const fieldValueMap = isTableField? values?.[tableName][row]: generateFieldValueMap(fields)
                    const { encodedFilter: filterConfig } = encodeFilters(filter, fieldValueMap)
                    return `GETSUMMARYAPP("${moduleId}","${tableName}","${columnName}","${summaryType}"${filterConfig})`
                },
            },{
                pattern: /DSUMAPP\(([\w_]+)\.+([\w_]+)[, ]*([\w_.]+[!=<>]+["]*[\w_ ]+["]*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, moduleId, columnName, filter, summaryType = 'sum') => {
                    let tableName = undefined
                    const fieldValueMap = generateFieldValueMap(allFields)
                    const { encodedFilter: filterConfig } = encodeFilters(filter, fieldValueMap)
                    return `GETSUMMARYAPP("${moduleId}","${tableName}","${columnName}","${summaryType}"${filterConfig})`
                },
            },{
                pattern: /DSUMAPP\(([\w_]+)\.+([\w_]+)[, ]*((and|or)*\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+[, ]*)+\)*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/gi,
                replacement: (match, moduleId, columnName, filter, matchType, matchField1, matchField2, summaryType = 'sum') => {
                    let tableName = undefined
                    const fieldValueMap = generateFieldValueMap(allFields)
                    const { encodedFilter: filterConfig } = encodeFilters(filter, fieldValueMap)
                    return `GETSUMMARYAPP("${moduleId}","${tableName}","${columnName}","${summaryType}"${filterConfig})`
                },
            }]
            regs.forEach(reg => {
                normalized = normalized.replace(reg.pattern, reg.replacement)
            })
            const fieldReference = (fields || []).find(f => f.id === formula)
            if (fieldReference?.fieldType === 'sequence-number' && 
                values?.[`${fieldReference?.id}`]) {
                normalized = `"${values?.[`${fieldReference?.id}`]}"`
            }
            if(field){
                const { id = '' } = field
                const value = (val) => activityId === 'fs-start' ? undefined: val
                const shouldSaveTimezone = ['now()', 'today()'].includes(normalized.toString().toLowerCase())
                normalized = normalized.replace(/NOW\(\)/gi, `NOW("${value(shouldSaveTimezone? values[id]: moduleInfo['created_on'])}","${userTimezone}","${datetime_format}","${recordId}","${id}","${shouldSaveTimezone}")`)
                normalized = normalized.replace(/TODAY\(\)/gi, `TODAY("${value(moduleInfo['created_on'])}","${userTimezone}","${datetime_format}","${recordId}","${id}","${shouldSaveTimezone}")`)
            }
        } catch (e){
            console.error(e)
        }
        return normalized
    }
    const getFieldValue = (value, field) => {
        let fieldValue = value
        const isJSONMode = field.dataType === 'JSON'
        const isNumber = field.fieldType === 'number'
        const isSummary = field.fieldType === 'aggregation'
        const isObjectValue = typeof fieldValue === 'object'
        if (Boolean(fieldValue)) {
            switch(true) {
                case isNumber:
                    fieldValue = calculatePercentageValue(fieldValue, field.format)
                    break
                case isSummary:
                    const aggregateTarget = tableFields.find(f => f.id === field?.fieldToAggregate)
                    fieldValue = aggregateTarget?.format === 'Percentage'
                        ? calculatePercentageValue(fieldValue, aggregateTarget?.format)
                        : fieldValue
                    break
                case isObjectValue:
                case isJSONMode:
                    fieldValue = JSON.stringify(fieldValue)
                    break
                default:
                    break
            }
        }
        return parseValue(fieldValue, field.fieldType, null, timezone)
    }
    const generateFieldValueMap = (fields) => {
        let result = {}
        fields.forEach(field => {
            result[field.id] = getFieldValue(values[field.id], field)
        });
        return result
    }
    const getCellAddress = (id, rowIndex = 0) => {
        const currentSheet = fieldsOnSheets[id]?.[0]
        const sheetId = fieldsOnSheets[id]?.[1]
        const colId = fieldsOnSheets[id]?.[2]
        
        return currentSheet?.length > 0
            ? {
                sheet: sheetId,
                sheetName: currentSheet,
                col: colId,
                row: rowIndex,
            }
            : {}
    }
    const getFormulaValue = (field, row, table) => {
        const getFormula = () => {
            return normalizeExternalFormula(transformFormulaByField(field), field, row)
        }
        const fieldFormula = () => {
            return replaceNotWrappedByDoubleQuotes(getFormula(), function(match){
                    return fieldAliases?.[match] || match
                })
                .replace(/\[/g, '{')
                .replace(/\]/g, '}')
        }
        const columnFormula = () => {

            return replaceNotWrappedByDoubleQuotes(getFormula(), function(match){
                const sectionFieldIds = fields.map(({ id }) => id)
                const tableFieldIds = tableFields.map(({ id }) => id)
                const isSectionField = sectionFieldIds.includes(match);
                const isTableField = [...tableFieldIds, ...tableSystemFields].includes(match);
                
                if(isSectionField) 
                    return fieldAliases?.[match] || match;
                if(isTableField) {
                    return match.replace(new RegExp(`(${match})+`, 'g'), function(m) {
                        const safeFieldId = fieldAliases?.[m] || m
                        return `table__${table}__${safeFieldId}__${row}`
                    })
                }
                return match;
            })
            .replace(/\[/g, '{')
            .replace(/\]/g, '}')
        }
        const formulaValue = typeof row !== 'undefined' && table
            ? columnFormula()
            : fieldFormula()
        return replaceSingleQuotesThatWrapAText(validFormula(formulaValue), '"')
    }
    const isNumberString = (value, type) => {
        return typeof value === "string" && (!isNaN(Number(value)) && type?.includes('text') || value?.match(/^0\d\d*/));
    }
    const valueByType = (value, type) => {
        if(isNumberString(value, type)){
            return `'${value}`;
        }
        switch (type) {
            case 'file':
            case 'multi-file':
                let fileValue = null
                try {
                    fileValue = JSON.parse(value).map(f => f?.name).join(', ')
                } catch {
                    fileValue = value
                }
                return fileValue
            default:
                return value
        }
    }
    const setHfValue = (field, row = 0, table) => {
        try {
            const {id: fieldId, formula, isComputedField, fieldType} = field
            if (hfInstance) {
                const { sheet, sheetName, col } = getCellAddress(fieldId, row)
                const fieldValue = table
                    ? values[table]?.[row]?.[fieldId]
                    : values[fieldId]
                const cellValue = formula && (isComputedField || fieldType === 'sequence-number')
                    ? getFormulaValue(field, row, table)
                    : getFieldValue(fieldValue, field)
                if (sheet >= 0 && col >= 0 && hfInstance.doesSheetExist(sheetName)) {
                    hfInstance.setCellContents({
                        col, row, sheet
                    }, [[
                        valueByType(cellValue, field.fieldType)
                    ]])
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const setParsedFormulaValues = (parsed = {}) => {
        if (!isEqual(previousParsedFormulas, parsed)) {
            const formulaValues = Object.entries(parsed).reduce((acc, v) => {
                const [key, value] = v
                if (Array.isArray(value)) {
                    const currentRows = [...values[key] || []]
                    value.forEach((row, rowIndex) => {
                        currentRows.splice(rowIndex, 1, {
                            ...currentRows[rowIndex],
                            ...row,
                        })
                    })
                    return {
                        ...acc,
                        [key]: currentRows,
                    }
                }
                return {
                    ...acc,
                    [key]: value,
                }
            }, {})
            // set values based on valuesRef toget current realtime values
            const newValues = {
                ...valuesRef.current,
                ...formulaValues,
            }
            const sequenceValues = Object.entries(newValues).reduce((acc, v) => {
                if (v[0].match(sequenceFieldSuffix)) {
                    return {
                        ...acc,
                        [v[0]]: v[1],
                    }
                }
                return acc
            }, {})
            setValues && setValues(newValues)
            setPreviousValues(newValues)
            setSequenceValues && setSequenceValues(sequenceValues)

            // commit evaluated values to the store
            dispatch({
                type: 'SET_TABLE_VALUES',
                payload: newValues,
            })
        }
    }

    const findChangedFields = (values, previousValues) => {
        const changedFields = new Set();
        const changedTables = new Set();
    
        const addChangedField = (field, row, rowField) => {
            // will be used to render specific row
            changedFields.add(`${field}.${row}.${rowField}`);
            // will be used if a section field depended on this row field
            changedFields.add(rowField);
        };
    
        const processTable = (key, value, prevValue) => {
            for (let row = 0; row < value.length; row++) {
                if (!isEqual(value?.[row], prevValue?.[row])) {
                    if (prevValue?.[row]) {
                        // find which field has been changed in the current row
                        const { changedFields: changedRowFields = [] } = findChangedFields(value?.[row], prevValue?.[row]);
                        changedRowFields.forEach(rowField => addChangedField(key, row, rowField));
                    }
                    changedTables.add({ name: key, row });
                }
            }
        };
    
        for (const [key, value] of Object.entries(values)) {
            // find which fields and tables that have been changed
            if (!isEqual(value, previousValues[key])) {
                if (key.includes('table') && Array.isArray(value)) {
                    processTable(key, value, previousValues[key]);
                }
                changedFields.add(key);
            }
        }
    
        return {
            changedFields: Array.from(changedFields),
            changedTables: Array.from(changedTables)
        };
    };

    const parseFormula = () => {
        /**
         * Speed up calculation processes by suspending evaluation
         * once it starts evaluating the whole form.
         * It will later resume the evaluation on finish.
         */
        if (hfInstance.isEvaluationSuspended()) {
            return
        }
        setIsCalculating(true, async () => {
            try {
                hfInstance.suspendEvaluation()
                let tableValues = {}
                const activityPermissions = permission?.find((a) => a.activityId === activityId);
                
                let { changedFields, changedTables} = findChangedFields(values, previousValues)
                if(changedFields.length === 0){
                    // don't calculate if no changes in values
                    return;
                }
                // check related fields based on generated dependency map
                let relatedFields = []
                for (let index = 0; index < changedFields.length; index++) {
                    let related = dependencyFieldsMap[changedFields[index]] || []
                    if(related){
                        related.forEach(field => {
                            if(field.sectionName.includes('table')){
                                // push a new data to `changedTables` if the field should update a field in a tabel
                                // should calculate the table
                                changedTables.push({ name: field.sectionName })
                            }
                        })
                        // add all fields that depended on fields in the `changedFields` to relatedFields
                        // this prevents recalculating on the same field if multiple dependency
                        relatedFields = [...relatedFields, ...related]
                    }
                }

                // check related tables based on the `changedTables`
                // this will be used to update related table if multiple tables exists
                let relatedTables = tables.filter(table => changedTables.find(item => isEqual(item.name, table.key))) || []
                
                if(firstEvaluate){
                    // first render will need to calculate all fields and tables
                    // this is needed to register all fields in the formula sheets
                    relatedFields = fields
                    relatedTables = tables
                    setFirstEvaluate(false)
                }
                
                await Promise.all(relatedFields.map(async field => {
                    if (!field) return
                    const fieldValue = getFieldValue(values[field.id], field)
                    const expressionValue = parseValue(fieldValue, field?.fieldType, null, timezone)
                    
                    if (field.formula) {
                        const fieldValueMap = generateFieldValueMap(relatedFields)
                        await calculateAggregation(field.formula, fieldValueMap)
                    }
                    
                    setNamedExpression({
                        field,
                        value: expressionValue,
                    })
                    setHfValue(field)
                }))
                await Promise.all(relatedTables.map(async table => {
                    const { key, fields: userDefinedColumns } = table
                    const rowValues = (values[key] || []).map(_row => {
                        const _rowValue = {..._row};
                        tableSystemFields.forEach(_id => {
                            const uniqueRowFormulas = {
                                row_id: 'id'
                            }
                            // Register row formula values that are derived from
                            // their origin ids, e.g. row_id is basically id.
                            const _originalId = uniqueRowFormulas[_id]
                            _rowValue[_id] = _rowValue?.[_originalId] || ''
                        })
                        return _rowValue
                    })
                    const rowSystemColumns = tableSystemFields.map(id => ({ id }))
                    const columns = [
                        ...(userDefinedColumns || []),
                        ...rowSystemColumns,
                    ]
                    tableValues[key] = rowValues
                    if (Array.isArray(rowValues)) {
                        await Promise.all(rowValues.map(async (row, rowIndex) => {
                            await Promise.all(Object.entries(row).map(async item => {
                                const [fieldId, itemValue] = item
                                const field = (columns || []).find(({ id }) => id === fieldId)
                                if (!field) return
                                const fieldValue = getFieldValue(itemValue, field)
                                const expressionValue = parseValue(fieldValue, field?.fieldType, null, timezone)
                                if (field.formula) {
                                    await calculateAggregation(field.formula, row)
                                }
                                setNamedExpression({
                                    field,
                                    value: expressionValue,
                                    table: key,
                                    rowIndex,
                                })
                                setHfValue(field, rowIndex, key)
                            }))
                        }))
                    }
                }))
                setSystemFieldExpression()
                
                const formulas = (relatedFields || []).reduce((acc, field) => {
                    const isSequenceNumber = field.fieldType === 'sequence-number'
                    const hasFormula = (isSequenceNumber || field.isComputedField) && field.formula
                    const formulaKey = isSequenceNumber ? `${field.id}${sequenceFieldSuffix}` : field.id;
                    const formulaString = normalizeExternalFormula(field.formula)
                    const { sheet, sheetName, col } = getCellAddress(field.id)
                    const cellAddress = { sheet, col, row: 0 };
                    let result = ''
                    const fieldPermission = activityPermissions?.fields?.find((f) => f.name === field.id);
                    const isReadOnlyField = fieldPermission?.control === 2;
                    try {
                        if (sheetName && hfInstance.doesSheetExist(sheetName) && (!isReadOnlyField) ){
                            if(hfInstance.doesCellHaveSimpleValue(cellAddress)){
                                result = hfInstance.getCellSerialized(cellAddress);
                                if (isNumberString(result, field.fieldType)){  
                                    hfInstance.setCellContents(cellAddress, `'${result}`); 
                                }
                            }else{
                                result = hfInstance.getCellValue(cellAddress);
                            }
                        }
                    } catch (e) {
                        console.error(e)
                    }
                    let parsedFormula = result
                    if (isSequenceNumber) {
                        parsedFormula = validFormulaValue(result, formulaString, true)
                    }
                    return hasFormula && (!isReadOnlyField)
                        ? {
                            ...acc,
                            [formulaKey]: parseValue(parsedFormula, field?.fieldType, hfInstance, timezone),
                        }
                        : acc
                }, {})
                const tableFormulas = Object.entries(tableValues).reduce((acc, table) => {
                    const [key, rows] = table
                    let results = []
                    if (rows.forEach) {
                        rows.forEach((row, rowIndex) => {
                            const parsedFormulas = {}
                            Object.entries(row).forEach(item => {
                                const [fieldId] = item
                                const tableSchema = tables.find(t => t.key === key)
                                const { fields: tableFields = [] } = tableSchema || {}
                                const field = tableFields.find(({ id }) => id === fieldId) || {}
                                const fieldPermission = activityPermissions?.fields?.find((f) => f.name === field.id);
                                const isReadOnlyColumn = fieldPermission?.control === 2;
                                const { sheet, sheetName, col } = getCellAddress(field.id, rowIndex)
                                const hasFormula = field.isComputedField && field.formula

                                if (sheetName && hfInstance.doesSheetExist(sheetName) && (!isReadOnlyColumn)) {
                                    const cellAddress = { sheet, col, row: rowIndex };
                                    try {
                                        if (sheetName && hfInstance.doesSheetExist(sheetName)) {
                                            let result = '';
                                            if(hfInstance.doesCellHaveSimpleValue(cellAddress)){
                                                result = hfInstance.getCellSerialized(cellAddress);
                                                if (isNumberString(result, field.fieldType)){  
                                                    hfInstance.setCellContents(cellAddress, `'${result}`); 
                                                }
                                            } else if (tableSystemFields.includes(field.formula) && row?.[field.formula]) {
                                                // Retrieve values for row system formulas.
                                                result = row?.[field.formula]
                                            } else {
                                                result = hfInstance.getCellValue(cellAddress);
                                            }
                                            if (hasFormula) {
                                                parsedFormulas[fieldId] = parseValue(
                                                
                                                    validFormulaValue(result, field.formula, false), 
                                                    field?.fieldType, 
                                                    hfInstance,
                                                    timezone
                                                )
                                            }
                                        }
                                    } catch (e) {
                                        console.error(e)
                                    }
                                }
                            })
                            results.push(parsedFormulas)
                        })
                    }
                    return {
                        ...acc,
                        ...{ [key]: results },
                    }
                }, {})
                const newParsedFormulas = {
                    ...formulas,
                    ...tableFormulas,
                }
                setParsedFormulas(newParsedFormulas)
                setParsedFormulaValues(newParsedFormulas)
            } catch (e) {
                console.error(e)
            } finally {
                hfInstance.resumeEvaluation()
            }
        })
    }

    useEffect(() => {
        valuesRef.current = values
        if (hfInstance && activityId !== "fs-complete") parseFormula()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, hfInstance])
}

export default useSheet