import React from "react";
// import Dropdown from "react-bootstrap/Dropdown";
// import SVG from "react-inlinesvg";
// import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
// import ColumnToggleBody from "../ColumnToggleBody";
import IconField from '../../form-editor/components/IconField';

const HeaderDropdown = ({ 
    column, 
    // orders 
}) => {
    // const { onColumnToggle } = column.toggleProps || {};
    // const [show, setShow] = useState(false);
    // const onHideColumn = () => onColumnToggle(column.dataField);
    // const onSort = order => {
    //     column.setSort({
    //         dataField: column.dataField,
    //         order,
    //     });
    //     // onToggle();
    // }

    // const onToggle = () => {
    //     setShow(!show)
    // }

    return (
        <div className="d-flex align-items-center q-mr">
            {/* {
                column.icon
                    ? (
                        <SVG
                            className="mr-3"
                            width={16}
                            src={toAbsoluteUrl("/media/svg/form-editor/" + column.icon + ".svg")}
                        />
                    )
                    : null
            } */}

            {column.icon && 
                <IconField 
                    icon={column.icon} 
                    className="mr-3"
                    width={16} 
                />
            }

            { column.text }

            {/* {
                !column.void
                    ? (
                        <Dropdown
                            // alignRight
                            // show={show}
                            // onToggle={onToggle}
                            onToggle={(_, e) => {
                                e.stopPropagation();
                            }}
                            className="ml-auto"
                        >
                            <Dropdown.Toggle variant="flat" size="sm" bsPrefix="text-primary fa fa-caret-down" />

                            <Dropdown.Menu
                                // align="right"
                                popperConfig={{ strategy: 'fixed' }}
                                as={React.forwardRef((...menuProps) => {
                                    return ColumnToggleBody(...menuProps, onHideColumn, onSort, column, orders)
                                })}
                            />
                        </Dropdown>
                    )
                    : null
            } */}
        </div>
    )
}

const formatHeaderCell = (column, orders) => {
    return <HeaderDropdown column={column} orders={orders} />
}

export default formatHeaderCell;

/*
                <Dropdown.Toggle as={React.forwardRef((...buttonProps) => {
                    return ColumnToggleButton(...buttonProps, onToggle)
                })} />

                {
                    show
                        ? (
                            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} as={React.forwardRef((...menuProps) => {
                                return ColumnToggleBody(...menuProps, onHideColumn, onSort)
                            })} />
                        )
                        : null
                }
*/