import { useMemo } from "react";
import { useDataConnectorDetailQuery } from "../../app/states/Field.state";

const useDataConnectorReader = ({ config, enabled = true }) => {
    const { dataConnectorId } = config
    const { data = {} } = useDataConnectorDetailQuery({ dataConnectorId, enabled })
    return data
}

export const useDataConnectorReaderLoading = ({ config, enabled = true }) => {
    const { dataConnectorId } = config
    const { isLoading, isFetching, isRefetching } = useDataConnectorDetailQuery({ dataConnectorId, enabled })
    const loading = useMemo(() => enabled && (isLoading || isFetching || isRefetching), [
        enabled,
        isLoading,
        isFetching,
        isRefetching,
    ])
    return loading
}

export default useDataConnectorReader