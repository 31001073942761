
import React, { Suspense, useState } from "react";
import NewDataTable from "@components/DataTable/NewDataTable";
import LoadingDisplay from "../../components/LoadingDisplay";
import ModalDuplicateDataConnector from "@components/DataTable/ModalDuplicateDataConnector";

const DataConnectorTable = ({ dataTableId, ...dataTableProps }) => {
    const [dataDuplicate, setDataDuplicate] = useState(null);
    const [showDuplicate, setShowDuplicate] = useState(false);
    const onCopy = (row) => {
        setDataDuplicate(row);
        setShowDuplicate(true);
    };

    const columns = [
        {
            dataField: 'connector_name',
            text: 'Data Connector',
            hidden: false,
        }, {
            dataField: 'connector_description',
            text: 'Description',
            hidden: false,
        }, {
            dataField: 'url',
            text: 'URL',
            hidden: false,
        }, {
            dataField: 'record_created_by',
            text: 'Created By',
            hidden: false,
        }, {
            dataField: 'version',
            text: 'Version Number',
            hidden: false,
            editorType: 'number',
        }, {
            dataField: 'modified_on',
            text: 'Last Updated',
            editorType: 'datetime',
            hidden: false,
        }
    ]
    const hookProps = {
        dataTableId: `dataConnectors`,
        path: 'fetchDataConnectorDatatables',
    }

    return (
        <Suspense fallback={<LoadingDisplay />}>
            <NewDataTable
                {...dataTableProps}
                {...hookProps}
                actionPath="data-connector"
                columns={columns}
                className="table--sticky-header"
                withSelect={false}
                withCopy
                onCopy={onCopy}
            />

            <ModalDuplicateDataConnector data={dataDuplicate} show={showDuplicate} onClose={() => {
                setDataDuplicate(null)
                setShowDuplicate(null)
            }} />
        </Suspense>
    )
}

export default DataConnectorTable