import { FunctionPlugin, ErrorType, CellError } from "hyperformula";

const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export class RandBetween extends FunctionPlugin {
    randBetween({ args }) {
        if (!args.length) {
            return new CellError(ErrorType.REF, '#ERR_PARAM');
        }
        const params = args.map(arg => arg?.value?.value || arg?.value)
        let [min, max] = params
        try {
            if (params.length < 2) {
                [min, max] = min.split(',')
            }
        } catch (e) {
            console.error(e)
        }
        const localRandBetweenValue = localStorage.getItem(`randBetweenValue_${min}_${max}`)
        if (!localRandBetweenValue) {
            const randValue = randomIntFromInterval(min, max)
            localStorage.setItem(`randBetweenValue_${min}_${max}`, JSON.stringify(randValue))
            return randValue
        }
        return localRandBetweenValue
    }
}

RandBetween.implementedFunctions = {
    'RANDBETWEEN': {
        method: 'randBetween',
    }
}