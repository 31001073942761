import { useCallback } from "react";
import { formatter } from "../form-editor/components/FormEntry/NumberInput";

export default function FormattedNumber({
    value = null,
    field,
}) {
    const parseDisplayValue = useCallback(({ decimalPlaces, value, format, currencySign }) => {
        let result = formatter(value, null, format, currencySign, decimalPlaces);
        return String(result);
    }, []);

    let {
        decimalPlaces,
        format,
        currencySign
    } = field || {};

    return ![undefined, null].includes(value)
        ? parseDisplayValue({ decimalPlaces, value, format, currencySign })
        : value
}
