import React from "react";
import { components } from "react-select";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import style from "./style.module.scss";

const DropdownOptionUser = ({ children, data, ...props }) => {
    const { type, email_address, fullname } = data?.label || {}

    return (
        <components.Option {...props}>
            <div className="lookup-select">
                <div className="d-flex">
                    <div className={`text-center mr-2 ${style['option-icon']}`}>
                        { type === 'Team' ? <i className="fal fa-users"/> : <Avatar size="small" icon={<UserOutlined />} /> }
                    </div>
                    <div className={`${style['item-list']} px-4`}>
                        <span className="d-block">{ fullname }</span>
                        { email_address ? <span className="text-sm">{ email_address }</span> : null }
                    </div>
                </div>
            </div>
        </components.Option>
    );
};

export default DropdownOptionUser