import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Popover, List } from "antd";
import { STATUS_DRAFT, STATUS_IN_PROGRESS, STATUS_COMPLETED } from "@constants/datatable";
import { jsonParse } from "@utils/Q";
import { EXT_IMG } from "@constants/FILE_EXT";
import { isJsonString } from "@utils/json";
import Img from "../../app/components/Img";
import DisplayObject from "../../app/components/DisplayObject";
import DisplayUser from "../../app/components/DisplayUser";
import formatHeaderCell from "../../app/components/DataTable/formatters/formatHeaderCell";
import formatFooterCell from "../../app/components/DataTable/formatters/formatFooterCell";
import formatEditAction from "../../app/components/DataTable/formatters/formatEditAction";
import formatDuplicateAction from "../../app/components/DataTable/formatters/formatDuplicateAction";
import formatDeleteAction from "../../app/components/DataTable/formatters/formatDeleteAction";
import formatScheduleAction from "../../app/components/DataTable/formatters/formatScheduleAction";
import { awsFile } from "../../app/components/form-editor/utils";
import ExpandableText from "../../app/components/ExpandableText";
import FormattedNumber from "../../app/components/DataTable/FormattedNumber";
import { getConnectorColumns } from "../form-details/connector-columns";
import useTimeZone from "@utils/hooks/useTimeZone";
import BarcodeDisplay from "../../app/components/form-editor/components/FormEntry/BarcodeDisplay";
import ReactJson from 'react-json-view'
import { restorePercentageValue } from '../number'

const useColumnConfiguration = (props) => {
    const { 
        orders, 
        onDeleteRowItem,
        onShowAttachment,
        setSort,
        actionPath,
        withCopy,
        onCopy,
        withDelete,
        withFooter = true,
        withScheduleAction = false,
        onScheduleAction,
        localStorageItemName,
        sourceColumns,
        sourceRows,
        rawColumns,
        onSaveColumnConfig,
        isDefaultConfig
    } = props;
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const { moduleId } = useParams();
    const { token } = useSelector(({ auth }) => ({ token: auth?.authToken }));
    const { toLocalDateTimeStr, toDateStr, toLocalTimeStr, timezoneId } = useTimeZone();
    const [columns, setColumns] = useState([]);
    const [tempColumn, setTempColumn] = useState([]);
    const [rows] = useState(sourceRows || 10);
    const [footerKeys, setFooterKeys] = useState({});
    const [isEmptyColumns, setEmptyColumns] = useState(false);
    const buildEditPath = formatEditAction({
        history,
        match,
        location,
        moduleId,
    })
    const localColumns = useMemo(() => {
        let res = null
        try {
            const storedColumns = localStorage.getItem(localStorageItemName);
            if (storedColumns) {
                res = JSON.parse(storedColumns);
            }
        } catch { }
        return res
    }, [localStorageItemName])
    const actionsAtStart = [
        'editAction',
        'copyAction',
        'tasks',
        'workflow-item-tasks',
    ]
    const actionsAtEnd = [
        'scheduleAction',
        'deleteAction',
    ]
    const actions = [
        ...actionsAtStart,
        ...actionsAtEnd,
    ]

    const filterDataColumns = (columns) => {
        return  columns.map(f => ({ ...f })).filter(f => !actions.includes(f.dataField))
    }

    const onSaveConfig = async (row, columns) => {
        const filterColumns = filterDataColumns(columns)
        const isAllHidden = filterColumns.every(c => c.hidden)
        if (isAllHidden) {
            setEmptyColumns(isAllHidden)
        }
        await onSaveColumnConfig({ row, columns: filterColumns })
    }

    const sortCols = (a, b) => {
        if (localColumns && !isDefaultConfig) {
            let colObj = localColumns
            const indexA = colObj.findIndex(v => v === a.dataField);
            const indexB = colObj.findIndex(v => v === b.dataField);
            return indexA - indexB;
        }
        return 0;
    };
    const onSetFooterKey = (key, columnId) => {
        setFooterKeys({
            ...footerKeys,
            [columnId]: key,
        });
    };
    const setupFooterKeys = () => {
        const defaultFooterKeys = sourceColumns.reduce(
            (acc, col) => ({
                ...acc,
                [col.dataField]: "count",
            }),
            {}
        );
        setFooterKeys(defaultFooterKeys);
    };
    const getLocalStorageCols = () => {
        if (rawColumns == null && localColumns && localColumns.length > 0) {
            return localColumns;
        }
        const shownCols = sourceColumns
            .filter(c => !c.hidden)
            .map(c => c.dataField)
        if (sourceColumns.length > 0) {
            shownCols.length > 0 && localStorage.setItem(localStorageItemName, JSON.stringify(shownCols));
        }
        return shownCols;
    }
    const setupColumns = () => {
        const cols = getLocalStorageCols();
        let tableColumns = [...sourceColumns].map((column) => {
            const isHeaderFormatter = isDefaultConfig ? {} : {headerFormatter: (column) => formatHeaderCell(column, orders)}
            return {
                ...column,
                formatExtraData: column,
                formatter: (cell, row, rowIndex, col) => {
                    const currentField = sourceColumns.find(
                        ({ dataField }) => dataField === col.dataField
                    );
                    const hideFields = column.config?.hideFields;
                    const statusClasses = {
                        [STATUS_DRAFT]: "success",
                        [STATUS_IN_PROGRESS]: "warning",
                        [STATUS_COMPLETED]: "primary",
                        "Published": "primary",
                    };
                    if(currentField?.formatter && typeof currentField?.formatter === 'function'){
                        return currentField?.formatter(cell, row, rowIndex, col)
                    } 

                    switch (currentField?.editorType) {
                        case "barcode":
                            return cell ?<BarcodeDisplay value={cell} /> : "";
                        case "date":
                        case "calculation-date":
                            return cell ? toDateStr(cell) : "";
                        case "datetime":
                        case "calculation-date-time":
                            return cell ? toLocalDateTimeStr(cell) : "";
                        case "time":
                            return cell ? toLocalTimeStr(cell) : "";
                        case "workflow-item-status":
                        case "editor-status":
                            return (
                                <span
                                    className={`label label-light-${statusClasses[cell]} label-inline`}
                                >
                                    {cell}
                                </span>
                            );
                        case "audit-trail-status":
                            let auditTrailStatus = "primary"
                            switch(cell) {
                                case "Creation":
                                    auditTrailStatus = "success";
                                    break;
                                case "Deletion":
                                    auditTrailStatus = "danger";
                                    break;
                                default:
                                    auditTrailStatus = "primary";
                                    break;
                            }
                            return (
                                <span className={`label label-light-${auditTrailStatus} label-inline`}>
                                    {cell}
                                </span>
                            );
                        case "audit_trail_json":
                            return (
                                <ReactJson name={"changes"} src={JSON.parse(cell ?? '{}')} collapsed={true} />
                            );
                        case "lookup":
                        case "calculation-lookup":
                        case "remote-lookup":
                        case "calculation-remote-lookup":
                        case "multi-select-reference":
                        case "multi-select-external":
                            let objectValue = isJsonString(cell)
                                ? JSON.parse(cell)
                                : cell;
                            return typeof objectValue === "object" ? (
                                Array.isArray(objectValue) ? (
                                    objectValue.map((item, idx) => {
                                        return isJsonString(item) ? (
                                            <DisplayObject
                                                key={idx}
                                                componentClass="lookup-select display-mode"
                                                object={JSON.parse(item)}
                                                currentField={currentField}
                                                hideFields={hideFields}
                                            />)
                                            : typeof item === "object" ? (
                                                <DisplayObject
                                                    key={idx}
                                                    componentClass="lookup-select display-mode"
                                                    object={item}
                                                    currentField={currentField}
                                                    hideFields={hideFields}
                                                />)
                                                : <p>{item}</p>
                                    })
                                )
                                    :
                                    (<DisplayObject
                                        componentClass="lookup-select display-mode"
                                        object={objectValue}
                                        currentField={{
                                            ...currentField,
                                            // column configuration is attached within each rows value
                                            // with a format of {field id}_columns
                                            columns: getConnectorColumns({ values: row, field: col?.config }),
                                        }}
                                        hideFields={hideFields}
                                    />)
                            ) : (
                                cell
                            );
                        case "calculation-user":
                        case "user":
                            let userValue = isJsonString(cell) ? JSON.parse(cell) : cell;

                            return typeof userValue === "object" ? (
                                Array.isArray(userValue) ? (
                                    userValue.map((user, userIndex) => (
                                        <DisplayUser
                                            componentClass="pr-2 mr-2 mb-2"
                                            user={user}
                                            key={userIndex}
                                        />
                                    ))
                                ) : (
                                    <DisplayUser componentClass="pr-2" user={userValue} />
                                )
                            ) : (
                                cell
                            );
                        case "workflow-item-tasks":
                            const tasksCount = (cell || []).length;
                            const renderAction = buildEditPath("task-editor");
                            return tasksCount ? (
                                <Popover
                                    content={
                                        <List
                                            size="small"
                                            dataSource={cell}
                                            renderItem={(item) => (
                                                <List.Item className="p-0">
                                                    {renderAction(
                                                        null,
                                                        item,
                                                        null,
                                                        item.assigned_to_team || item.assigned_to_user,
                                                        { activity: item.assigned_to_team }
                                                    )}
                                                </List.Item>
                                            )}
                                        />
                                    }
                                >
                                    <Badge count={(cell || []).length}>
                                        <BellOutlined />
                                    </Badge>
                                </Popover>
                            ) : <BellOutlined className="text-muted" />;
                        case "checklist":
                            return isJsonString(cell)
                                ? JSON.parse(cell)
                                    ?.filter((v) => v.checked)
                                    .map((v) => v.value)
                                    ?.toString()
                                : cell;
                        case "file":
                        case "multi-file":
                            const isOCRdoc = currentField?.dataField === "_ocr_file_path";
                            const parsedCell = (isOCRdoc ? [cell] : jsonParse(cell, [])) || [];
                            const value = parsedCell.map((v) => ({name: v?.split("/").pop(), url: awsFile(v, token)})) 
                            return (
                                <div className="d-flex flex-row align-items-center ml-1-next">
                                    {value && value.map((v, i) => {
                                        const ext = v.name?.split(".").pop().toLowerCase();
                                        const isImg = EXT_IMG.includes(ext);
                                        if (isImg) {
                                            return (  
                                                <Img
                                                    key={v.name}
                                                    onClick={() => onShowAttachment(value, i)}
                                                    frame
                                                    src={v.url} // "/api/form/AWSS3/GetFile?key=" + v + "&x-token=" + token
                                                    alt={"file image " + i}
                                                    className="of-cov d-inline-block"
                                                    w="28"
                                                    h="28"
                                                />
                                            );
                                        }
                                        return <b key={v.name+i} onClick={() => onShowAttachment(value, i)} style={{cursor: 'pointer'}} className={"lh-1 fa-2x fal fi-" + ext} />;
                                    })}
                                </div>
                            );
                        case "image":
                            const dataCell = jsonParse(cell, []).map((v) => ({name: v.split("/").pop(), url: awsFile(v, token)}));
                            return dataCell?.length > 0
                                ? dataCell.map((v, i) => (
                                    <Img
                                        key={v}
                                        onClick={() => onShowAttachment(dataCell, i)}
                                        frame
                                        src={v.url}
                                        alt={"image " + i}
                                        style={{cursor: 'pointer'}}
                                        className="of-cov d-inline-block"
                                        w="28"
                                        h="28"
                                    />
                                ))
                                : "";
                        case "signature":
                            try {
                                const valCell = Array.isArray(cell) ? cell[0] : cell;
                                if (valCell.length !== 0 && valCell != null) {
                                    const parse = JSON.parse(cell) ? JSON.parse(cell)?.[0] : cell
                                    if (parse.length === 0) {
                                        return null
                                    }
                                    return (
                                        <Img
                                            frame
                                            src={parse.includes('base64') ? parse : awsFile(parse, token)}
                                            alt="signature"
                                            className="of-cov d-inline-block"
                                            w="28"
                                            h="28"
                                        />
                                    );
                                }
                                return null
                            } catch (e) { 
                                return null
                            }
                        case "number":
                        case "calculation-number":
                            const numberValue = restorePercentageValue(
                                cell,
                                currentField?.config?.format,
                                currentField?.config?.decimalPlaces
                            )
                            return <FormattedNumber value={numberValue} field={currentField?.config} />
                        default:
                            return <ExpandableText data={cell}></ExpandableText>;
                    }
                },
                ...isHeaderFormatter,
                headerClasses: (column) => {
                    let baseClassName = ''
                    const columnOrder =
                        (orders || []).find(
                            ({ columnName }) => columnName === column.dataField
                        ) || {};
                    const { direction } = columnOrder;
                    if (direction) {
                        baseClassName = "sorted";
                    }
                    return `${baseClassName} ${column?.headerClassNames || ''}`;
                },
                ...withFooter
                    ? {
                        footer: (columnData, column) => {
                            const content = columnData.reduce(
                                (acc, item) => ({
                                    ...acc,
                                    count: `${columnData.length || 0}`,
                                    blank: `${Number(acc.blank || 0) + Number(!item ? 1 : 0)}`,
                                    nonBlank: `${Number(acc.nonBlank || 0) + Number(!item ? 0 : 1)}`,
                                }),
                                {}
                            );
                            const labels = {
                                count: "Count: ",
                                blank: "Blank: ",
                                nonBlank: "Non blank: ",
                            };
                            const keyId = footerKeys[column.dataField];
                            return keyId && content[keyId]
                                ? `${labels[keyId]}${content[keyId]}`
                                : ``;
                        },
                    }
                    : {},
                footerFormatter: formatFooterCell,
                onSetFooterKey,
                sort: true,
                setSort,
                hidden: !cols.includes(column.dataField) && !actions.includes(column.dataField)
            }
        });
        if(withCopy) {
            tableColumns.unshift({
                void: true,
                dataField: "copyAction",
                text: "",
                classes: "text-center bg-white position-sticky zi-1 r-0 shadow-4 w-40px",
                formatter: formatDuplicateAction(onCopy),
                headerClasses: "bg-white position-sticky zi-1 r-0 shadow-4 w-40px",
                ...withFooter ? { footer: "" } : {},
            });
        }
        tableColumns.unshift({
            void: true,
            dataField: "editAction",
            text: "",
            classes: "text-center w-40px",
            formatter: buildEditPath(actionPath),
            headerClasses: "w-40px",
            ...withFooter ? { footer: "" } : {},
        });
        if(withScheduleAction){
            tableColumns.push({
                void: true,
                dataField: "scheduleAction",
                text: "",
                classes: "text-center bg-white position-sticky zi-1 shadow-4 scheduleAction",
                formatter: formatScheduleAction(onScheduleAction),
                headerClasses: "bg-white position-sticky zi-1 shadow-4 scheduleAction",
                ...withFooter ? { footer: "" } : {},
            });
        }
        if (withDelete) {
            tableColumns.push({
                void: true,
                dataField: "deleteAction",
                text: "",
                classes: "text-center bg-white position-sticky zi-1 r-0 shadow-4 w-40px",
                formatter: formatDeleteAction(onDeleteRowItem),
                headerClasses: "bg-white position-sticky zi-1 r-0 shadow-4 w-40px",
                ...withFooter ? { footer: "" } : {},
            });
        }
        const allTableColumns = [...tableColumns]
        actions.forEach(act => {
            const columnIndex = c => c?.dataField === act
            tableColumns.findIndex(columnIndex) !== -1 && tableColumns.splice(tableColumns.findIndex(columnIndex), 1)
        })
        const finalColumns = [
            ...actionsAtStart.filter(c => allTableColumns.find(tc => tc?.dataField === c)).map(act => allTableColumns.find(c => c?.dataField === act)),
            ...tableColumns.sort(sortCols),
            ...actionsAtEnd.filter(c => allTableColumns.find(tc => tc?.dataField === c)).map(act => allTableColumns.find(c => c?.dataField === act))
        ]
        setColumns(finalColumns);
        setTempColumn(finalColumns)
    };

    useEffect(() => {
        setupFooterKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceColumns]);

    useEffect(() => {
        if(timezoneId) setupColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceColumns, footerKeys, timezoneId]);

    return [columns, tempColumn, setColumns, rows, onSaveConfig, filterDataColumns, isEmptyColumns]
};

export default useColumnConfiguration