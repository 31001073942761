import React from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor'

export default function CodeEditorForm({value, onChange, language, placeholder, minHeight}) {
    return (
        <div className="code-editor">
            <CodeEditor
                value={value}
                language={language}
                onChange={(evn) => onChange(evn.target.value)}
                padding={15}
                placeholder={placeholder}
                minHeight={minHeight}
                style={{
                    fontSize: 12,
                    backgroundColor: '#ffffff',
                    border: '1px solid #E4E6EF',
                    borderRadius: '0.42rem',
                    position: 'relative',
                    textAlign: 'left',
                    boxSizing: 'border-box',
                    padding: 0,
                    overflow: 'hidden',
                    color: 'black',
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
            />
        </div>
    )
}
