import React from "react";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import {ReactComponent as InfoIcon} from "../../../svgs/info.svg";
import {ReactComponent as TaskIcon} from "../../../svgs/task.svg";
import {STATUS_DRAFT} from "@constants/datatable";
import style from "../style.module.scss";

const formatEditAction = ({ match, location, moduleId }) => (path = null) => (cell, row, index = null, content = null) => {
    const generatePath = () => {
        const formId = moduleId || row['module_id']
        const activityType = row['activity_type'] || row['task_activity_type'];
        let suffix
        let pathRewrite = path;
        switch (true) {
            case activityType === 'StartProcess':
                pathRewrite = 'item-editor'
                suffix = `${formId}/${row['record_id'] || row['task_record_id']}/${row.task_id || row.id}`
                break
            case path === 'item-editor':
                suffix = `${formId}/${row.id}/edit`
                break
            case path === 'task-editor':
                suffix = `${formId}/${row['record_id'] || row['task_record_id']}/${row.task_id || row.id}`
                break
            case path === 'form-editor':
                suffix = `show/${row.id}`
                break
            case path === 'module':
                suffix = `${row.id}`
                break
            case path === 'data-connector':
                suffix = `${row['connector_type']==="API"?'simple':'advance'}/${row.id}`
                break
            case path === 'schedule-editor':
                suffix = `${formId}/${row.id}`
                break
            default:
                suffix = `${row.id}`
                break
        }
        return {
            pathname: `${pathRewrite ? `/${pathRewrite}` : `${match?.path}`}/${suffix}`,
            state: {
                modal: location,
                activity: activityType === 'StartProcess' ? "fs-start" : row.activity_id || row.workflow_activity_id
            },
            search: '?from=datatable',
        }
    }

    return (
        <Link to={{...generatePath()}}> 
            {
                content
                    ? <span className="d-inline-block pl-2">{ content }</span>
                    : path === 'task-editor' ||
                        (path === 'form-editor' && row?.status === STATUS_DRAFT)
                        ? <TaskIcon className={style['button-icon']} />
                        : <InfoIcon className={style['button-icon']} />
            }
        </Link>
    )
}

export default formatEditAction