import React, { useMemo } from "react";
import { components } from "react-select";
import DisplayObject from "../DisplayObject";
import style from "./style.module.scss";
import { Cx } from "@utils/Q"; 

const DropdownValueContainerObject = props => {
    const { children, getValue, componentClass = '', config } = props
    const [result] = getValue()
    const { value } = result || {}
    const transformed = useMemo(() => children.map(c => {
        const hasValue = typeof c?.props?.children === 'object'
        return hasValue
            ? {
                ...c,
                props: {
                    ...c.props,
                    children: null,
                }
            }
            : c
    }), [children])

    return (
        <components.ValueContainer 
            {...props} 
            className={`${props.className} ${style['value-container']} ${value ? 'px-0' : ''}`}
        >
            {
                value
                    ? (
                        <DisplayObject
                            componentClass={Cx(componentClass, style['object-wrapper'])}
                            object={value}
                            currentField={{
                                config,
                            }}
                        />
                    )
                    : null
            }
            { transformed }
        </components.ValueContainer>
    );
};

export default DropdownValueContainerObject;
