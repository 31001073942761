import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalDelete = props => {
    const { show, onDelete, onCloseDelete } = props

    return (
        <Modal centered backdrop="static" show={show}>
            <Modal.Header className="py-4">
                <Modal.Title>Delete item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Deleting an item will cause any data in previous transactions stored in this item to also be permanently deleted.<br/>
            Are you sure you want to delete this item?
            </Modal.Body>
            <Modal.Footer className="py-2">
                <Button onClick={onCloseDelete} variant="outline-secondary">Cancel</Button>
                <Button onClick={onDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalDelete.propTypes = {
    show: PropTypes.bool,
    onDelete: PropTypes.func,
    onCloseDelete: PropTypes.func,
};

export default ModalDelete;
