import React from "react";
import {Input, Select} from "antd";
import useDatasetOptions from "@utils/hooks/useDatasetOptions";

const {Option} = Select

const ValueData = ({ valueExpression, onChange }) => {
    const { datasetOptions, loadingDataset } = useDatasetOptions();
    return (
        <Select
            className={'w-100'}
            onChange={onChange}
            options={datasetOptions}
            value={valueExpression}
            loading={loadingDataset}
        >
            {
                datasetOptions?.map((x, index) => {
                    return (
                        <Option
                            key={index}
                            value={x.value}
                            title={x.label}
                            className={"align-items-center before-muted q-mr"}
                        >
                            {x.label}
                        </Option>
                    );
                })
            }
        </Select>
    );
}

export const RenderRequestValueForm = ({ value, handleOnChange, fieldOptions }) => {
    const [valueName, valueType, valueExpression, valueDataKey, valueDataColumnId] = !value
        ? ['', 'value', '', '', '']
        : value
    switch (valueType) {
        case 'field':
            return (
                <Select
                    className={'w-100'}
                    value={valueExpression}
                    onChange={opt => {
                        handleOnChange([
                            valueName,
                            valueType,
                            opt,
                            valueDataKey,
                            valueDataColumnId,
                        ])
                    }}
                >
                    {
                        fieldOptions?.map((x, index) => {
                            return (
                                <Option
                                    key={index}
                                    value={x.value}
                                    title={x.label}
                                    className={"align-items-center before-muted q-mr"}
                                >
                                    {x.label}
                                </Option>
                            );
                        })
                    }
                </Select>
            )
        case 'data':
            return (
                <ValueData
                    valueExpression={valueExpression}
                    onChange={opt => {
                        handleOnChange([
                            valueName,
                            valueType,
                            opt,
                            valueDataKey,
                            valueDataColumnId,
                        ])
                    }}
                />
            )
        default:
            return (
                <Input
                    className={"w-100"}
                    type="text"
                    name="other"
                    value={valueExpression}
                    onChange={e => {
                        handleOnChange([
                            valueName,
                            valueType,
                            e?.target?.value,
                            valueDataKey,
                            valueDataColumnId,
                        ])
                    }}
                />
            )
    }
}
