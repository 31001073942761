import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";
import { parseJsonString } from "@utils/json";

export const inputValueValidation = (value) => {
    if(value && typeof value === 'string'){
        if(value.length > 40){
            return "Value cannot exceed 40 characters"
        }
        if(value.match(/^ /)){
            return "Value cannot start with a space"
        }
        if(value.match(SPECIAL_CHAR_REGEX)){
            try{
                const isJSONObj = parseJsonString(val)
                if(typeof isJSONObj === 'object') return false; //bypass object
                if(moment(val).isValid()) return false; //bypass date
                if((/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/).test(val)) return false; //bypass time
            } catch (e) {}
            return "Value cannot contain special characters"
        }
    }
    return false
}