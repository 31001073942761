import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { lookupPayload } from "@utils/form-editor/normalize-payload";
import useLookupPayload from "@utils/hooks/useLookupPayload";
import { Cx } from '@utils/Q';
import DropdownOptionObject, { MenuList } from "../../../dropdown-components/DropdownOptionObject";
import DropdownValueContainerObject from "../../../dropdown-components/DropdownValueContainerObject";
import style from "./style.module.scss";
import { parseJsonString } from "@utils/json"
import { parseLookupLabel } from "@utils/parseLookupLabel"
import { parseText } from "@utils/parseText"
import { useRecordModuleLookupQuery, useInvalidateLookupDropdownQueries } from "../../../../states/Field.state";
import useSelectOCRValue from "@utils/hooks/useSelectOCRValue";
import useTimeZone from "@utils/hooks/useTimeZone";

const LookupDropdown = ({
    config: sourceConfig,
    currentValue,
    onChange,
    className,
    disabled,
    formValues,
    table,
    rowIndex,
    isEditable,
    removeOptionsFilter,
    ...etc
}) => {
	const timezone = useTimeZone();
    const config = { ...sourceConfig }
    const { selectFieldsByThisProcess, hideFieldsDuringSelection, hideFields, autoSelectFirstValueByFilters } = config;
    const [options, setOptions] = useState([])
    const [payload, setPayload] = useState()
    const [enabled, setEnabled] = useState(false)
    const [clear, setClear] = useState()
    const isOCRMapping = useMemo(() => Boolean(currentValue?.includes("[AIservice]")), [currentValue])
    const { data, isLoading, isFetching, isRefetching, isFetched } = useRecordModuleLookupQuery({ payload, enabled:(payload?.params && (enabled || isOCRMapping)) })
    const loading = useMemo(() => enabled && (isLoading || isFetching || isRefetching), [
        enabled,
        isLoading,
        isFetching,
        isRefetching,
    ])
    const visibleKeys = useMemo(() => {
        const withFilters = hideFieldsDuringSelection && hideFields.length
        const valueKeys = withFilters
            ? selectFieldsByThisProcess.filter(key => !hideFields.includes(key))
            : selectFieldsByThisProcess
        return valueKeys
    }, [
        hideFieldsDuringSelection,
        hideFields,
        selectFieldsByThisProcess,
    ])

    const findByCurrentValue = useCallback((opt, value) => {
        return parseJsonString(opt?.raw)?.id === parseJsonString(value?.raw)?.id
    }, [])
    const defaultValue = useMemo(() => {
        try {
            if (clear) {
                return null
            }
            if (currentValue) {
                const option = parseJsonString(currentValue)
                const value = {
                    ...option,
                    [`${config?.id}_reference_id`]: option.id,
                }
                return {
                    label: parseLookupLabel(option, visibleKeys, isEditable),
                    value: option,
                    raw: JSON.stringify(value),
                }
            }
        } catch { }
    }, [currentValue, clear])
    const selectedValue = useMemo(() => {
        return options?.find(opt => findByCurrentValue(opt, { raw: currentValue })) || defaultValue
    }, [options, currentValue, defaultValue])

    const onSelectValue = (val, userAction) => {
        const { raw } = val ?? {}
        onChange && onChange(raw)
        val === null && userAction && setClear(true)
    }

    const loadOptions = async (requestPayload) => {
        setPayload({
            params: {
                q: Object.entries(requestPayload).reduce((acc, obj) => {
                    const [key, value] = obj
                    if(removeOptionsFilter && key === "filter"){
                        return{ ...acc };
                    }
                    return {
                        ...acc,
                        ...value ? { [key]: value } : {},
                    }
                }, {})
            }
        })
    }

    useLookupPayload({
        config,
        formValues,
        normalizer: () => lookupPayload(config, formValues, table, rowIndex, timezone),
        onUpdate: loadOptions,
    })
    
    useSelectOCRValue({isOCRMapping, currentValue, options, isFetched, onSelectValue});
    useEffect(() => {
        if (data && payload) {
            const remoteOptions = (data || []).map(option => {
                const value = {
                    ...option,
                    [`${config?.id}_reference_id`]: option.id,
                }
                return {
                    label: parseLookupLabel(option, visibleKeys, isEditable),
                    value: option,
                    raw: JSON.stringify(value),
                }
            })
            if (selectedValue) {
                let option = -1
                if (remoteOptions.length > 0) {
                    option = remoteOptions.findIndex(opt => findByCurrentValue(opt, selectedValue))
                }
                if (enabled && option < 0 && !isOCRMapping) {
                    onSelectValue(null)
                }
            }
            setOptions(remoteOptions)
            if (enabled && autoSelectFirstValueByFilters && remoteOptions.length > 0 && !currentValue) {
                onSelectValue(remoteOptions[0])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderComponent = () => {
        if (isEditable) {
            if (loading || options.length === 0) {
                return {}
            }
            return {
                MenuList,
                Option: ({ data, ...props }) => DropdownOptionObject({
                    ...props,
                    componentClass: 'lookup-select',
                    valueKeys: visibleKeys,
                    data: {
                        ...data,
                        label: parseText(data.value)
                    }
                }),
                NoOptionsMessage:() => {
                    return <div className="lookup-select p-3 text-center">
                        {(options?.length === 0) ? "No Options" : "Loading..."}
                    </div> 
                },
            }
        }
        return {
            MenuList, 
            ValueContainer: props => {
                return DropdownValueContainerObject({ ...props, componentClass: 'lookup-select', config })
            },
            Option: ({ data, ...props }) => DropdownOptionObject({
                ...props,
                componentClass: 'lookup-select',
                valueKeys: visibleKeys,
                data: {
                    ...data,
                    label: parseText(data.value)
                }
            }),
        }
    }

    useInvalidateLookupDropdownQueries({payload, enabled})
    return (
        <Select
            {...etc}
            components={renderComponent()}
            openMenuOnFocus
            tabIndex={1}
            className={Cx("fs-react-select", "searchable-select", className, style['select-json-mode'])}
            classNamePrefix="fs-r-select"
            menuShouldScrollIntoView={false}
            menuPlacement="auto" // top | bottom | auto
            options={options || []}
            isLoading={loading}
            isDisabled={disabled}
            value={selectedValue?.raw ? selectedValue : undefined}
            onChange={val => onSelectValue(val, true)}
            filterOption={(option, inputValue) => {
                const label = visibleKeys.map((key) => {
                    return JSON.parse(option?.data?.raw)[key] || '';
                }).join("-");
                return label.toLowerCase().includes(inputValue.toLowerCase());
            }}
            onMenuOpen={() => setEnabled(true)}
            onMenuClose={() => setEnabled(false)}
            backspaceRemovesValue={true}
            isClearable={true}
            key={`lookup_dropdown_${config?.id || ''}_${rowIndex}`}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            closeMenuOnScroll={(e) => {
                if (!e.target.classList?.contains("fs-r-select__menu-list")) {
                    return e.target.scrollTop > 0
                }
            }}
        />
    )
}

export default LookupDropdown;
