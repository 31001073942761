import { useEffect } from "react";
import { setClass } from "../Q";

const useFullContentLayout = () => {
    useEffect(() => {
        const contentContainer = document.querySelector('.content__container') || document.querySelector('#kt_content > .d-flex > .container-fluid');
        if (contentContainer) {
            const CLS = "d-flex flex-column content__container--full mw-100 px-0";
            // contentContainer.classList.add('d-flex', 'flex-column', 'content__container--full', 'mw-100');
            setClass(contentContainer, CLS);
            return () => {
                // contentContainer.classList.remove('d-flex', 'flex-column', 'content__container--full', 'mw-100');
                setClass(contentContainer, CLS, "remove");
            }
        }
    }, []);
}

export default useFullContentLayout;