import React from "react";
import style from "./style.module.scss";

const DottedContainer = ({ children, isEdge }) => {
    return (
        <div className={
            `
                dotted-container
                ${style['dotted-container']}
                ${isEdge ? style['is-edge'] : ''}
            `
        }>
            { children }
        </div>
    )
}

export default DottedContainer