import React from "react";
// import Spinner from "react-bootstrap/Spinner";
import { Skeleton } from 'antd';

const EmptyTable = ({ error, loading }) => {
    const errorMessage = error || 'No results found.';

    return loading
        ? <Skeleton active title={false} paragraph={{ rows: 2, width: "100%" }} />
        : errorMessage;
}

export default EmptyTable;

// <Spinner animation="border" variant="light" />
