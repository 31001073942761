import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import style from "./style.module.scss";

const CustomSelect = controlProps => {
    const {
        field,
        form,
        className,
        fieldClassName = '',
        ...props
    } = controlProps
    const { touched, errors } = form
    const errorArrayItem = field.name.split('.').reduce((o,i)=>o && o[i], errors)
    const touchedArrayItem = field.name.split('.').reduce((o,i)=>o && o[i], touched)
    const isError = (touched[field.name] && errors[field.name]) || (touchedArrayItem && errorArrayItem)
    const errorLabel = errors[field.name] || errorArrayItem

    return (
        <div className={className}>
            <Select
                {...field}
                {...props}
                onChange={val => form.setFieldValue(field.name, val)}
                className={`${fieldClassName} ${isError ? style['is-invalid'] : ''}`}
            />
            {
                isError
                    ? <Form.Control.Feedback type="invalid" className="d-block mb-1">{ errorLabel }</Form.Control.Feedback>
                    : null
            }
        </div>
    )
};

export default CustomSelect