import React from "react"
import { Skeleton } from "antd"

const LoadingDisplay = () => {
    return (
        <Skeleton
            active 
            loading 
            paragraph={{
                rows: 1,
                width: '30%',
            }}
        />
    )
}

export default LoadingDisplay