import React from "react";
import Barcode from "react-barcode";

const BarcodeDisplay = (props) => {
    return props.value
        ? (
            <div style={{maxWidth: "90%"}}>
                <Barcode
                    tabIndex={1}
                    value={props.value}
                    height={32}
                    displayValue={false}
                    margin={0}
                />
            </div>
        )
        : null
}

export default BarcodeDisplay;
