
import React, { useState } from "react";
import { Button } from 'antd';

const ExpandableText = ({ data = null }) => {
    const [expanded, setExpanded] = useState(false);
    const trimmedText = data?.toString().substring(0, 150);
    return (
        data?.length > 150 ?
            <div style={{ whiteSpace: 'normal', minWidth: '200px' }}>
                {expanded ?
                    data
                    :
                    trimmedText + "... "
                }
                <Button type="link" size="small" onClick={()=>setExpanded(!expanded)}>
                    {expanded ? "read less" : "read more"}
                </Button>
            </div>
            :
            data
    )
}

export default ExpandableText