import React, { useState, useRef, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Transfer } from 'antd';
import { useSize } from 'ahooks';
import DraggableItem from './DraggableItem';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function ModalChooseColumns({
  columns,
  tempColumn,
  rows = 10,
  onSave,
  onChangeColumns,
  localStorageItemName,
  isDefaultConfig = false,
  title = "Choose columns"
}) {

  const modalBodyRef = useRef();
  const modalBodySize = useSize(modalBodyRef);
  const columnsRaw = _.cloneDeep(columns);
  const available = columnsRaw.filter(f => f.text?.length > 0).map(f => ({ ...f, key: f.dataField }))
  const [show, setShow] = useState(false);
  const [colObj, setColObj] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const actionsAtStart = [
    'editAction',
    'tasks',
    'workflow-item-tasks',
  ]
  const actionsAtEnd = [
    'deleteAction',
  ]
  const actions = [
    ...actionsAtStart,
    ...actionsAtEnd,
  ]
  const excludeByActions = c => {
    const columnName = c?.dataField || c
    return !actions.includes(columnName)
  }
  const onChange = (keys, direction, moveKeys) => {
    const isLeft = direction === "left";
    let cols = columnsRaw.map(f => (f.dataField && (isLeft ? moveKeys : keys).includes(f.dataField) ? { ...f, hidden: !isLeft } : f));
    const shownCols = cols.filter((c) => !c.hidden).map((c) => c.dataField);
    setTargetKeys(keys);
    setColObj([...shownCols])
    actions.forEach(act => {
      cols.indexOf(act) !== -1 && cols.splice(cols.indexOf(act), 1)
    })
    cols = [
      ...actionsAtStart.filter(c => cols.includes(c)),
      ...cols,
      ...actionsAtEnd.filter(c => cols.includes(c))
    ]
    onChangeColumns(cols)
  }

  const onHide = () => {
    if (isDefaultConfig) {
      setTargetKeys(columnsRaw.filter(c => c.hidden).map(c => c.dataField))
      onChangeColumns(tempColumn)
    }
    setColObj(columnsRaw.filter((c) => !c.hidden).map((c) => c.dataField))
    setShow(false)
  };
  const moveRow = useCallback((dragIndex, hoverIndex) => {
    const cols = [...colObj];
    if (cols.length > 0) {
      actions.forEach(act => {
        cols.indexOf(act) !== -1 && cols.splice(cols.indexOf(act), 1)
      })
      const el = cols.splice(dragIndex, 1)[0];
      cols.splice(hoverIndex, 0, el);

      const sortedVisible = [
        ...actionsAtStart.filter(c => cols.includes(c)),
        ...cols,
        ...actionsAtEnd.filter(c => cols.includes(c))
      ]
      setColObj(sortedVisible);
      const allTableColumns = [...columnsRaw]
      actions.forEach(act => {
        const columnIndex = c => c?.dataField === act
        columnsRaw.findIndex(columnIndex) !== -1 && columnsRaw.splice(columnsRaw.findIndex(columnIndex), 1)
      })
      const sortCols = (a, b) => {
        return sortedVisible.indexOf(a?.dataField) - sortedVisible.indexOf(b?.dataField)
      }
      const finalColumn = [
        ...actionsAtStart.filter(c => allTableColumns.find(tc => tc?.dataField === c)).map(act => allTableColumns.find(c => c?.dataField === act)),
        ...columnsRaw.sort(sortCols),
        ...actionsAtEnd.filter(c => allTableColumns.find(tc => tc?.dataField === c)).map(act => allTableColumns.find(c => c?.dataField === act))
      ]
      onChangeColumns(finalColumn)
    }
  }, [colObj, columnsRaw]);

  const getColArray = () => {
    try {
      const cols = localStorage.getItem(localStorageItemName);
      const colObj = JSON.parse(cols);
      return colObj;
    } catch (err) {
      return [];
    }
  }

  useEffect(() => {
    setTargetKeys(columnsRaw.filter(c => c.hidden).map(c => c.dataField))
    if (isDefaultConfig) {
      setColObj(columnsRaw.filter((c) => !c.hidden).map((c) => c.dataField))
    }
    const cols = getColArray();
    if (cols && cols?.length > 0 && colObj.length < 1) {
      setColObj([...cols])
    }
  }, [columns])

  useEffect(() => {
    if (colObj.length > 0) {
      const columnsToStore = colObj.filter(excludeByActions)
      columnsToStore.length > 0 && localStorage.setItem(localStorageItemName, JSON.stringify(columnsToStore));
    }
  }, [colObj])

  const onSaveColumn = () => {
    onSave(rows, available)
    setShow(false)
  }
  return (
    <>
      <Button
        variant="outline-primary"
        onClick={() => setShow(true)}
      >
        {title}
      </Button>

      <Modal
        centered
        scrollable
        size="lg"
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className="py-3">
          <Modal.Title as="h6">{title}</Modal.Title>
        </Modal.Header>
        <div
          ref={modalBodyRef}
          className="modal-body py-4 ovxauto"
        >
          <div className="d-flex flex-row mb-2">
            <div className="flex1">Available</div>
            <div className="w-40px" />
            <div className="flex1">Current</div>
          </div>
          <DndProvider backend={HTML5Backend}>
            <Transfer
              className="flex-row-reverse fs-transfer-reverse"
              showSearch
              listStyle={{
                width: modalBodySize?.width ? (modalBodySize.width / 2) - 24 : 376, // '100%',
                height: 480,
              }}
              dataSource={available}
              targetKeys={targetKeys}
              filterOption={(inputValue, option) => inputValue && option?.text?.toLowerCase().includes(inputValue.toLowerCase())}
              onChange={onChange}
              render={item => {
                const items = available.filter((avail) => !targetKeys.includes(avail.key)).map((avail) => avail.key)
                return (
                  <DraggableItem
                    index={items.findIndex((key) => key === item.key)}
                    label={item.text}
                    moveRow={moveRow}
                    colItem={item}
                  />
                )
              }}
            />
          </DndProvider>

        </div>
        <Modal.Footer className="py-1">
          <Button onClick={onHide} size="sm" variant="outline-primary">Cancel</Button>
          {isDefaultConfig && <Button onClick={onSaveColumn} size="sm" variant="primary">Save</Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
}