import { FunctionPlugin } from "hyperformula";
import { EmptyValue } from "hyperformula/es/interpreter/InterpreterValue";
import moment from "moment";

export class GetSummary extends FunctionPlugin {
    aggregateSelfValue(config) {
        let result = ''
        try {
            const { columnValues, aggregationType } = config
            const summaryType = aggregationType.toLowerCase()
            let fieldValues = columnValues.flatMap(c => c.rawData())
                .flat()
                .filter(v => v !== EmptyValue);
            let parseVal = null;
            const getMinMaxValues = (flag = 'min') => {
                let isDate = false
                const values = fieldValues.map(v => {
                    const isValueDate = isNaN(Number(v))
                    isDate = isValueDate
                    return isValueDate ? moment.utc(v) : Number(v)
                })
                return isDate
                    ? moment[flag](values)
                    : values.length
                        ? Math[flag](...values)
                        : 0
            }

            switch (summaryType) {
                case 'concatenate':
                    parseVal = String(`"${fieldValues.join(', ')}"`)
                    break;
                case 'min':
                    parseVal = getMinMaxValues('min')
                    break;
                case 'max':
                    parseVal = getMinMaxValues('max')
                    break;
                case 'average':
                    const sum = fieldValues.reduce((acc, v) => Number(acc) + Number(v), 0)
                    parseVal = Math.round(sum/fieldValues.length)
                    break;
                case 'sum':
                    parseVal = fieldValues.reduce((acc, v) => Number(acc) + Number(v), 0)
                    break;
                case 'count':
                    parseVal = Number(fieldValues.length)
                    break;
                default: break;
            }
            const defaultValue = parseVal ?? ""
            result = summaryType !== 'concatenate' && isNaN(parseVal) ? 0 : defaultValue
        } catch(e) {
            
        }
        return result
    }

    getSummary(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('GETSUMMARY'), (...args) => {
            const [aggregationType, ...columnValues] = args
            const result = this.aggregateSelfValue({ aggregationType, columnValues })
            return result
          })
    }
}

GetSummary.implementedFunctions = {
    'GETSUMMARY': {
        method: 'getSummary',
        parameters: [
            {argumentType: "STRING"},
            {argumentType: "RANGE"},
        ],
        repeatLastArgs: 1,
    }
}