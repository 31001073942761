import React from "react";// , { useState }
import Dropdown from "react-bootstrap/Dropdown";

// import FooterDropdownButton from "../FooterDropdownButton";
import FooterDropdownBody from "../FooterDropdownBody";

const FooterDropdown = ({ column, text }) => {
    // const [show, setShow] = useState(false);
    const onSetFooterKey = key => {
        column.onSetFooterKey(key, column.dataField);
        // onToggle();
    }

    // const onToggle = () => {
    //     setShow(!show)
    // }

    return (
        <div className="dropdown__container">
            <Dropdown 
                // drop="up" 
                // show={show} 
                // onToggle={onToggle} 
            >
                <Dropdown.Toggle block variant="link" size="sm" className="d-flex align-items-center p-0 text-decoration-none text-dark after-ml-auto dd-toggle-1">{text}</Dropdown.Toggle>

                <Dropdown.Menu 
                    popperConfig={{ strategy: 'fixed' }} 
                    as={React.forwardRef((...menuProps) => {
                        return FooterDropdownBody(...menuProps, onSetFooterKey, text)
                    })} 
                />
            </Dropdown>
        </div>
    )
}

const formatFooterCell = (column, colIndex, { text }) => {
    return <FooterDropdown column={column} text={text} />
}

export default formatFooterCell;

/*
const FooterDropdown = ({ column, text }) => {
    const [show, setShow] = useState(false);
    const onSetFooterKey = key => {
        column.onSetFooterKey(key, column.dataField);
        onToggle();
    }
    const onToggle = () => {
        setShow(!show)
    }
    return (
        <div className="dropdown__container">
            <Dropdown
                show={show} 
                onToggle={onToggle} 
            >
                <Dropdown.Toggle as={React.forwardRef((...buttonProps) => {
                    return FooterDropdownButton(...buttonProps, onToggle, text)
                })} />
                {
                    show
                        ? (
                            <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} as={React.forwardRef((...menuProps) => {
                                return FooterDropdownBody(...menuProps, onSetFooterKey, text)
                            })} />
                        )
                        : null
                }
            </Dropdown>
        </div>
    )
}
*/