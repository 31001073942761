import React, { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";// , { createFilter }
import { userLookupPayload } from "@utils/form-editor/normalize-payload";
import { Cx } from "@utils/Q";
import usePrevious from "@utils/hooks/usePrevious";
import DropdownOptionUser from "../../../dropdown-components/DropdownOptionUser";
import { DropdownSingleValueContainerUser, DropdownMultipleValueContainerUser } from "../../../dropdown-components/DropdownValueContainerUser";
import style from "./style.module.scss";
import { useUserLookupQuery } from "../../../../states/Field.state";
import { debounce, uniqBy } from "lodash";

export const getFieldFilters = filters => {
    let result = []
    try {
        result = filters
            .filter(f => f.type === 'Field')
            .map(f => f.value)
    } catch(e) {}
    return result
}

export const getFieldFilterValues = ({ filters, values }) => {
    const result = {}
    try {
        const fieldFilters = getFieldFilters(filters)
        fieldFilters.forEach(f => {
            if (![undefined, null].includes(values?.[f])) {
                result[f] = values[f]
            }
        })
    } catch(e) {}
    return result
}

const UserLookupDropdown = ({
    config: sourceConfig,
    currentValue,
    onChange,
    className,
    disabled,
    formik,
    ...etc
}) => {    
    const config = {...sourceConfig}
    const { autoSelectFirstValueByFilter, fieldType, editorType } = config;
    const [options, setOptions] = useState([]);
    const [payload, setPayload] = useState();
    const [enabled, setEnabled] = useState(false)
    const [clear, setClear] = useState()
    const [configFilters, setConfigFilters] = useState({});
    const { data, isLoading, isFetching, isRefetching, isFetched, isSuccess } = useUserLookupQuery({ payload, enabled })
    const loading = useMemo(() => enabled && (isLoading || isFetching || isRefetching), [
        enabled,
        isLoading,
        isFetching,
        isRefetching,
    ])
    const previousOptions = usePrevious(options);
    const isMultiple = (fieldType || editorType) === 'multi-select-user';
    const defaultValue = useMemo(() => {
        try {
            if (clear) {
                return null
            }
            if (currentValue) {
                return isMultiple
                    ? JSON.parse(currentValue).map(opt => ({
                        label: opt,
                        raw: JSON.stringify(opt)
                    }))
                    : {
                        label: JSON.parse(currentValue),
                        raw: currentValue,
                    }
            }
        } catch {}
    }, [currentValue, clear])
    const selectedValue = useMemo(() => {
        const listOptions = options
        const userValue = (
            isMultiple
                ? uniqBy(defaultValue, 'raw').map((i) => {
                    return { 
                        ...i, 
                        value: i.label?.id
                    }
                })
                : listOptions.find(({ raw }) => {
                    return currentValue === raw
                })
        )
        return isMultiple
            ? userValue.length > 0
                ? userValue
                : defaultValue
            : (userValue || defaultValue)
    }, [options, currentValue, defaultValue, isMultiple])

    const autoSelectValue = (opts) => {
        const optionsUpdated = previousOptions?.length && JSON.stringify(opts) !== JSON.stringify(previousOptions)
        const newFirstOption = currentValue && JSON.stringify(opts[0]) !== JSON.stringify(currentValue)
        const isPristine = !currentValue || (optionsUpdated && newFirstOption)
        const loadComplete = isFetched || isSuccess
        if (
            autoSelectFirstValueByFilter && 
            opts.length > 0 &&
            loadComplete
        ) {
            onSelectValue(
                isPristine
                    ? opts[0]
                    : selectedValue
            )
        }
    }
    const loadOptions = async () => {
        const configFilters = userLookupPayload(config, formik?.values);
        const params = {q: configFilters};
        setConfigFilters(configFilters);
        setPayload({ params })
    }
    const onSelectValue = (value, userAction) => {
        const fieldValue = isMultiple
            ? value
                ? JSON.stringify(value.map(({ raw }) => JSON.parse(raw)))
                : '[]'
            : (value?.raw || null)
        onChange && onChange(fieldValue)
        value === null && userAction && setClear(true)
    }

    const filterValues = JSON.stringify(getFieldFilterValues({
        filters: config.filters,
        values: formik?.values,
    }));

    useEffect(() => {
        loadOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues])

    useEffect(() => {
        autoSelectValue(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValue, options])

    useEffect(() => {
        if (data) {
            const remoteOptions = (data || []).map(option => {
                return {
                    label: option,
                    value: option.id,
                    raw: JSON.stringify(option),
                }
            })
            autoSelectValue(remoteOptions)
            if(remoteOptions.length > 0){
                setOptions(remoteOptions)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const handleSearchDebounced = useRef(
        debounce((searchText) =>
            setPayload({ 
                params: {
                    q: searchText? userLookupPayload({
                        filters: [{
                            field: "fullname",
                            operator: "Contains",
                            value: searchText,
                        },
                        {
                            field: "email_address",
                            operator: "Contains",
                            value: searchText,
                        }],
                        rawFilters: {
                            combinator: 'or'
                        }
                    }, formik?.values): {}
                }
            }), 600)
    ).current

    return (
        <Select
            {...etc}
            components={{
                ...(isMultiple ? { MultiValueContainer: DropdownMultipleValueContainerUser } : {}),
                ...(!isMultiple ? { ValueContainer: DropdownSingleValueContainerUser } : {}),
                Option: DropdownOptionUser,
            }}
            className={Cx("fs-react-select user-lookup-dropdown", className, style['select-json-mode'])}
            classNamePrefix="fs-r-select"
            menuShouldScrollIntoView={false}
            menuPlacement="auto"
            openMenuOnFocus
            tabIndex={1}
            options={options}
            isLoading={loading}
            value={selectedValue}
            onChange={val => onSelectValue(val, true)}
            isMulti={isMultiple}
            isDisabled={disabled}
            backspaceRemovesValue={true}
            isClearable={true}
            onKeyDown={() => handleSearchDebounced("")}
            filterOption={(option, inputValue) => {
                if (!configFilters || Object.keys(configFilters).length === 0) {
                    handleSearchDebounced(inputValue)
                }
                return (option?.data?.raw || '').toLowerCase().includes(inputValue.toLowerCase());
            }}
            onMenuOpen={() => setEnabled(true)}
            onMenuClose={() => setEnabled(false)}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            closeMenuOnScroll={(e) => {
                if (!e.target.classList?.contains("fs-r-select__menu-list")) {
                    return e.target.scrollTop > 0
                }
            }}
        />
    )
}

export default UserLookupDropdown;
