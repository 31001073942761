import { isJsonString } from "@utils/json";

export const getConnectorColumns = ({ values, field }) => {
    let result = null
    try {
        const columns = values?.[`${field?.id}_columns`]
        if (isJsonString(columns)) {
            result = JSON.parse(columns)
        }
    } catch (e) {
        console.error(e)
    }
    return result   
}

export const getFieldColumns = (currentRecord) => {
    if (currentRecord) {
      const keys = Object.keys(currentRecord)
      return keys.filter((k) => k.slice(-8) === '_columns').reduce((a, v) => ({ ...a, [v]: currentRecord[v]}), {}) 
    }
    return {}
  }