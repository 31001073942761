export const SCHEDULE_FREQUENCY_TYPE = ['Fixed', 'Rate']
export const SCHEDULE_FREQUENCY = ['One Time', 'Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly']
export const SCHEDULE_FREQUENCY_RATE = [
    'Daily',
    '1 Weekly',
    '2 Weekly',
    '3 Weekly',
    '4 Weekly',
    '5 Weekly',
    '6 Weekly',
    '1 Monthly',
    '2 Monthly',
    '2.5 Monthly',
    '4 Monthly',
    '5 Monthly',
    '1 Yearly',
    '2 Yearly',
    '2.5 Yearly',
    '3 Yearly',
    '4 Yearly',
    '5 Yearly',
    'Custom'
]
export const SCHEDULE_RATE_UNIT = [
    'Minutes',
    'Hours',
    'Days',
    'Weeks',
    'Months',
    'Years',
]
export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const STATUS = ['Active', 'Suspended']
export const PREFIX = ['First', 'Second', 'Third', 'Fourth', 'Last']