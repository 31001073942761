import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import Button from "react-bootstrap/Button";
import { Switch, Spin } from "antd";
import useRowSelection from "@utils/hooks/useRowSelection";
import { SIZE_PER_PAGE_LIST } from "@constants/datatable";
import ModalDelete from "./ModalDelete";
import ModalAttachment from "@components/ModalAttachment";
import ModalReassign from "../../pages/TaskEditorPage/ModalReassign";
import EmptyTable from "./EmptyTable";
import SearchTable from "./SearchTable";
import Card from '../Card';
import ModalChooseColumns from "./ModalChooseColumns";
import style from "./style.module.scss";
import useNewDataTable from "@utils/hooks/useNewDataTable";
import { columnsToFields } from "@utils/form-details/datatable-config";
import useApiRequest from "@utils/hooks/useApiRequest";
import useApprovalSignal from "@utils/hooks/useApprovalSignal";
import formEditorActions from "@actions/formEditorActions";
import NewDataTablePrefetcher from "./NewDataTablePrefetcher";
import ModalFilters from "./ModalFilters";
import useColumnConfiguration from "@utils/hooks/useColumnConfiguration";
import { STATUS_IN_PROGRESS } from "../../constants/datatable";
import ModalScheduleAction from "./ModalScheduleAction";

const OPTION_SIZE = SIZE_PER_PAGE_LIST.map(({ text, ...rest }) => ({
  label: text,
  ...rest,
}));

const NewDataTable = (props) => {
  const dispatch = useDispatch();
  const isAppModule =  useRouteMatch(['/module/:id']) !== null
  const {lastHistory} = useSelector(({formEditorReducer}) => ({ lastHistory: formEditorReducer.lastHistory }));
  const { request, loading: loadingRequest } = useApiRequest();
  const pageSize = useMemo(() => lastHistory?.size || 10 , [lastHistory?.size ]);
  const {
    dataTableId,
    requestParams,
    path,
    dataMapper,
    title,
    actionPath,
    onCopy,
    exportParams,
    withCopy,
    withDelete,
    withReassign,
    withFooter = true,
    withHeader = true,
    withSelect = true,
    withSearch = true,
    withChooseColumns = true,
    withScheduleAction = false,
    withColumnFilter = false,
    className,
    extra,
    extraColumns,
    hasGridView = false,
    setIsListView,
    isListView = true,
    onTotal,
    isDefaultConfig,
    loadModuleDetail,
    ...tableProps
  } = props;
  const hookProps = {
    dataTableId,
    requestParams,
    path,
    dataMapper,
    lastHistory,
    exportParams,
    rows: pageSize
  }
  const {
    initData,
    itemData: data,
    itemQuery,
    queryColumn,
    itemOrders: orders,
    filterValues,
    onPageItem: onPage,
    onSearchItem: onSearch,
    onAdvancedFilter,
    onSortItem: onSort,
    onDeleteItem,
    onScheduleActionItem,
    apiResponse,
    loading,
    refreshDatatable,
    params,
    onExportExcel,
    isExporting
  } = useNewDataTable(hookProps)
  const error = apiResponse?.error
  const total = apiResponse?.recordsTotal
  const isLoading = loading || loadingRequest?.deleteEntry || loadingRequest?.deleteEntries
  const history = useHistory();
  const { moduleId } = useParams();
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [editId, setEditId] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showModalScheduleAction, setShowModalScheduleAction] = useState(false)
  const [scheduleActionData, setScheduleActionData] = useState({status: 0, text: 'Activate', item: {}})
  const [showModalReassign, setShowModalReassign] = useState(false);
  const [dataModalAttachment, setDataModalAttachment] = useState(null);
  const [loadingReassign, setLoadingReassign ] = useState(false);
  const { reassignMultiTasks, reassignMultiTasksInApp, reassignTaskOtherItem } = useApprovalSignal();
  const [sort, setSort] = useState({
    dataField: null,
    order: null,
  });
  const localStorageItemName = `newdatatable-columns-${dataTableId}`;
  const paginationOptions = {
    custom: true,
    totalSize: total,
    sizePerPageList: SIZE_PER_PAGE_LIST,
    variation: 'dropup',
    sizePerPage: pageSize,
    page: lastHistory?.page
  };
  const isTaskScheduler = actionPath === 'schedule-editor'
  const isTaskEditor = actionPath === 'task-editor'
  const [ reassignRecordId, setReassignRecordId ] = useState(null);
  const isOnAdvFilters = useMemo(() => filterValues?.filters?.rules?.length, [filterValues]);
  const actionsAtStart = [
    'editAction',
    'tasks',
    'workflow-item-tasks',
  ]
  const actionsAtEnd = [
    'scheduleAction',
    'deleteAction'
  ]
  const actions = [
    ...actionsAtStart,
    ...actionsAtEnd,
  ]
  const { selectRow, selected, onClearSelected } = useRowSelection({
    keyField: isAppModule && isTaskEditor ? "task_id" : "id",
  });

  const isReassignable = () => {
    try{
      if(selected.length === 1){
        const findReassignTaskLists = data.find((item)=> item.id === selected[0]);
        if(findReassignTaskLists?.workflow_status === STATUS_IN_PROGRESS){
          return true
        }
      }
    } catch (_){}
    return false
  }
  
  const onDelete = async () => {
    if (deleteMultiple) {
      await onConfirmDeleteMultiple();
    } else {
      await onConfirmDeleteSingle();
    }
  };
  const onDeleteRowItem = (row) => {
    setEditId(row.id);
    setShowDeleteConfirmation(true);
  };
  const onShowAttachment = (value, key) => {
    const data = { files:value, selected: key }
    setDataModalAttachment(data)
  };
  const onDeleteMultiple = () => {
    setDeleteMultiple(true);
    setShowDeleteConfirmation(true);
  };
  const onReassignTask = async (assigneeData) => {
    try {
      let success
      setLoadingReassign(true);
      if(!isTaskEditor){
        success = await reassignTaskOtherItem(assigneeData);
      } else if (isAppModule) {
        assigneeData.moduleId = moduleId
        success = await reassignMultiTasksInApp(selected, assigneeData);
      }else{
        success = await reassignMultiTasks(selected, assigneeData);
      }
      if (success) {
        refreshDatatable();
        onClearSelected()
      }
      setLoadingReassign(false);
    } catch (e) {
      setLoadingReassign(false);
      console.error(e)
    }
  };  
  const onConfirmDeleteSingle = async () => {
    onCloseDelete()
    onDeleteItem(
      [editId],
      () => request(
        isTaskScheduler? 'deleteTaskSchedulerEntries': 'deleteEntry',
        {
          moduleId,
          recordId: editId,
          recordIds: isTaskScheduler? [editId]: null
        }
      )
    )
  }
  const onConfirmDeleteMultiple = async () => {
    onCloseDelete()
    onDeleteItem(
      selected,
      () => request(
        isTaskScheduler? 'deleteTaskSchedulerEntries': 'deleteEntries',
        {
          moduleId,
          recordIds: selected,
        }
      )
    )
  };
  const onCloseDelete = () => {
    setEditId(null);
    setShowDeleteConfirmation(false);
    onClearSelected()
    setDeleteMultiple(false);
  };

  const onScheduleActionConfirm = async () => {
    try {
        await onScheduleActionItem(
            scheduleActionData.item.id,
            () => request(
                'taskScheduleAction',
                {
                    schedule_id: scheduleActionData.item.id,
                    status: scheduleActionData.status
                }
            ),
            scheduleActionData.status
        )
        onCloseScheduleAction()
    } catch (e) {
        console.error(e)
    }
  }

  const onScheduleAction = (row, status) => {
    let text = ''
    switch(status){
        case 0:
            text = 'Activate'
            break;
        case 1:
            text = 'Suspend'
            break;
        case 2:
            text = 'Run'
            break;
    }
    setShowModalScheduleAction(true)
    setScheduleActionData({status, text, item: row})
  };
  const onCloseScheduleAction = () => {
      setShowModalScheduleAction(false)
      setScheduleActionData({status: 0, text: 'Activate', item: {}})
  };

  const onScroll = (e) => {
    const dd = e.target.querySelector(".dropdown.show"); // dropdown-menu
    if (dd) dd.firstElementChild.click();
  };

  const onClickCard = (id) => {
    history.push({
      pathname: "/form-editor/show/" + id,
      state: { modal: {} },
    });
  }

  const onSaveColumnConfig = async (data) => {
    const { type } = exportParams
    let tabConfig
    switch (type) {
      case 'draft':
        tabConfig = 1
        break
      case 'other':
        tabConfig = 2
        break
      default:
        tabConfig = 0
    }
    dispatch(formEditorActions.setLastHistory({
      ...lastHistory,
      size: data.row,
      page: data.page
    }))
    await dispatch(formEditorActions.setUserConfig({
      moduleId,
      tabConfig,
      data
    }))
    await loadModuleDetail()
  }

  const columnProps = { 
    orders, 
    onDeleteRowItem,
    onShowAttachment,
    setSort,
    onCopy,
    withCopy,
    actionPath,
    withDelete,
    withFooter,
    withScheduleAction,
    onScheduleAction,
    localStorageItemName,
    rawColumns: props.rawColumns,
    sourceColumns: props.columns,
    sourceRows: props.rows,
    isDefaultConfig,
    onSaveColumnConfig
  }
  const [columns, tempColumn, setColumns, rows, onSaveConfig, filterDataColumns, isEmptyColumns] = useColumnConfiguration(columnProps);

  useEffect(() => {
    onTotal && onTotal(total)
  }, [total])

  useEffect(() => {
    let loaded = false
    const datatableFields = columnsToFields(columns, extraColumns)
    if (datatableFields?.length && !loaded) {
        initData(datatableFields)
    }
    return () => {
        loaded = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns)]);
  

  if (columns.length < 1) {
    return null;
  }

  const onTableChange = (type, newState) => {
    const { page, sortField, sortOrder, sizePerPage } = newState;
    if (type === "pagination") {
      onPage({ page, sizePerPage })
      dispatch(formEditorActions.setLastHistory({
        moduleId,
        tab: lastHistory?.tab,
        page,
        size: sizePerPage
      }))
      if (isDefaultConfig) {
        if (sizePerPage !==  lastHistory?.size) {
          const data = filterDataColumns(columns)
          onSaveColumnConfig({ row:sizePerPage, columns: data, page, })
        }
      }
    } else if (type === "sort") {
      onSort(
        sortField,
        sortOrder,
        columns
          .filter((col) => !col.void)
          .map((col) => col.dataField)
      )
    }
  };

  const RenderTable = (props, customProps) =>{
    const { columns: internalColumns } = props.baseProps;
    const columnsWithToggleProps = internalColumns.map((column) => {
      const { columnToggleProps } = props
      columnToggleProps.toggles = Object.entries(columnToggleProps.toggles).reduce((acc, t) => {
        const [key, state] = t
        let toggleState = state
        if (actions.includes(key)) {
          toggleState = true
        }
        return {
          ...acc,
          [key]: toggleState,
        }
      }, {})
      return {
        ...column,
        toggleProps: columnToggleProps,
      }
    });
    return (
      <div onScroll={onScroll} className="table-responsive fs-dt-entry">
        <BootstrapTable
          {...customProps}
          {...props.baseProps}
          remote={{
            pagination: Boolean(total),
            sort: false,
          }}
          onTableChange={onTableChange}
          columns={columnsWithToggleProps}
          wrapperClasses={`${style["data-table"]} ${className || ''}`} // table-responsive
          rowClasses="text-nowrap"
          selectRow={withSelect ? selectRow : undefined}
          keyField={isAppModule && isTaskEditor ? "task_id" : "id"}
          noDataIndication={
            <EmptyTable loading={isLoading} error={error} />
          }
          sort={sort}
        />
      </div>
    )
  };
  
  return (
    <>
      <ToolkitProvider 
        {...tableProps} 
        data={isLoading || isEmptyColumns ? [] : data} 
        columns={[...columns].filter(c => !c.hidden)} 
        columnToggle
      >
        {(props) => {
          return (
            <Spin spinning={loadingReassign}>
              {withHeader && 
                <div className="row pb-4 align-items-center">
                  {
                    title || extra
                        ? (
                            <div className="col-md-4">
                                <div className="d-flex align-items-center">
                                {title ? <h2 className="heading-h2">{title}</h2> : null}
                                {extra ? extra : null}
                                </div>
                            </div>
                        )
                        : null
                  }
                  <div className={`${title || extra ? 'col-md-8' : 'col-md-9 offset-md-3'} d-flex justify-content-end align-items-center`}>
                    { hasGridView && (
                      <Switch 
                        checked={isListView} // defaultChecked
                        onChange={() => setIsListView(!isListView)} 
                        checkedChildren="List" 
                        unCheckedChildren="Grid" 
                        className="mr-2"
                      />
                    ) }
                    {withSearch &&
                        <SearchTable onSearch={onSearch}
                          searchQuery={itemQuery}
                          queryColumn={queryColumn}
                          disabled={isLoading}
                          placeholder={"Search"}
                          withColumnFilter={withColumnFilter}
                          columns={columns}
                        />}
                    { onAdvancedFilter && <ModalFilters columns={columns} onSaveFilter={onAdvancedFilter} filterValues={filterValues}/> }
                    { withChooseColumns && <ModalChooseColumns
                      columns={columns} 
                      tempColumn={tempColumn}
                      rows={rows}
                      options={OPTION_SIZE}
                      onChangeColumns={setColumns}
                      onSave={onSaveConfig}
                      moduleId={moduleId}
                      isDefaultConfig={isDefaultConfig}
                      actionPath={actionPath}
                      localStorageItemName={localStorageItemName}
                    />}

                    { withReassign && isTaskEditor &&
                      <Button
                        onClick={()=>{
                          setReassignRecordId(null)
                          setShowModalReassign(true)
                        }}
                        disabled={selected.length < 1}
                        className={'ml-2'}
                      >
                        Reassign Tasks
                      </Button>
                    }
                    { withReassign && !isTaskEditor &&
                      <Button
                        onClick={()=>{
                          if(selected.length === 1){
                            const findReassignTaskLists = data.find((item)=> item.id === selected[0]);
                            setReassignRecordId(findReassignTaskLists?.id)
                            setShowModalReassign(true)
                          }
                        }}
                        disabled={selected.length !== 1 || !isReassignable()}
                        className={'ml-2'}
                      >
                        Reassign Tasks
                      </Button>
                    }
                    {exportParams && (isExporting ? <Spin className="ml-2"/> :
                      <Button
                        variant="outline-success"
                        onClick={onExportExcel}
                        disabled={total < 1}
                        className={'ml-2'}
                        title="Export to Excel"
                      >
                        <i className="fa fa-file-excel pr-0" />
                      </Button>)}
                    {withDelete && 
                      <Button
                        variant="outline-danger"
                        onClick={onDeleteMultiple}
                        disabled={selected.length < 1}
                        className={'ml-2'}
                      >
                        <i className="fa fa-trash-alt pr-0"/>
                      </Button>
                    }
                  </div>
                </div>
              }

              {total && !isEmptyColumns ? (
                <PaginationProvider
                  pagination={paginationFactory(paginationOptions)}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <div style={{ display: isListView ? 'block' : 'none' }}>
                        {RenderTable(props, {...paginationTableProps})}
                      </div>
                      { !isListView && (
                        <div className="row" >
                          { (tableProps.data || data || []).map((item, key) => (
                              <div key={item.id} className="col-6 col-md-2 mb-6" onClick={() => onClickCard(item.id)}>
                                  <Card title={item.display_name} recordCreatedBy={item.record_created_by} moduleVersion={item.module_version} appIcon={item.app_icon} {...item} />
                              </div>
                          )) }
                        </div>
                      ) }
                      <div className="d-flex justify-content-between">
                        <div className="mt-4">
                          <PaginationListStandalone {...paginationProps}/>
                        </div>
                        <div className="mt-4">
                          <SizePerPageDropdownStandalone
                            { ...paginationProps }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </PaginationProvider>
              ) : RenderTable(props)}
            </Spin>
          );
        }}
      </ToolkitProvider>

      <ModalDelete
        show={showDeleteConfirmation}
        onDelete={onDelete}
        onCloseDelete={onCloseDelete}
      />
      <ModalScheduleAction
        show={showModalScheduleAction}
        onConfirm={onScheduleActionConfirm}
        onClose={onCloseScheduleAction}
        scheduleActionData={scheduleActionData}
        loading={loadingRequest.taskScheduleAction}
      />
      <ModalReassign
        show={showModalReassign}
        onClose={() => setShowModalReassign(false)}
        onSubmit={onReassignTask}
        recordId={reassignRecordId}
      />
      <ModalAttachment
        show={dataModalAttachment !== null}
        onClose={() => {
          setDataModalAttachment(null)
        }}
        value={dataModalAttachment}
      />

      <NewDataTablePrefetcher path={path} params={params} />
    </>
  );
};

NewDataTable.propTypes = {
  keyField: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.any,
      text: PropTypes.string,
    })
  ),
  data: PropTypes.array,
  title: PropTypes.string,
  actionPath: PropTypes.oneOf(["item-editor", "task-editor", "module", "form-editor", "data-connector/add", "data-connector", "schedule-editor"]), 
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  isLoading: PropTypes.bool,
  withDelete: PropTypes.bool,
  withReassign: PropTypes.bool
};

export default NewDataTable;
