import React, { useState } from 'react';
import Flex from './Flex';
import SplashSpinner from './SplashSpinner';

export default function NoImage({ 
  val, 
  label, 
  iconClass = "", 
  size = 2 
}){
  const ext = (val?.name || '').split(".").pop().toLowerCase();
  const [loadPdf, setLoadPdf] = useState(true);
  return (
    <Flex dir="column" className="text-center" style={{ height: 'calc(100% - 39px)' }}>
      {!iconClass && ext === "pdf" ? 
        <div className="embed-responsive embed-responsive-1by1">
          {loadPdf && 
            <Flex className="position-absolute inset-0 zi-2 cwait">
              <SplashSpinner className="w-50px h-50px m-auto" />
            </Flex>
          }
          <embed 
            // className="embed-responsive-item" 
            src={val.url || (val?.originFileObj && window.URL.createObjectURL(val.originFileObj))} // val.url || val || window.URL.createObjectURL(val?.originFileObj)
            onLoad={e => {
              val.status !== "done" && window.URL.revokeObjectURL(e.target.src);
              setLoadPdf(false);
            }} 
          />
        </div>
        : 
        <Flex dir="column" justify="center" 
          className={"card-body p-1 fal fi-" + ext + " fa-" + size + "x" + (iconClass || "")} 
        >
          {val.name}
        </Flex>
      }
      
      {label && <div className="flexno figure-caption p-1 text-truncate mw-100">{val.name}</div>}
    </Flex>
  );
}
