import React from 'react';

import { ReactComponent as TextIcon } from '../../../svgs/form-editor/text-size.svg';
import { ReactComponent as TextareaIcon } from '../../../svgs/form-editor/align-left.svg';
import { ReactComponent as NumberIcon } from '../../../svgs/form-editor/number.svg';
import { ReactComponent as DateIcon } from '../../../svgs/form-editor/calendar.svg';
import { ReactComponent as DateTimeIcon } from '../../../svgs/form-editor/calendar-alt.svg';
import { ReactComponent as TimeIcon } from '../../../svgs/form-editor/time.svg';
import { ReactComponent as DropdownBasicIcon } from '../../../svgs/form-editor/dropdown-basic.svg';
import { ReactComponent as DropdownReferenceIcon } from '../../../svgs/form-editor/dropdown-reference.svg';
import { ReactComponent as DropdownExternalIcon } from '../../../svgs/form-editor/dropdown-external.svg';
import { ReactComponent as ImageIcon } from '../../../svgs/form-editor/image.svg';
import { ReactComponent as PaperClipIcon } from '../../../svgs/form-editor/paperclip.svg';
import { ReactComponent as SignatureIcon } from '../../../svgs/form-editor/signature.svg';
import { ReactComponent as EmailIcon } from '../../../svgs/form-editor/envelope.svg';
import { ReactComponent as SliderIcon } from '../../../svgs/form-editor/sliders-h.svg';
import { ReactComponent as ToggleIcon } from '../../../svgs/form-editor/toggle-on.svg';
import { ReactComponent as RatingIcon } from '../../../svgs/form-editor/star.svg';
import { ReactComponent as MultiSelectBasicIcon } from '../../../svgs/form-editor/multiselect-basic.svg';
import { ReactComponent as ChecklistIcon } from '../../../svgs/form-editor/tasks.svg';
import { ReactComponent as ButtonIcon } from '../../../svgs/form-editor/mouse.svg';
import { ReactComponent as TextareaRichIcon } from '../../../svgs/form-editor/edit.svg';
import { ReactComponent as TextareaHtmlIcon } from '../../../svgs/form-editor/richtext-html.svg';
import { ReactComponent as SummaryIcon } from '../../../svgs/form-editor/summary.svg';
import { ReactComponent as AutoNumberIcon } from '../../../svgs/form-editor/auto-number.svg';
import { ReactComponent as UserIcon } from '../../../svgs/form-editor/user-circle.svg';
import { ReactComponent as CalculationTextIcon } from '../../../svgs/form-editor/calculation-text.svg';
import { ReactComponent as CalculationNumberIcon } from '../../../svgs/form-editor/calculation-number.svg';
import { ReactComponent as CalculationCurrencyIcon } from '../../../svgs/form-editor/calculation-currency.svg';
import { ReactComponent as CalculationDateIcon } from '../../../svgs/form-editor/calculation-date.svg';
import { ReactComponent as CalculationDateTimeIcon } from '../../../svgs/form-editor/calculation-date-time.svg';
import { ReactComponent as CalculationEmailIcon } from '../../../svgs/form-editor/calculation-email.svg';
import { ReactComponent as CalculationTimeIcon } from '../../../svgs/form-editor/calculation-time.svg';
import { ReactComponent as PasswordIcon } from '../../../svgs/form-editor/password.svg';
import { ReactComponent as BarcodeIcon } from '../../../svgs/form-editor/barcode.svg';
import { ReactComponent as MultiSelectReferenceIcon } from '../../../svgs/form-editor/multiselect-reference.svg';
import { ReactComponent as MultiSelectExternalIcon } from '../../../svgs/form-editor/multiselect-external.svg';
import { ReactComponent as MultiSelectUserIcon } from '../../../svgs/form-editor/multiselect-user.svg';
import { ReactComponent as GetExternalDataIcon } from '../../../svgs/form-editor/get-external-data.svg';
import { ReactComponent as PullDataIcon } from '../../../svgs/form-editor/pulldata.svg';
import { ReactComponent as CalculationElementIcon } from '../../../svgs/form-editor/calculation-element.svg';

export default function IconField({
  icon, 
  ...etc
}){
  if(!icon){
    return null;
  }

  switch(icon){
    case "align-left": // fieldType: "textarea"
      return <TextareaIcon {...etc} />;
    case "number": // fieldType: "number"
      return <NumberIcon {...etc} />;
    case "calendar": // fieldType: "date"
      return <DateIcon {...etc} />;
    case "calendar-alt": // fieldType: "datetime"
      return <DateTimeIcon {...etc} />;
    case "time": // fieldType: "time"
      return <TimeIcon {...etc} />;
    case "dropdown-basic": // fieldType: "dropdown"
      return <DropdownBasicIcon {...etc} />;
    case "dropdown-reference": // fieldType: "lookup"
      return <DropdownReferenceIcon {...etc} />;
    case "dropdown-external": // fieldType: "remote-lookup"
      return <DropdownExternalIcon {...etc} />;
    case "image": // fieldType: "image"
      return <ImageIcon {...etc} />;
    case "paperclip": // fieldType: "file"
      return <PaperClipIcon {...etc} />;
    case "signature": // fieldType: "signature"
      return <SignatureIcon {...etc} />;
    case "envelope": // fieldType: "email"
      return <EmailIcon {...etc} />;
    case "sliders-h": // fieldType: "slider"
      return <SliderIcon {...etc} />;
    case "toggle-on": // fieldType: "switch"
      return <ToggleIcon {...etc} />;  
    case "star": // fieldType: "rating"
      return <RatingIcon {...etc} />;
    case "multiselect-basic": // fieldType: "checkbox"
      return <MultiSelectBasicIcon {...etc} />;
    case "tasks": // fieldType: "checklist"
      return <ChecklistIcon {...etc} />;
    case "mouse": // fieldType: "button"
      return <ButtonIcon {...etc} />;
    case "edit": // fieldType: "richtext"
      return <TextareaRichIcon {...etc} />;
    case "richtext-html": // fieldType: "richtext-html"
      return <TextareaHtmlIcon {...etc} />;
    case "summary": // fieldType: "aggregation"
      return <SummaryIcon {...etc} />;
    case "auto-number": // fieldType: "sequence-number"
    case "sequence-number": // fieldType: "sequence-number"
      return <AutoNumberIcon {...etc} />;
    case "user-circle": // fieldType: "user"
      return <UserIcon {...etc} />;
    case "calculation-text": // fieldType: "calculation-text"
      return <CalculationTextIcon {...etc} />;
    case "calculation-number": // fieldType: "calculation-number"
      return <CalculationNumberIcon {...etc} />;
    case "calculation-currency": // fieldType: "calculation-currency"
      return <CalculationCurrencyIcon {...etc} />;
    case "calculation-date": // fieldType: "calculation-date"
      return <CalculationDateIcon {...etc} />;
    case "calculation-date-time": // fieldType: "calculation-date-time"
      return <CalculationDateTimeIcon {...etc} />;
    case "calculation-email": // fieldType: "calculation-email"
      return <CalculationEmailIcon {...etc} />;
    case "calculation-time": // fieldType: "calculation-time"
      return <CalculationTimeIcon {...etc} />;
    case "password": // fieldType: "password"
      return <PasswordIcon {...etc} />;
    case "barcode": // fieldType: "barcode"
      return <BarcodeIcon {...etc} />;
    case "barcode-scanner": // fieldType: "barcode"
      return <BarcodeIcon {...etc} />;
    case "multiselect-reference": // fieldType: "multi-select-reference"
      return <MultiSelectReferenceIcon {...etc} />;
    case "multiselect-external": // fieldType: "multi-select-external"
      return <MultiSelectExternalIcon {...etc} />;
    case "get-external-data": // fieldType: "input-external"
      return <GetExternalDataIcon {...etc} />;
    case "multiselect-user": // fieldType: "input-external"
      return <MultiSelectUserIcon {...etc} />;
    case "pulldata":
      return <PullDataIcon {...etc} />;
    case "calculation-element":
      return <CalculationElementIcon {...etc} />;
    default: // fieldType: "text"
      return <TextIcon {...etc} />;
  }
}
