import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSelector, useDispatch } from "react-redux";
import isEqual from "lodash/isEqual";
import optionsActions from "@actions/optionsActions";

const useLookupPayload = ({ normalizer, formValues, onUpdate, remoteUrl } = {}) => {
    const dispatch = useDispatch()
    const { govHeaders } = useSelector(state => state.optionsReducer)
    const [payloadValues, setPayloadValues] = useState()
    const [currentUrl, setCurrentUrl] = useState()
    const [payload, setPayload] = useState()
    const loadGovHeaders = () => {
        if (!govHeaders && remoteUrl) {
            dispatch(optionsActions.getGovHeaders())
        }
    }
    const debounced = useDebouncedCallback(
        // function
        async (values,remoteUrl) => {
            if ((!isEqual(payloadValues, values) || !isEqual(currentUrl,remoteUrl)) && (govHeaders || !remoteUrl) && normalizer) {
                const normalizedPayload = await normalizer({ reqGovHeaders: govHeaders });
                setCurrentUrl(remoteUrl);
                setPayloadValues(values);
                if (!isEqual(payload, normalizedPayload)) {
                    setPayload(normalizedPayload)
                    onUpdate && onUpdate(normalizedPayload)
                }
            }
        },
        // delay in ms
        1000
    );
    useEffect(() => {
        if (formValues || remoteUrl) {
            debounced(formValues,remoteUrl)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues, govHeaders,remoteUrl])

    useEffect(() => {
        let headersLoaded = false
        if (!headersLoaded) loadGovHeaders()
        return () => {
            headersLoaded = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        requestPayload: payload,
        govHeaders,
    }
}

export default useLookupPayload