import { setAttr } from '../Q';// getAttr

// Add behavior antd components

const A_EXP = "aria-expanded";

// <DatePicker /> : https://ant.design/components/date-picker = onOpenChange(open)
// export function onToggleDatePicker(open){
//   // console.log('open: ', open);
//   // let attr = "aria-expanded";
//   if(open){
//     setTimeout(() => {
//       // Default antd = .ant-picker | Custom className = .fs-antdPop
//       setAttr(document.activeElement, { [A_EXP]: "true" });
//     }, 150);
//   }
//   // else{
//   //   console.log('else document.activeElement: ', document.activeElement);
//   //   setAttr(document.activeElement, attr);
//   // }
// }

export function onFocusDatePicker(e){
  setAttr(e.target, { [A_EXP]: "true" });
}

export function onBlurDatePicker(e){
  setAttr(e.target, A_EXP);
}

