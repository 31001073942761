import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';// Route,

import Flex from '../../components/Flex';
// import Head from '../../components/Head';
import Aroute from '../../components/Aroute';
// import { Img } from '../../components/Img';

export default function UnderConstruction(){
	// let location = useLocation();

  const { isAuthorized,  } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
	);

	// Not Found - 404
	return (
		<Flex dir="column" justify="center" align="center" 
			className="min-vh-100 bgi-size-cover bgi-no-repeat bg-white" // h-100
			style={{ backgroundImage: 'url("/media/bg/bg_prisma.svg")' }} // png
		>
			{/* <Head title="Under Construction" /> */}
			
			<Flex wrap dir="row" align="center" className="container">
				<div className="col-md-6 font-weight-bold">
					<h1 className="font-weight-bolder txt-navy mb-5">{/* fs-25r <div className="h5 font-weight-bolder"></div> */}
						Making Tax Digital for Corporation
					</h1>

					<p>MTD for Corporation Tax is part of the planned expansion of the UK Governments MTD service. However, at this stage there are no confirmed dates when this will become available. We will update you when more information is known.</p>
					<p>FinanSys are committed to providing MTD apps as the services become available, and all apps will be a standard part of FinanSys Flow and available to all users free of charge.</p>
					{/* /dashboard */}
					<Aroute to={isAuthorized ? "/tasks" : "/"} btn="primary" className="mt-2">Back to home</Aroute>
				</div>
				
				<div className="col-md-6 text-center">
					{/* /media/misc/under_construction_pc.png */}
					<img alt="PC" 
						src="/media/misc/under_construction_pc.svg" 
						// fluid 
						className="d-inline-block w-75 ratio-1-1" 
						loading="eager" // auto
						// height={284} 
						// onLoad={e => e.target.classList.add("img-fluid")} 
					/>
				</div>
			</Flex>
		</Flex>
	);
}



