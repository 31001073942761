import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import formEditorActions from "@actions/formEditorActions";
import { initRequest } from "../actionMethods";
import { useQueryClient } from "@tanstack/react-query"

const useApprovalSignal = () => {
    const dispatch = useDispatch()
    const request = initRequest(dispatch)
    const { moduleId, taskId, recordId } = useParams()
    const queryClient = useQueryClient()
    const onTaskSuccess = async (response) => {
        const messageType = response?.success
            ? 'success'
            : 'error'
        message[messageType](response?.message)
        queryClient.invalidateQueries(['task-detail', taskId])
        await dispatch(formEditorActions.getModuleItems({ moduleId }))
        await dispatch(formEditorActions.getModuleTasks({ moduleId }))
    }
    const submitTask = () => {
        return request(
            'submitTask',
            { taskId },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const rejectTask = ({ description }) => {
        return request(
            'rejectTask',
            {
                taskId,
                description,
            },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const reassignTask = (assignTo, comments) => {
        return request(
            'reassignTask',
            { taskId, assignTo, comments },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const reassignMultiTasks = (taskIds, assigneeData) => {
        return request(
            'reassignMultiTasks',
            { taskIds, assigneeData },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const reassignMultiTasksInApp = (taskIds, assigneeData) => {
        return request(
            'reassignMultiTasksInApp',
            { taskIds, assigneeData },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const reassignTaskOtherItem = (assigneeData) => {
        const { assignTo, comments, reassignFrom } = assigneeData
        return request(
            'reassignTask',
            { taskId: reassignFrom?.value, assignTo: assignTo?.value, comments },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const withdrawTask = () => {
        return request(
            'withdrawTask',
            { moduleId, recordId },
            async response => {
                await onTaskSuccess(response)
            }
        )
    } 
    const sendBackTask = ({ goBackToActivityId, message}) => {
        return request(
            'sendBackTask',
            { taskId, goBackToActivityId, message },
            async response => {
                await onTaskSuccess(response)
            }
        )
    }
    const getTaskDetails = () => {
        return request(
            'getTaskDetails',
            { taskId },
        )
    }
    return {
        submitTask,
        rejectTask,
        reassignTask,
        reassignMultiTasks,
        reassignMultiTasksInApp,
        reassignTaskOtherItem,
        sendBackTask,
        getTaskDetails,
        withdrawTask
    }
}

export default useApprovalSignal