import { useEffect } from "react"
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query"
import useApiRequest from "../../utils/hooks/useApiRequest"

export const useRemoteLookupQuery = ({ payload, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = ['remote-lookup', JSON.stringify(payload)]
    return useQuery(
        queryKey,
        () => {
            if (payload?.data && enabled) {
                return request('remoteLookup', payload)
            }
            return {}
        },
        {
            select: ({ data }) => {
                if (data) {
                    return data
                }
                return null
            },
            suspense: false,
            enabled,
        }
    )
}

export const useAdvancedRemoteLookupQuery = ({ payload, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = ['advanced-remote-lookup', JSON.stringify(payload)]
    return useQuery(
        queryKey,
        () => {
            if (payload?.data && enabled) {
                return request('dataConnectorExecute', payload)
            }
            return {}
        },
        {
            select: (response) => {
                let {data, success} = response

                if(!success){
                    return []
                }
                
                if (data) {
                    return data
                }
                return null
            },
            suspense: false,
            enabled,
        }
    )
}

export const useDataConnectorDetailQuery = ({ dataConnectorId, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = ['data-connector-detail', dataConnectorId]
    return useQuery(
        queryKey,
        () => {
            if (dataConnectorId && enabled) {
                return request('fetchDataConnectorById', { recordId: dataConnectorId })
            }
            return {}
        },
        {
            select: (response) => {
                const { data: details } = response || {}
                const { display_columns } = details || {};
                const { available } = display_columns ?? {};
                return {
                    ...details,
                    remoteUrl: details?.url || details?.request_data?.url,
                    hideFields: available || [],
                }
            },
            suspense: false,
            enabled,
        }
    )
}

export const useRecordModuleLookupQuery = ({ payload, enabled, formkey }) => {
    const { request } = useApiRequest()
    const queryKey = ['record-module-lookup', JSON.stringify(payload), formkey]
    return useQuery(
        queryKey,
        () => {
            if (payload?.params && enabled) {
                return request('recordModuleLookup2', payload)
            }
            return {}
        },
        {
            select: ({ data }) => {
                if (data) {
                    return data
                }
                return []
            },
            suspense: false,
            enabled,
        }
    )
}

export const useAdhocRecordModuleLookupQuery = ({ payload, formkey }) => {
    const { request } = useApiRequest()
    const queryKey = ['record-module-lookup-adhoc', JSON.stringify(payload), formkey]
    const queryClient = useQueryClient()
    return {
        query: useQuery(
            queryKey,
            () => {
                if (payload?.params) {
                    return request('recordModuleLookup2', payload)
                }
                return {}
            },
            {
                select: ({ data }) => {
                    if (data) {
                        return data
                    }
                    return []
                },
                suspense: false,
                enabled: false,
                keepPreviousData: true,
            }
        ),
        invalidate: () => queryClient.invalidateQueries(queryKey)
    }
}

export const useUserLookupQuery = ({ payload, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = ['user-lookup', JSON.stringify(payload)]
    return useQuery(
        queryKey,
        () => {
            if (payload?.params && enabled) {
                return request('userLookup2', payload)
            }
            return {}
        },
        {
            select: ({ data }) => {
                if (data) {
                    return data
                }
                return []
            },
            suspense: false,
            staleTime: 5000,
            enabled,
        }
    )
}

export const useFieldLoadingQuery = () => {
    const isFetching = useIsFetching()
    return isFetching > 0
}

export const useInvalidateLookupDropdownQueries = ({payload, enabled}) => {
    const queryClient = useQueryClient()
    useEffect(() => {
      if (enabled) {
        queryClient.invalidateQueries(['record-module-lookup', payload])
      }
    }, [enabled])
    return null
}

export const useDataEntryLookupQuery = (params) => {
    const { request } = useApiRequest()
    const queryKey = ['data-entry-lookup', JSON.stringify(params)]
    return useQuery(
        queryKey,
        () => {
            if (params) {
                return request('dataEntryLookup', params)
            }
            return {}
        },
        {
            select: ({ data }) => {
                if (data) {
                    return data.items || []
                }
                return []
            },
            suspense: false,
            enabled: true,
        }
    )
}