import React, { createContext, useReducer, useEffect } from "react";
import { useSelector } from "react-redux";
import {HyperFormula} from "hyperformula";
import { message } from "antd";
import {getSectionSchemas} from "@utils/section-schema";
import useSheet from "@utils/hooks/useSheet";
import { F_TIME } from "../app/components/form-editor/const";
import {ExtractText, NumberValue, Rand, RandBetween, GetSummary, GetSummaryApp, DuplicateCheck, translations, Now, Today} from "../formulas";
import useResolveCalculation from '../utils/hooks/useResolveCalculation'

const initialState = {
    sheetsMap: {},
    hfInstance: null,
    isCalculating: true,
    tableValues: {},
};
const reducer = (state, action) => {
    const { hfInstance, sheetsMap, isCalculating, tableValues } = action.payload
    switch (action.type) {
        case 'SET_HF_INSTANCE':
            return {
                ...state,
                hfInstance,
            };
        case 'SET_SHEETS_MAP':
            return {
                ...state,
                sheetsMap: {
                    ...state.sheetsMap,
                    ...sheetsMap,
                }
            };
        case 'SET_IS_CALCULATING':
            return {
                ...state,
                isCalculating,
            };
        case 'SET_TABLE_VALUES':
            return {
                ...state,
                tableValues: action.payload,
            }
        default:
            return state
    }
}
export const ModuleContext = createContext()
export const ModuleProvider = (props) => {
    const [, contextHolder] = message.useMessage();
    const {
        children,
        sheets,
        ...hookProps
    } = props
    const [state, dispatch] = useReducer(reducer, initialState);
    const { date_format } = useSelector(state => state.timezoneReducer);

    useSheet({
        ...hookProps,
        moduleStates: state,
        dispatch,
    })
    useEffect(() => {
        const mapSheets = async () => {
            HyperFormula.registerFunctionPlugin(ExtractText, translations)
            HyperFormula.registerFunctionPlugin(NumberValue, translations)
            HyperFormula.registerFunctionPlugin(Rand, translations)
            HyperFormula.registerFunctionPlugin(RandBetween, translations)
            HyperFormula.registerFunctionPlugin(GetSummary, translations)
            HyperFormula.registerFunctionPlugin(GetSummaryApp, translations)
            HyperFormula.registerFunctionPlugin(DuplicateCheck, translations)
            HyperFormula.registerFunctionPlugin(Now, translations)
            HyperFormula.registerFunctionPlugin(Today, translations)
            const sheetsMap = getSectionSchemas(sheets)

            //convert sheetsMap into object for faster cell address retrieval
            let colIndex = 0
            const fieldsOnSheets = {}
            for (const [key, value] of Object.entries(sheetsMap)) {
                const section = key
                for (let index = 0; index < value.length; index++) {
                    const id = value[index];
                    fieldsOnSheets[id] = [section, colIndex, index]
                }
                colIndex += 1
            }
            
            const hfSheets = Object.keys(sheetsMap).reduce((smKeys, sh) => {
                return {
                    ...smKeys,
                    [sh]: []
                }
            }, {})
            const hfInstance = HyperFormula.buildFromSheets(hfSheets, {
                licenseKey: '9747d-c63da-72524-4b013-b4c5d',
                dateFormats: [date_format.toUpperCase(), 'DD/MM/YYYY'],
                timeFormats: [F_TIME.toLowerCase()],
            })
            await dispatch({
                type: 'SET_SHEETS_MAP',
                payload: {
                    sheetsMap: fieldsOnSheets,
                },
            })
            await dispatch({
                type: 'SET_HF_INSTANCE',
                payload: {
                    hfInstance,
                },
            })
        }
        mapSheets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheets])

    const removeLocalStorages = (regex) => {
        let arr = [];
        for (let i = 0; i < localStorage.length; i++){
            if (localStorage.key(i).match(regex)) {
                arr.push(localStorage.key(i));
            }
        }

        for (let i = 0; i < arr.length; i++) {
            localStorage.removeItem(arr[i]);
        }
        localStorage.removeItem('randValue')
    }

    const { 
        resolveCalculation, 
        resolving, 
    } = useResolveCalculation({ calculating: state.isCalculating })

    useEffect(() => {
        //clear now/today to reset saved format on page load
        removeLocalStorages(/nowValue_|todayValue_/)
        return () => {
            removeLocalStorages(/randBetweenValue_|sumappValue_|nowValue_|todayValue_/)
        }
    }, [])

    useEffect(() => {
        resolveCalculation(async () => {
            if (Object.keys(state.tableValues).length === 0 && hookProps.initialValues) {
                dispatch({
                    type: 'SET_TABLE_VALUES',
                    payload: hookProps.initialValues
                })
            }
        })
    }, [hookProps.initialValues])

    return (
        <ModuleContext.Provider value={{ moduleStates: state, dispatch, resolveCalculation, resolving }}>
            { contextHolder }
            { children }
        </ModuleContext.Provider>
    )
}
