import {useEffect, useMemo, useState} from "react";
import {dataTablePayload} from "../form-editor/normalize-payload";
import { useDataTableQuery, useInvalidateModuleDatatableQueries } from "../states/NewDataTable.state";
import message from '@utils/message';
import useExportExcel from "./useExportExcel";

const useNewDataTable = ({path, exportParams, dataTableId, requestParams, dataMapper, lastHistory, rows}) => {
    const [itemData, setItemData] = useState([])
    const [apiResponse, setApiResponse] = useState({})
    const [itemPage, setItemPage] = useState(lastHistory?.page || 1)
    const [sizePerPage, setSizePerPage] = useState(lastHistory?.size)
    const [itemQuery, setItemQuery] = useState(localStorage.getItem(`datatable-query-${dataTableId}`))
    const [queryColumn, setQueryColumn] = useState(localStorage.getItem(`datatable-query-column-${dataTableId}`))
    const [queryColumns, setQueryColumns] = useState(localStorage.getItem(`datatable-query-columns-${dataTableId}`))
    const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem(`datatable-filtervalues-${dataTableId}`)))
    const [itemOrders, setItemOrders] = useState([])
    const [fields, setFields] = useState([])
    const [params, setParams] = useState()
    const {isExporting, exportToExcel} = useExportExcel();
    const resetData = () => {
        setItemData([])
        setApiResponse({
            recordsTotal: 0,
        })
    }
    const {
        data,
        isLoading,
        isFetching,
        isRefetching,
    } = useDataTableQuery(path, params)
    const refreshDatatable = useInvalidateModuleDatatableQueries(params?.moduleId)
    const loading = useMemo(() => isLoading || isFetching || isRefetching, [
        isLoading,
        isFetching,
        isRefetching,
    ])
    useEffect(() => {
        let _data = data?.data
        let _response = data
        if (_data) {
            if (typeof dataMapper === 'function') {
                _data = _data.map(dataMapper)
            }
            setItemData(_data)
        }
        const {search, filters} = params?.data || {};
        if ((search?.searchValue || filters?.rules?.length) && _response?.recordsFiltered !== undefined) {
            _response.recordsTotal = _response.recordsFiltered
        }
        setApiResponse(_response)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, params])
    const loadDataByPath = async (reqParams) => {
        resetData()
        setParams(reqParams)
    }
    const getData = (params = {}, fieldsPayload = fields, filters) => {
        const orderData = getOrderData();
        return loadDataByPath({
            ...requestParams,
            data: dataTablePayload({
                dataTableId,           
                ...combineFilters({query:itemQuery, column:queryColumn, columns:queryColumns, advFilters:filters || filterValues?.filters}),
                ...params,
                ...(
                    (orderData?.orders || []).length && (orderData.columns || []).length
                        ? {
                            orders: orderData.orders,
                        }
                        : {}
                ),
                fields: fieldsPayload?.length
                    ? fieldsPayload
                    : orderData?.columns || [],
            })
        })
    }
    const onExportExcel = async () => {
        const { moduleId } = params;
        const { columns, filters, orders, search} = params.data;
        const { path, type } = exportParams;
        const payload = { type, columns, filters, orders, search };
        exportToExcel(path, { refId:moduleId, payload});
    }
    const getOrderData = () => {
        const storedOrders = localStorage.getItem(`newdatatable-orders-${dataTableId}`)
        const storedColumns = localStorage.getItem(`newdatatable-columns-${dataTableId}`)
        return {
            orders: JSON.parse(storedOrders),
            columns: JSON.parse(storedColumns),
        }
    }
    const initData = async (fieldsPayload) => {
        const orderData = getOrderData()
        setItemPage(lastHistory?.page)
        setSizePerPage(lastHistory?.size)
        setFields(fieldsPayload)
        if (orderData?.orders && orderData?.columns) {
            setItemOrders(orderData.orders)
        }
        if (dataTableId) {
            getData({page: lastHistory?.page, sizePerPage: lastHistory?.size}, fieldsPayload)
        }
    }
    const orderExists = (columnName, direction) => order => {
        return order.columnName === columnName && order.direction === direction
    }
    const onPageItem = ({page, sizePerPage}) => {
        setItemPage(page)
        setSizePerPage(sizePerPage)
        getData({
            page,
            sizePerPage,
            query: itemQuery,
        })
    }
    const clearItemQuery = () => {
        setItemQuery("")
        setQueryColumn(null);
        localStorage.removeItem(`datatable-query-${dataTableId}`);
        localStorage.removeItem(`datatable-query-column-${dataTableId}`);
    }
    useEffect(() => {
        window.addEventListener("beforeunload", clearItemQuery);
        return () => window.removeEventListener("beforeunload");
    }, []);

    const combineFilters = ({query, column, columns, advFilters}) =>{
        let simpleRule = {
            id:"simplerule",
            field:column,
            operator:"contains",
            value:query
        };
        let cFilters = null;
        const hasAdvFilters = advFilters?.rules?.length > 0;
        if(hasAdvFilters){
            if(query){
                if (!column && columns){             
                    simpleRule = {
                        combinator:"or",
                        id:"simplerules",
                        rules: columns?.map((c) => {
                            return {
                                id:c.value,
                                field:c.value,
                                operator:"contains",
                                value:query
                            }
                        })
                    }
                }
                cFilters = {
                    combinator:"and",
                    id:"root",
                    rules: [
                        simpleRule ,
                        { 
                            ...advFilters,
                            id:"advroot"
                        }
                    ]
                }
            } else if (!query){
                cFilters = advFilters;
            } 
        } else {
            if(query && column) {
                cFilters =  {
                    rules:[simpleRule]
                }
            } else {
                cFilters  = {rules: []};
            }
        } 
        
        return {
            ...(cFilters ? { filters:cFilters } :{}),
            ...(query && !hasAdvFilters && !column ? {query}:{})
        }
    } 
    
    const onAdvancedFilter = (values) =>{    
        const {filters} = values;    
        setFilterValues(values);
        localStorage.setItem(`datatable-filtervalues-${dataTableId}`, JSON.stringify(values));
        setItemPage(1);
        getData({
            page: 1,
            sizePerPage: rows,
            ...(combineFilters({query:itemQuery, column:queryColumn, columns:queryColumns, advFilters:filters}))
        });
    }
    const onSearchItem = (query, column, columns) => {
        setItemQuery(query)
        localStorage.setItem(`datatable-query-${dataTableId}`, query);
        setItemPage(1)
        setQueryColumn(column)
        setQueryColumns(columns);
        if (query && column) {
            localStorage.setItem(`datatable-query-column-${dataTableId}`, column);
        }else {
            localStorage.removeItem(`datatable-query-column-${dataTableId}`);
        }
        if(columns){
            localStorage.setItem(`datatable-query-columns-${dataTableId}`, columns);
        } else {
            localStorage.removeItem(`datatable-query-columns-${dataTableId}`);
        }
        getData({
            page: 1,
            sizePerPage: rows,
            ...(combineFilters({query, column, columns, advFilters:filterValues?.filters}))
        });
    }
    const onSortItem = (columnName, direction, columns) => {
        const isOrderExists = orderExists(columnName, direction)
        const orders = [{
            columnName,
            direction,
        }]
        if (!itemOrders.find(isOrderExists)) {
            setItemOrders(orders)
            localStorage.setItem(`newdatatable-orders-${dataTableId}`, JSON.stringify(orders))
            columns.length > 0 && localStorage.setItem(`newdatatable-columns-${dataTableId}`, JSON.stringify(columns))
            getData({page: lastHistory?.page, sizePerPage: lastHistory?.size})
        }
    }
    const onDeleteItem = async (ids, action, records = [...itemData]) => {
        let result = records
        try {
            const recordsAfterDelete = records.filter(({ id }) => !ids.includes(id))
            const { success, message: respMessage } = await action(ids)
            if (success) {
                result = recordsAfterDelete
                refreshDatatable()
            }  
            message[success ? "success" : "error"](respMessage?? 'Records have been deleted');
        } catch (e) {
            console.error(e)
        }
        setItemData(result)
        return result
    }
    const onScheduleActionItem = async (id, action, status, records = [...itemData]) => {
        let result = records
        try {
            const recordsAfterChanges = records.map((item) => {
                if(item.id === id){
                    return {
                        ...item,
                        schedule_status: status === 0? 'Active': status === 1? 'Suspended': item.status
                    }
                } else{
                    return item
                }
            })
            const { success, message: respMessage } = await action()
            if (success) {
                result = recordsAfterChanges
                refreshDatatable()
            }  
            message[success ? "success" : "error"](respMessage?? 'Schedule has been changed');
        } catch (e) {
            console.error(e)
        }
        setItemData(result)
        return result
    }
    
    return {
        itemData,
        itemPage,
        sizePerPage,
        itemQuery,
        itemOrders,
        queryColumn,
        initData,
        onPageItem,
        onSearchItem,
        onAdvancedFilter,
        onSortItem,
        onDeleteItem,
        onScheduleActionItem,
        onExportExcel,
        isExporting,
        apiResponse,
        loading,
        getData,
        params,
        filterValues,
        refreshDatatable
    }
}

export default useNewDataTable