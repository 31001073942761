
export const parseLookupLabel = (option, valueKeys, isEditable) => {
  try {
      let label = ''
      if (typeof option === 'string' || !isEditable) label = option
      if (typeof option === 'object' && isEditable) {
          valueKeys.forEach((item, idx)=> {
              label += valueKeys.length-1 === idx ? option[item] : `${option[item]} - `
          })
      }
      return label
  } catch (err) { console.error(err) }
}