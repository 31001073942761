import moment, {isMoment} from "moment-timezone";
import { useSelector } from "react-redux";

const useTimeZone = () => {
    const  { date_format, datetime_format, time_format, time_zone:timezoneId } = useSelector(state => state.timezoneReducer);
    const iso_format = 'YYYY-MM-DDTHH:mm:ss';
    const international = 'YYYY-MM-DD HH:mm:ss';
    moment.tz.setDefault(timezoneId);
    const formatList = [ date_format, datetime_format, time_format, "HH:mm", international, iso_format];
    const unusualFormats = [
        "DD MMMM YYYY", "MMMM DD YYYY", "YYYY MMMM DD",  "D MMMM YYYY", "MMMM D YYYY", "YYYY MMMM D",
        "DD MMM YYYY", "MMM DD YYYY", "YYYY MMM DD", "D MMM YYYY", "MMM D YYYY", "YYYY MMM D",
		"DD MM YYYY", "MM DD YYYY", "YYYY MM DD", "D M YYYY", "M D YYYY", "YYYY M D", 
		"DD M YYYY", "M DD YYYY", "YYYY M DD", "YYYY MM D", "D MM YYYY", "MM D YYYY",
		"DD MMMM YY", "MMMM DD YY", "YY MMMM DD", "D MMMM YY", "MMMM D YY", "YY MMMM D",
        "DD MMM YY", "MMM DD YY", "YY MMM DD", "D MMM YY", "MMM D YY", "YY MMM D", 
		"DD MM YY", "MM DD YY", "YY MM DD", "D MM YY", "MM D YY", "YY MM D",
        "DD M YY", "M DD YY", "YY M DD", "D M YY", "M D YY", "YY M D"
    ]
    const extractFormat = (dateValue) => {
        let result = null;
        try{
            let value = dateValue?.toString().toUpperCase();
            value = value.replace(/\b(\d+)(ST|ND|RD|TH)\b/, '$1'); // Remove the 'st', 'nd', 'rd', 'th' suffixes
            if(/^(\d{2} ([A-Z]*[\/][A-Z]*) \d{2})/.test(value)){
                result = "DD MMM/MMM YY"
            }else{
                formatList.forEach((format) => {
                    if (!result && moment(value, format, true).isValid()) {
                        result = format;
                    }
                });
                if(!result){
                    value = value?.replaceAll(/\.|\-|\/|\"|\,/g, " ");
                    while(value?.includes("  ")){
                        value = value?.replaceAll("  ", " ");
                    }
                    value = value?.trim();
                    unusualFormats.forEach((format) => {
                        if (!result && moment(value, format, true).isValid()) {
                            result = format;
                        }
                        const datetimeFormat = `${format} ${time_format}`;
                        if (!result && moment(value, datetimeFormat, true).isValid()) {
                            result = datetimeFormat;
                        }
                    });
                }
            }
        }catch(e) {
            return null;
        }
        return result;
    }
    const normaliseDateStr = (value, format) =>{
        if(format?.includes("MMM/MMM")){
            const DD_MMM = value.match(/^(\d{2} [A-Z]{3})/)?.[0];
            const YYYY = value.match(/ \d{4}$/)?.[0];
            const YY = value.match(/ \d{2}$/)?.[0];
            value = `${DD_MMM}${YYYY || YY}`;
            format = `DD MMM ${YYYY ? "YYYY" : "YY"}`;
        }
        return { value, format };
    }
    const toValidMoment = (value) =>{
        if(!value) return null;
        if(isMoment(value)) return value;
        let format = extractFormat(value);
        const normalised = normaliseDateStr(value, format);
        value = normalised.value;
        format = normalised.format;
        return format ? moment(value, format) : moment(value);
    }
    const toLocalMoment = (value) => {
        if(!value) return null;
        const mdate = toValidMoment(value).utc(true);
        return mdate.tz(timezoneId);
    }
    const toUTCMoment = (value) => {
        if(!value) return null;
        const mdate = toValidMoment(value);
        return mdate.utc();
    }
    const toDateStr = (value) => {
        if(!value) return "";
        return toValidMoment(value).utc(true).format(date_format);
    }
    const toDateTimeStr = (value) => {
        if(!value) return "";
        return toValidMoment(value)?.format(datetime_format);
    }
    const toLocalDateTimeStr = (value) => {
        if(!value) return "";
        return toLocalMoment(value)?.format(datetime_format);
    }
    const toLocalTimeStr = (value) => {
        if(!value) return "";
        return toLocalMoment(value)?.format(time_format);
    }
    const toUTCDateTimeStr = (value) => {
        if(!value) return "";
        return toUTCMoment(value)?.format(datetime_format);
    }
    const toUTCTimeStr = (value) => {
        if(!value) return "";
        return toUTCMoment(value)?.format(time_format);
    }
    const generateDateTime = ({ value, isActual }) => {
        if (isActual) {
            return moment().utc();
        } else if (value) {
            return toValidMoment(value).utc(true);
        }
        return null
    }
    return {
        timezoneId,
        date_format, 
        datetime_format, 
        time_format, 
        iso_format,
        extractFormat,
        generateDateTime,
        toValidMoment,
        toDateStr,
        toDateTimeStr,
        toLocalMoment,
        toLocalTimeStr,
        toLocalDateTimeStr,
        toUTCMoment,
        toUTCDateTimeStr,
        toUTCTimeStr
    }
}

export default useTimeZone