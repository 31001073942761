import React from "react";
import { components } from "react-select";
// import { Avatar } from "antd";
// import { UserOutlined } from "@ant-design/icons";
import DisplayUser from "../DisplayUser";
import style from "./style.module.scss";

export const DropdownSingleValueContainerUser = props => {
    const { children, getValue } = props
    const [result] = getValue()
    const { label } = result || {}
    const transformed = children.map(c => {
        const hasValue = typeof c?.props?.children === 'object'
        return hasValue
            ? {
                ...c,
                props: {
                    ...c.props,
                    children: null,
                }
            }
            : c
    })

    return (
        <components.ValueContainer {...props} className={`${style['value-container--user__wrapper']} ${label ? 'px-1' : ''}`}>
            <DisplayUser
                componentClass={`${props.className} flex-grow-1`}
                user={label}
            />
            { transformed }
        </components.ValueContainer>
    );
};

export const DropdownMultipleValueContainerUser = props => {
    const { children, data } = props
    const { label } = data || {}

    const transformed = arr => arr.map(c => {
        const hasValue = typeof c?.props?.children === 'object'
        if (Array.isArray(c)) {
            return transformed(c)
        }
        return hasValue
            ? {
                ...c,
                props: {
                    ...c.props,
                    children: null,
                }
            }
            : c
    })

    return (
        <components.MultiValueContainer {...props} className={`${style['value-container--user__wrapper']} ${label ? 'px-0' : ''}`}>
            <DisplayUser
                componentClass={`${props.className} pr-2`}
                user={label}
            />
            { transformed(children) }
        </components.MultiValueContainer>
    );
};

export default {
    DropdownSingleValueContainerUser,
    DropdownMultipleValueContainerUser,
}