import { useState } from "react";
import axios from 'axios';
import fileDownload from "@utils/file/fileDownload";
import message from '@utils/message';
import { isObj } from '@utils/Q';

const useExportExcel = () =>{
    const [isExporting, setIsExporting] = useState(false);
    const exportToExcel = async(path, params) =>{
        setIsExporting(true);
        const {refId, payload} = params;
        const isStandardColumns =  payload.columns[0]?.data && payload.columns[0]?.name;
        const columns = payload.columns.length > 0 ? 
        isStandardColumns ? payload.columns : payload.columns.map(val => ({
            data: val.dataField,
            name: val.dataField
        })) : []
        const settings = { rowlimit: 0, columnAlignment: 0 };
        const search = isObj(payload.search) ? payload.search : {
            searchValue: payload.search ?? "",
            searchRegex: false
        }
        const payloadBody = {
            ...payload,
            columns,
            search,
            settings
        }
        let url = `/api/${path}/export?format=excel`;
        if(refId){
            if(path.includes('entrymodule')){
                url += `&moduleId=${refId}`
            }else if(path.includes('referencedata')){
                url += `&referenceDataId=${refId}`
            }
        }
        const response = await axios.post(url, payloadBody, { responseType: 'blob' });
        if (response?.status === 200) {
            const filename = response.headers['content-disposition'].split('filename=')[1]?.replaceAll('\"', '');
            fileDownload(response.data, filename);
        } else {
            message["error"]("Export to Excel Failed");
        }
        setIsExporting(false);  
    }

    return{
        exportToExcel,
        isExporting
    }
}

export default useExportExcel