import React from 'react';
import Flex from '../../components/Flex';
import Aroute from '../../components/Aroute';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { APP_NAME, APP_YEAR } from '../../../data/appData';

export default function NotFound() {
    return (
        <Flex dir="column" className="flex-root">
            <Flex id="kt_login"
                  dir="column"
                  className="flex-grow-1 login login-1 login-signin-on flex-lg-row flex-row-fluid bg-light bgi-no-repeat bgi-size-cover"
                  style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-login.jpg")})` }}
            >
                <Flex
                    className="flex-row-auto flex-grow-1 bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                >
                    <Flex dir="column" justify="start" className="flex-row-fluid">
                        <Aroute to="/" className="d-flex flex-column-auto mt-5 active justify-content-center flex-grow-1 align-items-center">
                            <img
                                height="50"
                                loading="lazy"
                                alt={APP_NAME}
                                src={toAbsoluteUrl("/media/logos/FinanSys-Apps-White.svg")} // finansys_flow_white_2.svg
                            />
                        </Aroute>

                        <Flex dir="column" justify="start" className="align-items-center flex-column-fluid mb-30 pt-20 text-white">
                            <p className="font-size-h3"><strong>Page Not Found</strong></p>
                            <p>The page that you're looking for does not exist or you do not have sufficient permission to access it.</p>
                            <Aroute to="/" className="d-flex flex-column-auto active">
                                <span>Back to the Homepage</span>
                            </Aroute>
                        </Flex>

                        <div className="d-none flex-column-auto d-lg-flex justify-content-center align-items-center mt-10">
                            <div className="font-weight-bold text-white">{/* opacity-70  */}
                                &copy; {APP_YEAR} FinanSys Limited
                            </div>
                        </div>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

