import React from 'react';

import { Cx } from '../../utils/Q';

export default function SplashSpinner({ className }){
  return (
    <svg className={Cx("splash-spinner", className)} viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  )
}