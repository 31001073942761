import React from "react";
import { components } from "react-select";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import style from "./style.module.scss";

const DropdownOption = ({ children, data, ...props }) => {
    const { type, email, label } = data

    return (
        <components.Option {...props}>
            <div className="d-flex">
                <div className={`text-center mr-2 ${style['option-icon']}`}>
                    { type === 'Team' ? <i className="fal fa-users"/> : <Avatar size="small" icon={<UserOutlined />} /> }
                </div>
                <div className="flex-grow-1 pt-1">
                    <span className="d-block">{ label }</span>
                    { email ? <span className="text-sm">{ email }</span> : null }
                </div>
            </div>
        </components.Option>
    );
};

export default DropdownOption