import OpenAI from "openai"
import { localStorageThreadIdKey } from "../../app/constants/open-ai";

const openai = new OpenAI({
    organization: process.env.REACT_APP_OPEN_AI_ORGANIZATION_ID,
    project: process.env.REACT_APP_OPEN_AI_PROJECT_ID,
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

export const deleteLocalOpenAiThread = async () => {
    const threadId = localStorage.getItem(localStorageThreadIdKey)
    if(threadId){
        await openai.beta.threads.del(threadId)
        localStorage.removeItem(localStorageThreadIdKey)
    }
}

export default openai