import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import workflowActions from "@actions/workflowActions";

const useWorkflowActions = () => {
    const dispatch = useDispatch()
    const { id: moduleId } = useParams()
    const { workflowInstanceDetails, workflow, assignee, loading } = useSelector(state => state.workflowReducer);
    const setWorkflow = payload => dispatch(workflowActions.setWorkflow({ workflow: payload, moduleId }))
    const getWorkflowInstanceDetails = id => {
        if (!workflowInstanceDetails[id]) {
            dispatch(workflowActions.getWorkflowInstanceDetails(id))
        }
    }
    const getWorkflowAssignee = async () => {
        let result = []
        result = await dispatch(workflowActions.getAssignee(moduleId))
        return result
    }
    const getAssignmentSteps = () => {
        return workflow?.elements?.length > 0
            ? workflow.elements
                .filter((dataFilter) => dataFilter.type === "Assignment")
                .map((elem) => {
                    return {
                        activityId: elem.id,
                        activityName: elem?.data?.raw?.title || elem?.data?.label || elem?.type,
                    };
                })
            : [];
    }
    const getActivityList = () => {
        let activityList =
            workflow?.elements?.length > 0
                ? workflow.elements
                    .filter((dataFilter) => dataFilter.type === "Assignment")
                    .map((elem) => {
                        return {
                            activityId: elem.id,
                            activityName: elem?.data?.raw?.title || elem?.data?.label || elem?.type,
                        };
                    })
                : [];
        [
            { activityId: "fs-start", activityName: "Start" },
            { activityId: "fs-complete", activityName: "Completed" },
        ].forEach((res, i) => {
            if (i === 1) {
                activityList.splice(activityList.length, 0, res);
            } else {
                activityList.splice(0, 0, res);
            }
        });
        return activityList
    }
    const getWorkflowTabs = content => {
        const activityList = getActivityList()
        return activityList.map((activity, idx) => {
            return {
                key: activity.activityId,
                title: activity.activityName,
                content: (container) => {
                    return content({ idx: activity.activityId, container })
                },
            };
        })
    }

    return {
        setWorkflow,
        getWorkflowInstanceDetails,
        getWorkflowAssignee,
        getAssignmentSteps,
        getWorkflowTabs,
        getActivityList,
        workflowInstanceDetails,
        workflow,
        assignee,
        loading,
    }
}

export default useWorkflowActions