import React, {useState} from "react";
import {Dropdown, Menu} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ActionButton from "../ActionButton";
import style from "./style.module.scss";

const DropdownButton = props => {
    const {
        menus,
    } = props
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const menu = (
        <Menu>
            {
                menus.map((m, mId) => (
                    <React.Fragment key={mId}>
                        <Menu.Item key={mId}>
                            <span onClick={(e) => {
                                setDropdownVisible(false)
                                m.onClick(e)
                            }}>
                                { m.label }
                            </span>
                        </Menu.Item>
                        {
                            mId < (menus.length - 1)
                                ? <Menu.Divider />
                                : null
                        }
                    </React.Fragment>
                ))
            }
        </Menu>
    );

    return (
        <div
            className={style['dropdown-button']}
        >
            <Dropdown
                overlay={menu}
                trigger={['click']}
                onVisibleChange={setDropdownVisible}
                visible={dropdownVisible}
            >
                <ActionButton>
                    <PlusOutlined/>
                </ActionButton>
            </Dropdown>
        </div>
    )
}

export default DropdownButton