import { useCallback, useRef, useState } from 'react'

const useResolveCalculation = ({ calculating }) => {
    const maxRetry = 3
    const retryDelay = 300
    const retry = useRef(null)
    const retryCount = useRef(null)
    const [resolving, setResolving] = useState(true)

    const resolveCalculation = useCallback(async (callback) => {
        const doCallback = async () => {
            // When the app has finished formula evaluation
            // reset the timeout references
            clearTimeout(retry.current)
            retry.current = null

            // reset the counter
            retryCount.current = 0

            // execute the callback
            await callback()

            // turn off the flag
            setResolving(false)
        }
        const doResolve = async () => {
            try {
                setResolving(true)
                if (!!calculating) {
                    // When the app is evaluating formula calculation
                    // wait until it is finished before executing the callback.
                    retry.current = setTimeout(async () => {
                        if (retryCount.current >= maxRetry) {
                            doCallback()
                        } else {
                            retryCount.current = retryCount.current + 1
                            doResolve()
                        }
                    }, retryDelay)
                } else {
                    doCallback()
                }
            } catch (e) {
                console.error('retry callback failed', e)
            }
        }
        doResolve()
    }, [retryCount, maxRetry, calculating])

    return {
        resolveCalculation,
        resolving,
    }
}

export default useResolveCalculation