import React, { useMemo } from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";
import { isJsonString } from "@utils/json";
import useDataConnectorReader from "../../../utils/hooks/useDataConnectorReader";

const DisplayObjectItem = (props) => {
    const { object, hideFields, currentField, remoteConfig = false } = props
    const { columns } = currentField || {}
    const remoteDetail = useDataConnectorReader({ config: currentField?.config || {}, enabled: remoteConfig })

    const visibleFields = useMemo(() => {
        if (currentField?.config?.selectFieldsByThisProcess?.length > 0) {
            return currentField?.config?.selectFieldsByThisProcess;
        }
        // Visible fields are stored in the columns information
        if (columns?.current?.length > 0) {
            return columns.current
        }
        // Backward compatibility for visible fields that are stored in reserveFieldNames property
        if (currentField?.config?.reserveFieldNames?.length > 0) {
            return currentField?.config?.reserveFieldNames
        }
        // Fallback for visible fields by reading from the real time configurations
        if (remoteDetail?.display_columns?.current?.length > 0) {
            return remoteDetail?.display_columns?.current
        }
        // Default visible fields
        return []
    }, [
        columns?.current?.length,
        currentField?.config?.selectFieldsByThisProcess?.length,
        currentField?.config?.reserveFieldNames?.length,
        remoteDetail?.display_columns?.current?.length,
    ])
    const parsedObject = isJsonString(object) ? JSON.parse(object) : object;
    let content = parsedObject
        ? Object.entries(parsedObject).filter(([, value]) => value)
        : []

    if (Array.isArray(hideFields) && hideFields.length > 0 && visibleFields.length === 0) {
        if (content.length > 0) {
            content = content.filter(f => !hideFields.includes(f[0]))
        }
    }
    if (Array.isArray(visibleFields) && visibleFields.length > 0) {
        if (content.length > 0) {
            content = content.filter(f => visibleFields.includes(f[0]))
        }
    }

    const contentObj = Object.fromEntries(content)

    return content.length
        ? (
            <div className={`${style['item-list']} d-flex flex-wrap`}>
                {
                    visibleFields.map(key => (
                        <div key={key} className="mr-1">
                            <span>{ contentObj?.[key] }</span>
                        </div>
                    ))
                }
            </div>
        )
        : null
}

const DisplayObject = props => {
    const { object, componentClass = '', size = 'md', isTableCell , ...itemProps } = props
    const classNames = {
        sm: {
            padding: 'py-2 px-2'
        },
        md: {
            padding: 'p-1'
        }
    }
    const parsedValue = isJsonString(object) ? JSON.parse(object) : object;
    return (
        typeof parsedValue === "object" ?
            <div className={`${classNames[size]?.padding} flex-grow-1 ${isTableCell?"p-3 rounded-0":""} ${componentClass} valueEl`}>
                {
                    Array.isArray(parsedValue)
                        ? (
                            <>
                                {
                                    parsedValue.map((item, key) => (
                                        <DisplayObjectItem key={key} object={item} {...itemProps} />
                                    ))
                                }
                            </>
                        )
                        : (
                            <DisplayObjectItem object={parsedValue} {...itemProps} />
                        )
                }
            </div>
            :
            <input
                type="text"
                className={`form-control form-control-sm ${isTableCell?"h-100 rounded-0":""}`}
                value={parsedValue ?? ""}
                disabled={true}
            />
    )
}

DisplayObject.propTypes = {
    componentClass: PropTypes.string,
    filterKeys: PropTypes.func,
}

export default DisplayObject