// import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { DeviceUUID } from 'device-uuid';

const govHeaders = async (username) => {
	let hasPlugins = [];
	if (navigator?.plugins?.length > 0) {
		Array.from(navigator.plugins).forEach(v => hasPlugins.push(encodeURIComponent(v.name)));
	}else {
		hasPlugins = false;
	}

	const govDoNotTrack = "Gov-Client-Browser-Do-Not-Track";
	const govPublicIP = "Gov-Client-Public-IP";
	const { innerWidth, innerHeight, screen, location, devicePixelRatio, external, doNotTrack } = window;
	const { availWidth, availHeight, width, height, colorDepth } = screen; 

	const timeISO = new Date().toISOString();
	const port = location.port;
	let headers = {
		[govDoNotTrack]: "false", // false
		"Gov-Client-Browser-JS-User-Agent": navigator.userAgent, 
		"Gov-Client-Browser-Plugins": hasPlugins ? hasPlugins.join(",") : "n/a", 
		"Gov-Client-Device-ID": new DeviceUUID().get(), 
		"Gov-Client-Local-IPs": "127.0.0.1", // Gov-Client-Local-IPs = IPv4, IPv6, local GUID
		"Gov-Client-Local-IPs-Timestamp": timeISO, 
		[govPublicIP]: "127.0.0.1", 
		"Gov-Client-Public-IP-Timestamp": timeISO, 
		"Gov-Client-Public-Port": port.length > 0 ? port : "65535",
		"Gov-Client-Screens": `width=${width}&height=${height}&scaling-factor=${parseInt(devicePixelRatio)}&colour-depth=${colorDepth},width=${availWidth}&height=${availHeight}&scaling-factor=${devicePixelRatio}&colour-depth=${colorDepth}`, 
		"Gov-Client-Timezone": "UTC" + (momentTimeZone(timeISO).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('Z')),
		"Gov-Client-User-IDs": "FinanSysApps=" + username, // Gov-Client-User-IDs = FinansysFlow=user32abc7b3
		"Gov-Client-Window-Size": `width=${innerWidth}&height=${innerHeight}`, 
	};

	if (doNotTrack || navigator.doNotTrack || 'doNotTrack' in navigator || navigator.msDoNotTrack || 'msTrackingProtectionEnabled' in external) {
		// The browser supports Do Not Track!
		if (doNotTrack === "1" || navigator.doNotTrack === "yes" || navigator.doNotTrack === "1" || navigator.msDoNotTrack === "1" || (external.msTrackingProtectionEnabled && external.msTrackingProtectionEnabled())) {
			headers[govDoNotTrack] = "true"; // true
		}
	}
	// else{
	//     console.log("Do Not Track is not supported.");
	//     headers[govDoNotTrack] = false;
	// }

	// Gov-Client-Public-IP = https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript
	// let hasPublicIP;
	// fetch("https://www.cloudflare.com/cdn-cgi/trace").then(r => {
	//     console.log('r: ', r);
	//     return r.text();
	// }).then(res => {
	//     let resObj = {};
	//     if(res){
	//         res.split("\n").forEach(v => {
	//             let kv = v.split("=");
	//             resObj[kv[0]] = kv[1];
	//         });
	//     }
	//     console.log('res: ', res);
	//     console.log('resObj: ', resObj);

	//     if(resObj.ip){
	//         headers.publicIP = resObj.ip;
	//     }
	// }).catch(e => {
	//     console.log('e: ', e);
	// });
	try {
		const getIp = await fetch("https://api.ipify.org");// https://api.ipify.org?format=json | https://ipinfo.io/json
		if (getIp.ok) {
			const resGetIp = await getIp.text();// json
			if (resGetIp) { //  && resGetIp.ip
				headers[govPublicIP] = resGetIp;
			}
		}
	} catch (err) {
		console.log({ err })
	}
	// else {
	// 	console.log("An error has occured: ", getIp.status);// throw new Error("An error has occured: " + getIp.status);
	// }

	// MUST have Permissions API
	// const getIpLocal = await getLocalIP();
	// if (getIpLocal && !getIpLocal.error) {
	//   headers["Gov-Client-Local-IPs"] = getIpLocal;
	// }

	return Object.entries(headers).map(h => ({
		key: h[0],
		value: h[1],
	}));
}

export default govHeaders;
