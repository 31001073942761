import { useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import useApiRequest from "../../../utils/hooks/useApiRequest"
import { fetchDataTable, getDataTableQueryKey } from "../../../utils/states/NewDataTable.state"

const NewDataTablePrefetcher = ({ path, params }) => {
    const { request } = useApiRequest()
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.invalidateQueries(getDataTableQueryKey(path, params))
        queryClient.prefetchQuery(getDataTableQueryKey(path, params), () => fetchDataTable(path, params, request))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}

export default NewDataTablePrefetcher