import React from "react";
import {CloseOutlined} from "@ant-design/icons";
import ActionButton from "../ActionButton";

const RemoveButtonIcon = props => {
    const {
        handleOnClick,
        ...buttonProps
    } = props

    return (
        <ActionButton onClick={handleOnClick} {...buttonProps}>
            <CloseOutlined/>
        </ActionButton>
    )
}

export default RemoveButtonIcon