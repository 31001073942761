import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const ModalScheduleAction = props => {
    const { show, onConfirm, onClose, scheduleActionData = {}, loading} = props

    return (
        <Modal centered backdrop="static" size="sm" show={show}>
            <Modal.Header className="py-4">
                <Modal.Title>{scheduleActionData.text} schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you want to {scheduleActionData.text} {scheduleActionData?.item?.schedule_name}?
            </Modal.Body>
            <Modal.Footer className="py-2">
                <Button onClick={onClose} variant="outline-secondary" disabled={loading}>Cancel</Button>
                <Button onClick={onConfirm} disabled={loading}>
                    {
                        loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                    }
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalScheduleAction.propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};

export default ModalScheduleAction;
