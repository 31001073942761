import {CloseOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React from "react";

const RemoveButton = props => {
    const {
        className = '',
        handleOnClick,
        ...buttonProps
    } = props
    return (
        <Button
            type="link"
            className={`text-muted ${className}`}
            icon={<CloseOutlined/>}
            onClick={handleOnClick}
            {...buttonProps}
        />
    )
}

export default RemoveButton