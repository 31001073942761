import {ExtractText as ExtractTextFormula} from "./extract-text";
import {NumberValue as NumberValueFormula} from "./number-value";
import {Rand as RandFormula} from "./rand";
import {RandBetween as RandBetweenFormula} from "./rand-between";
import {GetSummary as GetSummaryFormula} from "./get-summary";
import {GetSummaryApp as GetSummaryAppFormula} from "./get-summary-app";
import {DuplicateCheck as DuplicateCheckFormula} from "./duplicate-check";
import {Now as NowFormula} from "./now";
import {Today as TodayFormula} from "./today";

export const ExtractText = ExtractTextFormula
export const NumberValue = NumberValueFormula
export const Rand = RandFormula
export const RandBetween = RandBetweenFormula
export const GetSummary = GetSummaryFormula
export const GetSummaryApp = GetSummaryAppFormula
export const DuplicateCheck = DuplicateCheckFormula
export const Now = NowFormula
export const Today = TodayFormula
export const translations = {
    enGB: {
        'EXTRACTTEXT': 'EXTRACTTEXT',
        'NUMBERVALUE': 'NUMBERVALUE',
        'RAND': 'RAND',
        'RANDBETWEEN': 'RANDBETWEEN',
        'GETSUMMARY': 'GETSUMMARY',
        'GETSUMMARYAPP': 'GETSUMMARYAPP',
        'DUPLICATECHECK': 'DUPLICATECHECK',
        'NOW': 'NOW',
        'TODAY': 'TODAY',
    }
}
export const builtInFormulas = [
    "ABS",
    "ACOS",
    "ACOSH",
    "ACOT",
    "ACOTH",
    "AND",
    "ARABIC",
    "ARRAYFORMULA",
    "ARRAY_CONSTRAIN",
    "ASIN",
    "ASINH",
    "ATAN",
    "ATAN2",
    "ATANH",
    "AVEDEV",
    "AVERAGE",
    "AVERAGEA",
    "AVERAGEIF",
    "BASE",
    "BESSELI",
    "BESSELJ",
    "BESSELK",
    "BESSELY",
    "BETA.DIST",
    "BETA.INV",
    "BETADIST",
    "BETAINV",
    "BIN2DEC",
    "BIN2HEX",
    "BIN2OCT",
    "BINOM.DIST",
    "BINOM.INV",
    "BINOMDIST",
    "BITAND",
    "BITLSHIFT",
    "BITOR",
    "BITRSHIFT",
    "BITXOR",
    "CEILING",
    "CEILING.MATH",
    "CEILING.PRECISE",
    "CHAR",
    "CHIDIST",
    "CHIDISTRT",
    "CHIINV",
    "CHIINVRT",
    "CHISQ.DIST",
    "CHISQ.DIST.RT",
    "CHISQ.INV",
    "CHISQ.INV.RT",
    "CHISQ.TEST",
    "CHITEST",
    "CHOOSE",
    "CLEAN",
    "CODE",
    "COLUMN",
    "COLUMNS",
    "COMBIN",
    "COMBINA",
    "COMPLEX",
    "CONCATENATE",
    "CONFIDENCE",
    "CONFIDENCE.NORM",
    "CONFIDENCE.T",
    "CORREL",
    "COS",
    "COSH",
    "COT",
    "COTH",
    "COUNT",
    "COUNTA",
    "COUNTBLANK",
    "COUNTIF",
    "COUNTIFS",
    "COUNTUNIQUE",
    "COVAR",
    "COVARIANCE.P",
    "COVARIANCE.S",
    "COVARIANCEP",
    "COVARIANCES",
    "CRITBINOM",
    "CSC",
    "CSCH",
    "CUMIPMT",
    "CUMPRINC",
    "DATE",
    "DATEDIF",
    "DATEVALUE",
    "DAY",
    "DAYS",
    "DAYS360",
    "DB",
    "DDB",
    "DEC2BIN",
    "DEC2HEX",
    "DEC2OCT",
    "DECIMAL",
    "DEGREES",
    "DELTA",
    "DEVSQ",
    "DOLLARDE",
    "DOLLARFR",
    "EDATE",
    "EFFECT",
    "EOMONTH",
    "ERF",
    "ERFC",
    "EVEN",
    "EXACT",
    "EXP",
    "EXPON.DIST",
    "EXPONDIST",
    "F.DIST",
    "F.DIST.RT",
    "F.INV",
    "F.INV.RT",
    "F.TEST",
    "FACT",
    "FACTDOUBLE",
    "FALSE",
    "FDIST",
    "FDISTRT",
    "FILTER",
    "FIND",
    "FINV",
    "FINVRT",
    "FISHER",
    "FISHERINV",
    "FLOOR",
    "FLOOR.MATH",
    "FLOOR.PRECISE",
    "FORMULATEXT",
    "FTEST",
    "FV",
    "FVSCHEDULE",
    "GAMMA",
    "GAMMA.DIST",
    "GAMMA.INV",
    "GAMMADIST",
    "GAMMAINV",
    "GAMMALN",
    "GAMMALN.PRECISE",
    "GAUSS",
    "GCD",
    "GEOMEAN",
    "HARMEAN",
    "HEX2BIN",
    "HEX2DEC",
    "HEX2OCT",
    "HF.ADD",
    "HF.CONCAT",
    "HF.DIVIDE",
    "HF.EQ",
    "HF.GT",
    "HF.GTE",
    "HF.LT",
    "HF.LTE",
    "HF.MINUS",
    "HF.MULTIPLY",
    "HF.NE",
    "HF.POW",
    "HF.UMINUS",
    "HF.UNARY_PERCENT",
    "HF.UPLUS",
    "HLOOKUP",
    "HOUR",
    "HYPGEOM.DIST",
    "HYPGEOMDIST",
    "IF",
    "IFERROR",
    "IFNA",
    "IMABS",
    "IMAGINARY",
    "IMARGUMENT",
    "IMCONJUGATE",
    "IMCOS",
    "IMCOSH",
    "IMCOT",
    "IMCSC",
    "IMCSCH",
    "IMDIV",
    "IMEXP",
    "IMLN",
    "IMLOG10",
    "IMLOG2",
    "IMPOWER",
    "IMPRODUCT",
    "IMREAL",
    "IMSEC",
    "IMSECH",
    "IMSIN",
    "IMSINH",
    "IMSQRT",
    "IMSUB",
    "IMSUM",
    "IMTAN",
    "INDEX",
    "INT",
    "INTERVAL",
    "IPMT",
    "ISBINARY",
    "ISBLANK",
    "ISERR",
    "ISERROR",
    "ISEVEN",
    "ISFORMULA",
    "ISLOGICAL",
    "ISNA",
    "ISNONTEXT",
    "ISNUMBER",
    "ISO.CEILING",
    "ISODD",
    "ISOWEEKNUM",
    "ISPMT",
    "ISREF",
    "ISTEXT",
    "LARGE",
    "LCM",
    "LEFT",
    "LEN",
    "LN",
    "LOG",
    "LOG10",
    "LOGINV",
    "LOGNORM.DIST",
    "LOGNORM.INV",
    "LOGNORMDIST",
    "LOGNORMINV",
    "LOWER",
    "MATCH",
    "MAX",
    "MAXA",
    "MAXPOOL",
    "MEDIAN",
    "MEDIANPOOL",
    "MID",
    "MIN",
    "MINA",
    "MINUTE",
    "MIRR",
    "MMULT",
    "MOD",
    "MONTH",
    "MROUND",
    "MULTINOMIAL",
    "NA",
    "NEGBINOM.DIST",
    "NEGBINOMDIST",
    "NETWORKDAYS",
    "NETWORKDAYS.INTL",
    "NOMINAL",
    "NORM.DIST",
    "NORM.INV",
    "NORM.S.DIST",
    "NORM.S.INV",
    "NORMDIST",
    "NORMDIST",
    "NORMINV",
    "NORMSINV",
    "NOT",
    // "NOW",
    "NPER",
    "NPV",
    "OCT2BIN",
    "OCT2DEC",
    "OCT2HEX",
    "ODD",
    "OFFSET",
    "OR",
    "PDURATION",
    "PEARSON",
    "PHI",
    "PI",
    "PMT",
    "POISSON",
    "POISSON.DIST",
    "POISSONDIST",
    "POWER",
    "PPMT",
    "PRODUCT",
    "PROPER",
    "PV",
    "QUOTIENT",
    "RADIANS",
    // "RAND",
    // "RANDBETWEEN",
    "RATE",
    "REPLACE",
    "REPT",
    "RIGHT",
    "ROMAN",
    "ROUND",
    "ROUNDDOWN",
    "ROUNDUP",
    "ROW",
    "ROWS",
    "RRI",
    "RSQ",
    "SEARCH",
    "SEC",
    "SECH",
    "SECOND",
    "SERIESSUM",
    "SHEET",
    "SHEETS",
    "SIGN",
    "SIN",
    "SINH",
    "SKEW",
    "SKEW.P",
    "SKEWP",
    "SLN",
    "SLOPE",
    "SMALL",
    "SPLIT",
    "SQRT",
    "SQRTPI",
    "STANDARDIZE",
    "STDEV",
    "STDEV.P",
    "STDEV.S",
    "STDEVA",
    "STDEVP",
    "STDEVPA",
    "STDEVS",
    "STEYX",
    "SUBSTITUTE",
    "SUBTOTAL",
    "SUM",
    "SUMIF",
    "SUMIFS",
    "SUMPRODUCT",
    "SUMSQ",
    "SUMX2MY2",
    "SUMX2PY2",
    "SUMXMY2",
    "SWITCH",
    "SYD",
    "T",
    "T.DIST",
    "T.DIST.2T",
    "T.DIST.RT",
    "T.INV",
    "T.INV.2T",
    "T.TEST",
    "TAN",
    "TANH",
    "TBILLEQ",
    "TBILLPRICE",
    "TBILLYIELD",
    "TDIST",
    "TDIST2T",
    "TDISTRT",
    "TEXT",
    "TIME",
    "TIMEVALUE",
    "TINV",
    "TINV2T",
    // "TODAY",
    "TRANSPOSE",
    "TRIM",
    "TRUE",
    "TRUNC",
    "TTEST",
    "UNICHAR",
    "UNICODE",
    "UPPER",
    "VAR",
    "VAR.P",
    "VAR.S",
    "VARA",
    "VARP",
    "VARPA",
    "VARS",
    "VLOOKUP",
    "WEEKDAY",
    "WEEKNUM",
    "WEIBULL",
    "WEIBULL.DIST",
    "WEIBULLDIST",
    "WORKDAY",
    "WORKDAY.INTL",
    "XNPV",
    "XOR",
    "YEAR",
    "YEARFRAC",
    "Z.TEST",
    "ZTEST"
]
export const customFormulas = [
    "EXTRACTTEXT",
    "NUMBERVALUE",
    "RAND",
    "RANDBETWEEN",
    "JSON",
    "PARSE",
    "DUPLICATECHECK",
    "NOW",
    "TODAY"
]
export const formulaList = [
    ...builtInFormulas,
    ...customFormulas,
]
