import React, { useEffect, useMemo, useState } from "react"
import { Input, Select } from "antd";
import { inputValueValidation } from "@utils/validation";

const { Search } = Input;
const SearchTable = ({ onSearch, searchQuery, queryColumn, disabled, placeholder, columns, withColumnFilter }) => {
    const [inputValue, setInputValue] = useState(searchQuery);
    const [selectedColumn, setSelectedColumn] = useState(queryColumn);
    const [error, setError] = useState(false);

    const isColumnSearchable = (column) => { 
        const {editorType, void:voidColumn, hidden} = column || {};
        const unsupportedTypes =  [
            "barcode", "button", "checklist", "date", "number", "image", "file", 
            "pull-data", "rating", "slider", "switch", "signature", "time"
        ];
        return !voidColumn && hidden === false && (!editorType || 
            unsupportedTypes.every(t => !editorType.includes(t)) || 
            editorType.includes("sequence") || editorType.includes("scanner"));
    }
    const columnOptions = useMemo(() => {
        return columns?.reduce((res, col) => {
            if (isColumnSearchable(col)) {
                res.push({
                    label: col.text, value: col.dataField
                })
            }
            return res;
        }, [])
    },[columns]);

    const handleOnSearch = (query) => {
        if(!error){
            if (query.length === 0 || query.length > 0) {
                onSearch(query, selectedColumn, columnOptions);
            }
        }
    }

    useEffect(() => {
      const error = inputValueValidation(inputValue)
      setError(error)
    }, [inputValue])
    
    return (
        <div className="col-md-6">
            <div className="row">
                {withColumnFilter && <Select
                    className="col-md-4"
                    showSearch
                    allowClear
                    value={selectedColumn}
                    onChange={(val)=> setSelectedColumn(val)}
                    options={columnOptions}
                    placeholder="All Columns"
                />}
                <div className={`col-md-${withColumnFilter?"8":"12"}`}>
                    <Search
                        placeholder={placeholder || "Search"}
                        allowClear
                        value={inputValue}
                        onSearch={(value) => handleOnSearch(value)}
                        className={error ? " is-invalid" : " is-valid"}
                        disabled={disabled}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={e => { e.key === 'Enter' && handleOnSearch(inputValue) }}
                    />
                    {(inputValue && error) &&
                        <div className="invalid-feedback">{error}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchTable