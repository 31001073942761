import { initRequest } from "@utils/actionMethods";

const workflowActions = {
    setWorkflow({ workflow }) {
        return dispatch => dispatch({
            type: 'SET_WORKFLOW',
            payload: workflow,
        })
    },
    setActivities(payload) {
        return dispatch => dispatch({
            type: 'SET_ACTIVITIES',
            payload,
        })
    },
    setConnections(payload) {
        return dispatch => dispatch({
            type: 'SET_CONNECTIONS',
            payload,
        })
    },
    setEditWorkflow(payload) {
        return dispatch => dispatch({
            type: 'SET_EDIT_WORKFLOW',
            payload,
        })
    },
    getShareSettings(moduleId) {
        return async dispatch => {
            const request = initRequest(dispatch)
            return await request(
                'getShareSettings',
                { moduleId },
            )
        }
    },
    getAssignee(moduleId) {
        return async dispatch => {
            const request = initRequest(dispatch)
            let result = []
            await request(
                'getAssignee',
                { moduleId },
                ({ data }) => {
                    const list = data?.items || []
                    const assignee = list.map(a => ({
                        value: a?.id,
                        label: a?.name,
                        type: a?.type,
                    }))
                    dispatch({
                        type: 'SET_ASSIGNEE',
                        payload: assignee,
                    })
                    result = assignee
                }
            )
            return result
        }
    },
    updateShareSettings(payload) {
        return () => {
            // TODO: Integrate share settings POST API
            console.log(payload)
        }
    },
    getPermissibleFields(moduleId) {
        return async dispatch => {
            const request = initRequest(dispatch)
            return await request(
                'getPermissibleFields',
                { moduleId },
                (data = []) => {
                    const payload = data.map(option => ({
                        value: option.Id,
                        label: option.Name,
                    }))
                    dispatch({
                        type: 'SET_PERMISSIBLE_FIELDS',
                        payload,
                    })
                },
            )
        }
    },
    getWorkflowInstanceDetails(id) {
        return async dispatch => {
            fetch('/DUMMY/json/workflow/instance-details.json').then(response => response.json()).then(response => {
                dispatch({
                    type: 'SET_WORKFLOW_INSTANCE_DETAILS',
                    payload: {
                        workflowId: id,
                        details: response,
                    },
                })
            })
        }
    },
}

export default workflowActions