import { FunctionPlugin } from "hyperformula";

export class Rand extends FunctionPlugin {
    rand() {
        const localRandValue = localStorage.getItem('randValue')
        if (!localRandValue) {
            const randValue = Math.random()
            localStorage.setItem('randValue', JSON.stringify(randValue))
            return randValue
        }
        return localRandValue
    }
}

Rand.implementedFunctions = {
    'RAND': {
        method: 'rand',
    }
}