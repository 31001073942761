import { useState } from "react";// React, 


const useRowSelection = ({ keyField }) => {
    const [selectedRow, setSelectedRow] = useState([])
    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRow([
                ...selectedRow,
                row,
            ])
        } else {
            setSelectedRow([
                ...selectedRow.filter(x => x[keyField] !== row[keyField])
            ])
        }
    }
    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setSelectedRow(rows)
        } else {
            onClearSelected()
        }
    }

    const onClearSelected = () => {
        setSelectedRow([])
    }
    
    const selected = selectedRow.map(x => x[keyField])

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selected: selectedRow.map(x => x[keyField]),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
    };

    return {
        selectRow,
        selected,
        selectedRow,
        onClearSelected
    }
}

export default useRowSelection