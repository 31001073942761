import React from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const formatDuplicateAction = onActionItem => (cell, row) => {
    return (
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Duplicate Connector {row.connector_name}</Tooltip>}> 
            <Button 
                size="xs" 
                variant="flat"
                className="text-primary far fa-clone" 
                onClick={() => onActionItem(row)}
            />
        </OverlayTrigger>
    )
}

export default formatDuplicateAction;