import React from "react";
import Form from "react-bootstrap/Form";
import style from "./style.module.scss";
import CodeEditorForm from "./CodeEditorForm";

const CustomFormControl = controlProps => {
    const {
        field,
        form,
        className,
        fieldClassName = '',
        controlAs = 'input',
        renderControl,
        placeholder,
        minHeight,
        ...props
    } = controlProps
    const { touched, errors } = form
    const fieldName = field.name.split('.')[0]
    const errorArrayItem = field.name.split('.').reduce((o, i) => o && o[i], errors)
    const touchedArrayItem = field.name.split('.').reduce((o, i) => o && o[i], touched)
    const isError = (touched[field.name] && errors[field.name]) || (touchedArrayItem && errorArrayItem)
    const errorLabel = errors[field.name] || errorArrayItem
    const syntax = form?.values[fieldName]?.syntax?.value
    const classCode = controlAs !== 'code' ? `${className} ${style['form-control__container']}` : 'w-75'
    const renderForm = () => {
        if(renderControl){
            return renderControl({
                value: field.value,
                onChange: v => form.setFieldValue(field.name, v),
                isError,
            })
        }
        if (controlAs === 'code') {
            const reMapLanguange = (syntax) => {
                switch (syntax) {
                    case 'xml':
                        return 'xml'
                    case 'JavaScript':
                        return 'js'
                    case 'Json':
                        return 'json'
                    case 'regex':
                        return 'regex' 
                    case 'Literal':
                    case 'Liquid':
                    default:
                        return 'plaintext'
                }
            }
            return <CodeEditorForm 
                        value={field?.value} 
                        onChange={(v) => form.setFieldValue(field.name, v)}
                        language={reMapLanguange(syntax)}
                        placeholder={placeholder}
                        minHeight={minHeight}
                    />
        }
        return (
            <Form.Control
                {...field}
                {...props}
                as={controlAs}
                isInvalid={isError}
                className={fieldClassName}
            />
        )
    }
    return (
        <div className={classCode}>
            {renderForm()}
            {
                isError
                    ? <Form.Control.Feedback type="invalid" className="d-block mb-1">{errorLabel}</Form.Control.Feedback>
                    : null
            }
        </div>
    )
};

export default CustomFormControl