import React from "react";
import {Button} from "react-bootstrap";

const CloseButton = props => {
    const { className, ariaLabel, ...buttonProps } = props
    return (
        <Button
            size="sm"
            variant="flat"
            className={`tip tipBR fa-lg fal fa-times ired ${className}`}
            aria-label={ariaLabel ? ariaLabel : "Close"}
            {...buttonProps}
        />
    )
}

export default CloseButton