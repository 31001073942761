const formEditorEndpoints = {
    createProcessFromScratch(data) {
        return {
            url: '/api/form/Editor/CreateProcessFromScratch',
            method: 'POST',
            data
            // data: {
            //     name: data.formName,
            //     categoryId: data.formCategories,
            //     description: data.description,
            // },
        }
    },
    saveForm({ id, data }) {
        return {
            url: '/api/form/Editor/SaveForm',
            method: 'POST',
            params: {
                id,
            },
            data,
        }
    },
    applyConfigAllUser({ moduleId }) {
        return {
            url: '/api/form/Editor/applyconfigalluser',
            method: 'POST',
            params: {
                moduleId,
            }
        }
    },
    setUserConfig({ moduleId, tabConfig, data }) {
        return {
            url: '/api/form/Editor/setuserconfig',
            method: 'PUT',
            params: {
                moduleId,
                tabConfig,
            },
            data
        }
    },
    deleteForm(moduleId) {
        return {
            url: '/api/form/Editor/DeleteForm',
            method: 'POST',
            params: {
                moduleId,
            },
        }
    },
    publish({ id }) {
        return {
            url: '/api/form/Editor/publish',
            method: 'POST',
            params: {
                id,
            },
        }
    },
    saveRecordTemplate({ moduleId, templateId, templateName, data }) {
        return {
            url: '/api/form/Editor/recordtemplate',
            method: 'POST',
            params: {
                moduleId,
                templateId,
                templateName,
            },
            data,
        }
    },
    getSavedRecordTemplate(id) {
        return {
            url: `/api/form/Editor/recordtemplate/${id}`,
            method: 'GET',
        }
    },
    loadRecordTemplate({ moduleId, templateId }) {
        return {
            url: `/api/form/Editor/recordtemplate/${moduleId}/${templateId}`,
            method: 'GET',
            params: {
                moduleId,
                templateId,
            },
        }
    },
    renameRecordTemplate({ templateId, templateName }) {
        return {
            url: '/api/form/Editor/recordtemplate/rename',
            method: 'POST',
            params: {
                templateId,
                name: templateName,
            },
        }
    },
    deleteRecordTemplate({ moduleId, templateId }) {
        return {
            url: `/api/form/Editor/recordtemplate/${moduleId}/${templateId}`,
            method: 'DELETE',
            params: {
                moduleId,
                templateId,
            },
        }
    },
    setOrderIndexRecordTemplate({ templateId, index }) {
        return {
            url: "/api/form/Editor/recordtemplate/orderindex",
            method: 'POST',
            params: {
                templateId,
                index,
            },
        }
    },
    getPermissibleList(params) {
        return {
            url: '/api/form/ListKeyValue/getList',
            method: 'GET',
            params,
        }
    },
    addPermissibleList(data) {
        return {
            url: '/api/form/ListKeyValue/save',
            method: 'POST',
            data,
        }
    },
    getPermissibleListCategoryOptions(params) {
        return {
            url: '/api/form/ListKeyValue/category/list',
            method: 'GET',
            params,
        }
    },
    addPermissibleListCategoryOption(name) {
        return {
            url: '/api/form/ListKeyValue/category/add',
            method: 'POST',
            params: {
                name,
            },
        };
    },
    getPermissibleListItems(listId) {
        return {
            url: '/api/form/ListKeyValue/detail',
            method: 'GET',
            params: {
                id: listId,
            },
        }
    },
    getFormList({ searchTerm }) {
        return {
            url: '/api/form/Editor/ListForms',
            method: 'GET',
            params: {
                searchTerm
            },
        }
    },
    getFormListTable({ data = {} }) {
        return {
            url: '/api/form/editor/applications/datatables',
            method: 'POST',
            data,
        }
    },
    getStiReportListTable({ data = {} }) {
        return {
            url: '/api/form/report/datatables',
            method: 'POST',
            data,
        }
    },
    getEditorModules({ data = {} }) {
        return {
            url: '/api/form/editor/modules/datatables',
            method: 'POST',
            data,
        }
    },
    getFormDetail({ id }) {
        return {
            url: '/api/form/Editor/GetForm',
            method: 'GET',
            params: {
                id,
            },
        }
    },
    getEmailTemplateFields({moduleId}) {
        return {
            url: '/api/form/Editor/getEmailTemplateFields',
            method: 'GET',
            params: {
                moduleId,
            },
        }
    },
    updateEmailTemplate({data}) {
        return {
            url: '/api/form/Editor/updateEmailTemplate',
            method: 'POST',
            data
        }
    },
    deleteFileUrl() {
        return '/api/form/AWSS3/RemoveFileByKey';
    },
    getFileUrl() {
        return '/api/form/AWSS3/GetFile';
    },
    uploadUrl() {
        return '/api/form/AWSS3/Upload';
    },
    uploadBase64Url() {
        return '/api/form/AWSS3/UploadBase64';
    },
    uploadEntry({ data }) {
        return {
            url: '/api/form/AWSS3/Upload',
            method: 'POST',
            data
        }
    },
    moduleLookup(id) {
        return {
            url: '/api/form/Editor/ModuleLookup',
            method: 'GET',
            params: {
                id,
            },
        }
    },
    columnLookup(requestParams) {
        const params = typeof requestParams === 'object'
            ? requestParams
            : { id: requestParams }
        return {
            url: '/api/form/Editor/ColumnLookup',
            method: 'GET',
            params,
        }
    },
    remoteLookup({ data }) {
        return {
            url: '/api/form/Editor/RemoteUrl',
            method: 'POST',
            data,
        }
    },
    lookup({ data }) {
        return {
            url: '/api/EntryModule/RecordModuleLookup',
            method: 'POST',
            data,
        }
    },
    recordModuleLookup2({ params }) {
        return {
            url: '/api/EntryModule/recordModuleLookup2',
            method: 'GET',
            params,
        }
    },
    aggregateLookup({ data }) {
        return {
            url: '/api/EntryModule/AggregateLookup',
            method: 'POST',
            data,
        }
    },
    userLookup({ data }) {
        return {
            url: '/api/form/Editor/UserLookup',
            method: 'POST',
            data,
        }
    },
    userLookup2({ params }) {
        return {
            url: '/api/form/Editor/UserLookup2',
            method: 'GET',
            params,
        }
    },
    reservedFieldNames() {
        return {
            url: '/api/form/Editor/ReservedFieldNames',
            method: 'GET'
        }
    },
    reservedModuleNames() {
        return {
            url: '/api/form/Editor/ReservedModuleNames',
            method: 'GET'
        }
    },
    getMostUsedList() {
        return {
            url: '/api/form/Editor/ListMostUsed',
        }
    },
    getModuleCategories() {
        return {
            url: '/api/sys/permissions/menu/sidebar',
        }
    },
    copyModule({ params }) {
        return {
            url: '/api/form/Editor/CopyFrom',
            method: 'POST',
            params,
        }
    },
    updateModule({ params }) {
        return {
            url: '/api/form/Editor/UpdateModule',
            method: 'POST',
            params,
        }
    },
    getFavouriteList() {
        return {
            url: '/api/form/Editor/Favourite/list',
        }
    },
    addRemoveFavouriteModule({moduleId, isAdd}) {
        return {
            url: `/api/form/Editor/favourite/${isAdd ? "add" : "remove"}`,
            method: 'POST',
            params: {
                moduleId,
            }
        }
    },
    getListApplications() {
        return {
            url: '/api/form/Editor/ListApplications',
        }
    },
    setOrderListApplications({ data }) {
        return {
            url: '/api/sys/user/preference/sortupdate',
            method: 'PATCH',
            data
        }
    },
    setModuleReassignment({moduleId, isReassignmentDisabled}) {
        return {
            url: '/api/form/Editor/setmodulereassignment',
            method: 'POST',            
            params: {
                moduleId,
                isReassignmentDisabled
            },
        }
    },
    getModuleConfig(id) {
        return {
            url: '/api/form/Editor/getmoduleconfig?moduleId='+id,
            method: 'GET'
        }
    },
    dataEntryLookup(params){
        return {
            url: '/api/form/editor/dataentrylookup',
            method: 'GET',
            params,
        }
    }
}

export default formEditorEndpoints