import { FunctionPlugin } from "hyperformula";
import moment from "moment-timezone";

export class Now extends FunctionPlugin {
    now(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('NOW'), (...args) => {
            const [ value, userTimezone, date_format, recordId, field, shouldSaveTimezone ] = args
            const localNowValue = localStorage.getItem(`nowValue_${recordId}_${field}`)
            if (!localNowValue) {
                let nowValue = moment(value).utc(true).tz(userTimezone)
                if(!nowValue.isValid()){
                    nowValue = moment().utc().tz(userTimezone)
                }
                if(shouldSaveTimezone === 'true'){
                    nowValue = nowValue.toISOString().replace(/.\d+Z$/g, "")
                } else {
                    nowValue =  nowValue.format(date_format)
                }
                localStorage.setItem(`nowValue_${recordId}_${field}`, nowValue)
                return nowValue
            }
            return localNowValue
        })
    }
}

Now.implementedFunctions = {
    'NOW': {
        method: 'now',
        parameters: [
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
        ],
    },
}