import React, {useState, useEffect} from "react";
import QueryBuilder, {Rule} from "react-querybuilder";
import {
    AntDActionElement,
    AntDNotToggle,
} from "@react-querybuilder/antd";
import RadioGroup from "./partials/RadioGroup";
import DropdownButton from "./partials/DropdownButton";
import RemoveButton from "./partials/RemoveButton";
import RemoveButtonIcon from "./partials/RemoveButtonIcon";
import RuleContainer from "./partials/RuleContainer";
import {
    RenderFieldSelector,
    RenderVisibilityOperator,
    RenderValueEditor,
    RenderOperator,
    RenderValidationValueEditor,
    RenderFilterFieldSelector,
    RenderFilterOperator,
    RenderFilterValueEditor,
    RenderTableFilterValueEditor,
    RenderSecurityValueEditor,
    RenderRequestValueEditor,
    RenderTableFilterOperator,
} from "./controls";
import "./querybuilder.scss";
import { inputValueValidation } from "@utils/validation";

const generateId = () => {
    return `r-${Math.random().toString()}`
}

const generateGroup = (isRoot) => {
    return {
        id: isRoot ? 'root' : generateId(),
        combinator: 'or',
        not: false,
        rules: [],
    }
}

const customAddRuleAction = ({ mode }) => props => {
    const menus = [{
        label: 'Add rule',
        onClick: props.handleOnClick,
    },
    ((['tablefilter','visibility'].includes(mode) && props.level < 1)) && {
        label: 'Add group',
        onClick: () => {
            props.context.setQuery({
                ...props.context.query,
                rules: [
                    ...props.context.query.rules,
                    generateGroup(),
                ]
            })
        }
    }].filter(menu => !!menu)

    return (
        <div className="d-inline-block ml-2">
            <DropdownButton menus={menus} />
        </div>
    )
}

const CustomCombinator = props => {
    return (
        <RadioGroup {...props} />
    )
}

const CustomRule = ruleProps => {
    const { fieldData, handleOnChange, ...props} = ruleProps
    return (
        <RuleContainer>
            <Rule {...props} />
        </RuleContainer>
    )
}

const customControlList = {
    visibility: {
        fieldSelector: RenderFieldSelector,
        operatorSelector: RenderVisibilityOperator,
        valueEditor: RenderValueEditor,
    },
    validation: {
        fieldSelector: () => null,
        operatorSelector: RenderOperator,
        valueEditor: RenderValidationValueEditor,
    },
    filter: {
        fieldSelector: RenderFilterFieldSelector,
        operatorSelector: RenderFilterOperator,
        valueEditor: RenderFilterValueEditor,
    },
    security: {
        fieldSelector: () => null,
        operatorSelector: () => null,
        valueEditor: RenderSecurityValueEditor,
    },
    request: {
        fieldSelector: () => null,
        operatorSelector: () => null,
        valueEditor: RenderRequestValueEditor,
    },
    tablefilter: {
        fieldSelector: RenderFilterFieldSelector,
        operatorSelector: RenderTableFilterOperator,
        valueEditor: RenderTableFilterValueEditor
    },
}

const QueryBuilderComponent = props => {
    const {
        fields,
        onChange,
        initialValue = null,
        mode = 'visibility',
        config,
        ...queryBuilderProps
    } = props
    const [query, setQuery] = useState(generateGroup(true));
    const onChangeQuery = q => {
        setQuery(q)
        onChange(q, fields)
    }
    useEffect(() => {
        if (initialValue && (query?.rules || []).length <= 0) {
            onChangeQuery(initialValue)
        }
        // eslint-disable-next-line
    }, [initialValue])

    const validator = (q) => {
        let { value } = q
        if(Array.isArray(value)){
            value = value[1]
        }
        const valueValidation = inputValueValidation(value)
        return {
            valid: !valueValidation,
            reasons: [valueValidation],
        }
    }
    
    return (
        <QueryBuilder
            fields={(fields || []).map((field)=> {return {...field, validator}})}
            query={query}
            onQueryChange={onChangeQuery}
            controlElements={{
                cloneGroupAction: AntDActionElement,
                cloneRuleAction: AntDActionElement,
                notToggle: AntDNotToggle,
                removeGroupAction: RemoveButtonIcon,
                removeRuleAction: RemoveButton,
                combinatorSelector: CustomCombinator,
                addRuleAction: customAddRuleAction({ mode }),
                rule: CustomRule,
                fieldSelector: customControlList[mode]?.fieldSelector,
                operatorSelector: customControlList[mode]?.operatorSelector,
                valueEditor: customControlList[mode]?.valueEditor,
                addGroupAction: () => null,
                dragHandle: React.forwardRef((props, ref) => (
                    <span className="sr-only" ref={ref}/>
                )),
            }}
            controlClassnames={{
                header: 'dotted-container',
                ruleGroup: 'dotted-content',
            }}
            addRuleToNewGroups={false}
            inlineCombinators={false}
            enableDragAndDrop={false}
            context={{
                query,
                setQuery,
                fields,
                config,
            }}
            {...queryBuilderProps}
        />
    )
}

export default QueryBuilderComponent