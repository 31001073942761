import React from "react";
import Button from "react-bootstrap/Button";

const formatScheduleAction = onScheduleAction => (cell, row) => {
    const status = (row.schedule_status ?? "active").toLowerCase()
    return (
        <div className="d-flex flex-column">
        {
            status === 'active' && <Button 
                size="xs" 
                className="m-1"
                onClick={() => onScheduleAction(row, 2)}
            >
                Run Now
            </Button>
        }
        {
            status === 'suspended' && <Button 
                size="xs" 
                className="m-1"
                variant="success"
                onClick={() => onScheduleAction(row, 0)}
            >
                Activate
            </Button>
        }
        {
            status === 'active' && <Button 
                size="xs" 
                variant="danger"
                className="m-1"
                onClick={() => onScheduleAction(row, 1)}
            >
                Suspend
            </Button>
        }
        </div>
    )
}

export default formatScheduleAction;