import React, { useRef } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useDrop, useDrag } from "react-dnd";
import styles from "./style.module.scss";

const DraggableItem = ({ index, label, moveRow, colItem }) => {
  const ref = useRef();
  const type = "DraggableItem";
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? 'dropOverDownward' : 'dropOverUpward'
      };
    },
    drop: (item) => {
      moveRow(item.index, index, colItem);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index, type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  preview(drop(ref));

  return (
    <div
      key={label}
      ref={ref}
      className={`${styles.itemWrapper} ${isOver ? dropClassName : ""}`}
    >
      <span className={styles.label}>{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </div>
  );
};

export default DraggableItem;