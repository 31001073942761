import {Radio} from "antd";
import React from "react";
import style from "./style.module.scss";

const RadioGroup = props => {
    const {
        handleOnChange,
        options = [],
        value,
    } = props
    return (
        <div
            className={style['radio-group']}
        >
            <Radio.Group
                onChange={
                    (e) => {
                        handleOnChange(e?.target?.value)
                    }
                }
                value={value}
                buttonStyle="solid"
            >
                {
                    options.map(opt => (
                        <Radio.Button key={opt?.name} value={opt?.name}>
                            {opt?.label}
                        </Radio.Button>
                    ))
                }
            </Radio.Group>
        </div>
    )
}

export default RadioGroup