import { FunctionPlugin } from "hyperformula";
import moment from "moment-timezone";

export class Today extends FunctionPlugin {
    today(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('TODAY'), (...args) => {
            const [ value, userTimezone, date_format, recordId, field, shouldSaveTimezone ] = args
            const localTodayValue = localStorage.getItem(`todayValue_${recordId}_${field}`)
            if (!localTodayValue) {
                let todayValue = moment(value, 'YYYY-MM-DD').utc(true).tz(userTimezone)
                if(!todayValue.isValid()){
                    todayValue = moment().utc().startOf('day').tz(userTimezone)
                }
                if(shouldSaveTimezone === 'true'){
                    todayValue = todayValue.toISOString().replace(/.\d+Z$/g, "")
                } else {
                    todayValue = todayValue.format(date_format)
                }
                localStorage.setItem(`todayValue_${recordId}_${field}`, todayValue)        
                return todayValue
            }
            return localTodayValue
        })
    }
}

Today.implementedFunctions = {
    'TODAY': {
        method: 'today',
        parameters: [
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
            {argumentType: "STRING"},
        ],
    }
}