import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const DisplayUser = ({ user, componentClass = '' }) => {
    let content = user?.fullname || user?.email_address;

    return content 
        ? (
            <div className={`py-1 d-flex ${componentClass} ${style['value-container--user']} valueEl`}>
                <div className="d-flex align-items-center">
                    <span className="d-inline-block ml-2">{ content }</span>
                </div>
            </div>
        )
        : null
}

DisplayUser.propTypes = {
    user: PropTypes.object,
    componentClass: PropTypes.string,
}

export default DisplayUser