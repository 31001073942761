export const textWithinFormula = (text) => {
  // stringPattern regex detects valid text within formula
  // will match: "abc"
  // will not match: 5
  // will not match: abc
  // will not match: 'abc'
  const stringPattern = /^(?=)"+(.*)"+$/
  return stringPattern.test(text)
}

const notWrappedByDoubleQuotesStringPattern = /\b\w+(?!"|\()\b/g

export const notWrappedByDoubleQuotes = (text) => {
  // This regex matches text that are not wrapped bby double quotes
  // will match: abc
  // will match: 'abc'
  // will not match: "abc"
  return notWrappedByDoubleQuotesStringPattern.test(text)
}

export const replaceNotWrappedByDoubleQuotes = (text, fx) => {
  // This regex matches text that are not wrapped bby double quotes
  // will match: abc
  // will match: 'abc'
  // will not match: "abc"
  return text.replace(notWrappedByDoubleQuotesStringPattern, fx)
}

export const matchNotWrappedByDoubleQuotes = (text) => {
  // This regex matches text that are not wrapped bby double quotes
  // will match: abc
  // will match: 'abc'
  // will not match: "abc"
  const match = text.match(notWrappedByDoubleQuotesStringPattern)
  return match
}

const singleQuotesThatWrapATextStringPattern = /\b'\B|\B'\b/g

export const singleQuotesThatWrapAText = (text) => {
  // This regex matches single quotes that wrap a text
  // will match: 'abc'
  // will not match: "abc'd"
  return singleQuotesThatWrapATextStringPattern.test(text)
}

export const matchSingleQuotesThatWrapAText = (text) => {
  // This regex matches single quotes that wrap a text
  // will match: 'abc'
  // will not match: "abc'd"
  const match = text.match(singleQuotesThatWrapATextStringPattern)
  return match
}

export const replaceSingleQuotesThatWrapAText = (text, fx) => {
  // This regex matches text that are not wrapped bby double quotes
  // will match: abc
  // will match: 'abc'
  // will not match: "abc"
  return text.replace(singleQuotesThatWrapATextStringPattern, fx)
}