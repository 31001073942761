import React, { useState} from 'react'; 
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import QueryBuilderComponent from "@components/QueryBuilder";
import {tableFilterAdapter} from "@components/QueryBuilder/adapter";
import style from "./style.module.scss";
import { Cx } from "@utils/Q";
import sortBy from "lodash/sortBy";
import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";
import { parseJsonString } from "@utils/json";
import moment from 'moment-timezone';

const ModalFilters = (props) => {
    const {
        columns,
        filterValues,
        onSaveFilter = () => {
        },
        onClear
    } = props

    const bypassField = (val) => {
        try{
            const isJSONObj = parseJsonString(val)
            if(typeof isJSONObj === 'object') return true; //bypass object
            if(moment(val).isValid()) return true; //bypass date
            if((/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/).test(val)) return true; //bypass time
            const valArr = String(val).split(',')
            if(moment(valArr[0]).isValid() && moment(valArr[1]).isValid()) return true; //bypass date between
        } catch (e) {}
        return false
    }

    const valueSchema = Yup.string()
        .test("value", "Value cannot exceed 40 characters", (val) => {
            if(bypassField(val)) return true;
            return String(val).length <= 40;
        })
        .test("value", "Value cannot contain special characters", async (val) => {
            if(bypassField(val)) return true;
            return !(SPECIAL_CHAR_REGEX.test(val));
        })
        .test("value", "Value cannot start with a space", (val) => !(/^ /.test(val)))
        .when("filters", { 
            is: (filters) => {
                return filters?.rules?.length > 0 && filters.rules.map(v => v.value?.length < 1).filter(f => f).flat()?.length > 0;
            },
            then: Yup.string().required("is required") 
        })
        .nullable()
    const ruleSchema = Yup.array().of(Yup.object().shape({
        value: valueSchema,
        rules: Yup.lazy(() => ruleSchema.default(undefined)),
    }))
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues:filterValues,
        validationSchema: Yup.object().shape({
            filters: Yup.object().shape({
                value: valueSchema,
                rules: ruleSchema,
            })
        }),
        validateOnChange: true,
        onSubmit: async (values, fn) => {
            fn.setSubmitting(false);
            setShowModal(false);
            onSaveFilter(values);
        }
    });
    const submitDisabled = formik.errors && !_.isEmpty(formik.errors)
    const onClose = () => {
        setShowModal(false);
    }
    const onClearFilter = () => {
        setShowModal(false);
        formik.setValues({}, false);
        formik.resetForm();
        onSaveFilter({
            filters: { rules:[] }
        });
        onClear && onClear()
    }
    const onChangeFilterRule = tableFilterAdapter({
        onChange: ({ filters, rawFilters }) => {
            formik.setValues({
                ...formik.values,
                filters,
                rawFilters
            })
        }
    })
    const [showModal, setShowModal] = useState(false);
    const hasFilter = () =>{
        return filterValues?.filters?.rules?.length > 0;
    }
    const sortedFields = sortBy(columns?.filter((f) => f.text && !f.hidden && !f.notFilterable), (i) => i.text.toLowerCase());
    return (
        <>
            <button title={hasFilter() ? "Filters have been applied" : "No filter has been applied"}
                className={Cx(style['filter-button'],style['filter-'+(hasFilter()?'on':'off')] )} onClick={() => setShowModal(true)}>
                <i className={Cx(`${hasFilter() ? 'fas' : 'fal'} fa-filter`, style['filter-icon']) } ></i>
            </button>
            <Modal
                scrollable
                size="xl"
                backdrop="static"
                keyboard={false}
                restoreFocus={false}
                show={showModal}
            >
                <Modal.Header className="py-2 pr-2 position-relative zi-1 shadow-4 d-print-none">
                    <Modal.Title as="h5">Advanced Filter</Modal.Title>
                    <Button title="Close" onClick={onClose} size="sm" variant="flat"
                        className="fa-lg far fa-times ired" />
                </Modal.Header>
                <Modal.Body className="fs-scroll tab-content-fieldset tabContentFieldSetupModal">                    
                                       
                    <QueryBuilderComponent
                        fields={sortedFields.map(f => ({
                            name: f?.dataField,
                            label: f?.text,
                            valueEditorType: f?.editorType,
                            config: f?.config
                        }))}
                        config={{
                            restrictFilterField: false
                        }}
                        onChange={onChangeFilterRule}
                        initialValue={filterValues?.rawFilters}
                        mode="tablefilter"
                    />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between flex-row p-1 zi-1 shadow-5">
                    <div className="ml-5">
                        {hasFilter() &&
                            <Button onClick={onClearFilter} variant="danger">Clear Filters</Button>
                        }
                    </div>
                    <div className="justify-content-end">
                        <Button onClick={onClose} variant="secondary" className="mr-2">Cancel</Button>
                        <Button type="submit" disabled={submitDisabled} onClick={formik.handleSubmit}>Submit</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default ModalFilters