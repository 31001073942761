import { useEffect } from "react";

const useSelectOCRValue = ({isOCRMapping, currentValue, options, isFetched, onSelectValue}) => {
    const isContextEqual =(text1, text2)=>{
        const context1 = text1?.toLowerCase().replaceAll(/\'|\.|\,| /g,"");
        const context2 = text2?.toLowerCase().replaceAll(/\'|\.|\,| /g,"")?.split(/\n/)?.[0];
        const subcontext2 = context2?.substring(0,context2?.length/2);
        return text1 === text2 ||  context1 === context2 || context1?.includes(context2)|| context1?.startsWith(subcontext2)
    }
    useEffect(() => {
        if (isFetched && isOCRMapping && options?.length) {
            const actualValue = currentValue?.replace("[AIservice]", "");
            const mappedValue = options.find((o) => isContextEqual(o?.label, actualValue));
            onSelectValue(mappedValue);
        }
    },[currentValue, options, isFetched, isOCRMapping])
}

export default useSelectOCRValue;