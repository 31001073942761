import React from "react";
import clsx from "clsx";
import styles from "./card.module.scss";

const Card = ({
  recordCreatedBy,
  moduleVersion,
  moduleDescription,
  title,
  status,
  appIcon,
  compact = false,
}) => {
  return (
    <div className={clsx("bg-white rounded col-xs-3", styles.square)}>
      <div className="position-absolute top-0 start-0 text-center w-100 h-100 pt-6 d-flex flex-column justify-content-center align-items-center px-4">
        <i className={`far ${appIcon ? appIcon : "fa-browser"} fa-lg mb-2 text-primary`} />
        <h3 className={clsx("mw-100 mb-2 q-mr", styles.heading)} title={title}>
          {title}
        </h3>

        {moduleDescription && (
          <p className="small mb-2">{moduleDescription}</p>
        )}

        {recordCreatedBy && !compact && (
          <p className="small mb-2">Created by {recordCreatedBy}</p>
        )}

        {moduleVersion && !compact ? (
          <div className="text-muted">Version {moduleVersion}</div>
        ) : (
          ""
        )}
      </div>
      <div
        className={
          "position-absolute start-0 bottom-0 badge round-br-0 round-tl-0 round-tr-1 text-white align-items-end align-self-start flexno mnw-80px bg-" +
          (status === "Draft" ? "green-1" : "primary")
        }
      >
        {status}
      </div>
    </div>
  );
};

export default Card;
