import React from 'react'
import Flex from '../../components/Flex'

export default function Maintenance() {
    return (
        <Flex dir="column" justify="center" align="center"
            className="min-vh-100 bgi-size-cover bgi-no-repeat bg-white" // h-100
            style={{ backgroundImage: 'url("/media/bg/bg_prisma.svg")' }} // png
        >
            <Flex wrap dir="row" align="center" className="container">
                <div className="col-md-6 font-weight-bold">
                    <h1 className="font-weight-bolder txt-navy mb-5">{/* fs-25r <div className="h5 font-weight-bolder"></div> */}
                        FinanSys Apps
                    </h1>

                    <p>We apologize for any inconvenience, but our website is currently undergoing scheduled maintenance to improve your browsing experience.</p>
                    <p>During this time, our team is working diligently to ensure that our website runs smoothly and efficiently. We appreciate your patience and understanding as we work to make our site even better for you.</p>
                    <p>Please check back shortly, and we'll be back online as soon as possible.</p>
                </div>

                <div className="col-md-6 text-center">
                    <img
                        alt="maintenance"
                        src="/media/bg/maintenance.png"
                        className="d-inline-block max-w-full"
                        loading="eager"
                    />
                </div>
            </Flex>
        </Flex>
    )
}



