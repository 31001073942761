import React from "react";
import { useHistory } from 'react-router-dom';// useLocation, 
import { Dropdown, Menu, Skeleton } from "antd";
import { Button } from "react-bootstrap";
import useRemoveSubheader from "@utils/hooks/useRemoveSubheader";
import useFullContentLayout from "@utils/hooks/useFullContentLayout";
import RpaDataTable from "./RpaDataTable";
import useApiRequest from "@utils/hooks/useApiRequest";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const RoboticAutomationPage = () => {
	let history = useHistory();
    const { loading } = useApiRequest();

    useRemoveSubheader()
    useFullContentLayout()

    const dcMenu = () => (
		<Menu>			
            <Menu.Item key="advance" onClick={() => history.push("/robotic-automation/advance")}>
				Advanced
			</Menu.Item>
			<Menu.Item key="simple" onClick={() => history.push("/robotic-automation/simple")} >
				Simple
			</Menu.Item>
		</Menu>
	);
    return (
        <div className="px-6">
            <Skeleton 
                active 
                loading={loading?.fetchDataConnectorDatatables} 
                paragraph={{ rows: 3, width: "100%" }} 
                className="cwait p-6"
            >
                <RpaDataTable
                    keyField="id"
                    withFooter={false} 
                    extra={(
                        <Dropdown overlay={dcMenu()} placement="bottomRight">
                            <Button variant="success">
                                New automation <i className="ml-2 fal fa-plus-circle" />
                            </Button>
                        </Dropdown>
                    )}
                />
            </Skeleton>
        </div>
    )
}

export default RoboticAutomationPage;