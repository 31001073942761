/** Entry application component used to compose providers and render Routes. */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { VisibilityProvider } from '../providers/VisibilityProvider';
import Refresher from './components/Refresher';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      suspense: true,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: (count, { response: { status } }) =>
        status !== 401 && status !== 403 && count < 3,
      networkMode: 'always',
    },
  },
});

export default function App({ store, persistor, basename }){
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                {/* Provide `react-intl` context synchronized with Redux state.  */}
                <I18nProvider>
                  <VisibilityProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Refresher >
                      <Routes />
                    </Refresher>
                  </VisibilityProvider>
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
}
