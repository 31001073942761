import axios from 'axios'
import store from '../redux/store';
import { requestInterceptor, requestRejectInterceptor, responseInterceptor, responseRejectInterceptor } from '../redux/setupAxios';
import endpoints from './endpoints'

const client = axios.create()

client.interceptors.request.use(requestInterceptor(store), requestRejectInterceptor)
client.interceptors.response.use(responseInterceptor, responseRejectInterceptor)
client.defaults.timeout = 3600000

const api = Object.keys(endpoints).reduce((collections, path) => {
    collections[path] = async params => {
        return await client.request(endpoints[path](params))
    }
    return collections
}, {})

export { client }
export default api