import { useState, useEffect } from "react";
import useDatasetApi from "./useDatasetApi";

const useDatasetOptions = () => {
    const { getDatasetList, loading } = useDatasetApi()
    const [datasetOptions, setDatasetOptions] = useState([])
    const loadOptions = async () => {
        const response = await getDatasetList()
        if (response?.success && response?.data) {
            const options = response.data.map(data => ({
                value: data.id,
                label: data.display_name // data['display_name'],
            }))
            setDatasetOptions(options)
        }
    }
    useEffect(() => {
        let listLoaded = false
        if (!listLoaded) loadOptions()
        return () => {
            listLoaded = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        datasetOptions,
        loadingDataset: loading.getDatasetList,
    }
}

export default useDatasetOptions