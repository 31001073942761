export const FIELDS_AS_PARAMS = [
    'text',
    'textarea',
    'number',
    'currency',
    'date',
    'datetime',
    'email',
    'switch',
    'slider',
    'rating',
    'aggregation',
    'dropdown',
    'calculation-text',
    'calculation-number',
    'richtext-html',
]