const FILTER_OPERATORS = [
  "Equal to", // 0
  "Not equal to", // 1  
  "Contains", // 2
  "Does not contain", // 3
  "Min length", // 4
  "Max length", // 5
  "Is empty", // 6
  "Is not empty", // 7
  "In", // 8

  
  // "Part of",
  // "Not part of",
  // "Greater than",
  // "Less than",
  // "Greater than or equal to",
  // "Less than or equal to",
];

export default FILTER_OPERATORS