import React from "react";
import DottedContainer from "../DottedContainer";
import style from "./style.module.scss";

const RuleContainer = props => {
    return (
        <div className={`${style['rule-wrapper']} dotted-content`}>
            <DottedContainer>
                <div className={`bg-eee p-4 ${style['rule-container']}`}>
                    { props.children }
                </div>
            </DottedContainer>
        </div>
    )
}

export default RuleContainer