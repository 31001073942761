import React, {useEffect} from "react";
import {Col, DatePicker, Input, InputNumber, Row, Select, TimePicker} from "antd";
import VISIBILITY_CONDITION from "@constants/visibility/condition";
import VALIDATION_TYPES from "@constants/validation/types";
import CONDITIONS from "@constants/validation/conditions";
import {FIELDS_AS_PARAMS} from "@constants/remote-lookup";
import {onBlurDatePicker, onFocusDatePicker} from "@utils/components/antd_datepicker";
import useWorkflowActions from "@utils/hooks/useWorkflowActions";
import { isActualDateTime } from "../../../../utils/date";
import {EXT_DOC, EXT_IMG} from "../../../constants/FILE_EXT";
import {FIELD_VALUE_VALIDATIONS, OTHER_VALIDATIONS} from "../../../constants/validation/types";
import { WORKFLOW_STATUSES } from "../../../constants/datatable";
import FILTER_OPERATORS from "../../form-editor/components/field-tabs/Settings/FilterOperators";
import TABLE_FILTER_CONDITION from "../../form-editor/components/field-tabs/Settings/TableFilterOperators";
import {RenderRequestValueForm} from "../partials/RenderRemoteValue";
import LookupDropdown from "../../form-editor/components/FormEntry/LookupDropdown";
import UserLookupDropdown from "../../form-editor/components/FormEntry/UserLookupDropdown";
import useTimeZone from "@utils/hooks/useTimeZone";
import { SCHEDULE_FREQUENCY } from "@constants/task-scheduler";

const {Option} = Select

export const RenderVisibilityOperator = props => {
    const {
        fieldData,
        handleOnChange,
        value,
    } = props
    const renderOperator = () => {
        let arr = [];
        switch (fieldData?.valueEditorType) {
            case "text":
            case "calculation-text":
                arr = VISIBILITY_CONDITION.foperator.text;
                break;
            case "number":
            case "calculation-number":
                arr = VISIBILITY_CONDITION.foperator.number;
                break;
            case "currency":
                arr = VISIBILITY_CONDITION.foperator.currency;
                break;
            case "date":
            case "calculation-date":
                arr = VISIBILITY_CONDITION.foperator.date;
                break;
            case "calculation-date-time":
            case "datetime":
            case "time":
                arr = VISIBILITY_CONDITION.foperator.datetime;
                break;
            case "email":
                arr = VISIBILITY_CONDITION.foperator.email;
                break;
            case "switch":
                arr = VISIBILITY_CONDITION.foperator.switch;
                break;
            case "rating":
                arr = VISIBILITY_CONDITION.foperator.rating;
                break;
            case "dropdown":
                arr = VISIBILITY_CONDITION.foperator.dropdown;
                break;
            case "user":
                arr = VISIBILITY_CONDITION.foperator.user;
                break;
            case "aggregation":
                arr = VISIBILITY_CONDITION.foperator.aggregation;
                break;
            case "sequence-number":
                arr = VISIBILITY_CONDITION.foperator.sequencenumber;
                break;
            case "checkbox":
                arr = VISIBILITY_CONDITION.foperator.checkbox;
                break;
            case "slider":
                arr = VISIBILITY_CONDITION.foperator.slider;
                break;
            case "checklist":
                arr = VISIBILITY_CONDITION.foperator.checklist;
                break;
            case "workflow_status":
                arr = VISIBILITY_CONDITION.foperator.workflow_status;
                break;
            default:
                break;
        }
        return VISIBILITY_CONDITION.operator.filter((_, i3) => arr.includes(i3))
    }
    const options = renderOperator()
    useEffect(() => {
        if (value === '=' && options[0]?.value) {
            handleOnChange(options[0].value)
        }
        // eslint-disable-next-line
    }, [fieldData])
    return (
        <Select
            showSearch
            optionFilterProp="children"
            className="w-100"
            dropdownClassName="zi-upModal"
            onChange={handleOnChange}
            value={value}
        >
            {
                options.map((x, index) =>
                    <Option
                        key={index}
                        value={x.value}
                        title={x.label}
                    >
                        {x.label}
                    </Option>
                )
            }
        </Select>
    )
}

export const RenderFilterOperator = props => {
    const {
        fieldData,
        handleOnChange,
        value,
    } = props
    const renderOperator = () => {
        let options = [];
        switch (fieldData?.valueEditorType) {
            default:
                options = FILTER_OPERATORS;
                break;
        }
        return options.map(opt => ({
            value: opt,
            label: opt,
        }));
    }
    const options = renderOperator()
    useEffect(() => {
        if (value === '=' && options[0]?.value) {
            handleOnChange(options[0].value)
        }
        // eslint-disable-next-line
    }, [fieldData])
    return (
        <Select
            showSearch
            optionFilterProp="children"
            className="w-100"
            dropdownClassName="zi-upModal"
            onChange={handleOnChange}
            value={value}
        >
            {
                options.map((x, index) =>
                    <Option
                        key={index}
                        value={x.value}
                        title={x.label}
                    >
                        {x.label}
                    </Option>
                )
            }
        </Select>
    )
}

export const RenderOperator = props => {
    const {
        handleOnChange,
        value,
        options,
        fieldData,
    } = props
    useEffect(() => {
        if (value === '=' && options[0]?.name) {
            handleOnChange(options[0].name)
        }
        // eslint-disable-next-line
    }, [fieldData])
    return (
        <Col span={6}>
            <Select
                showSearch
                optionFilterProp="children"
                className="w-100"
                dropdownClassName="zi-upModal"
                onChange={handleOnChange}
                value={value}
            >
                {
                    options.map((x, index) =>
                        <Option
                            key={index}
                            value={x.name}
                            title={x.label}
                        >
                            {x.label}
                        </Option>
                    )
                }
            </Select>
        </Col>
    )
}
export const RenderTableFilterOperator = props => {
    const {
        fieldData,
        handleOnChange,
        value,
    } = props
    const filteredOperators = () => {
        let arr = [];
        switch (fieldData?.valueEditorType) {
            case "number":
            case "calculation-number":
                arr = TABLE_FILTER_CONDITION.foperator.number;
                break;
            case "date":
            case "time":
            case "datetime":
            case "calculation-date":
            case "calculation-date-time":
                arr = TABLE_FILTER_CONDITION.foperator.datetime;
                break;
            case "lookup":
            case "calculation-lookup":
            case "calculation-reference-dynamic":
                arr = TABLE_FILTER_CONDITION.foperator.lookup;
                break;
            case "remote-lookup":
                arr = TABLE_FILTER_CONDITION.foperator.external;
                break;
            case "user":
            case "calculation-user":
                arr = TABLE_FILTER_CONDITION.foperator.user;
                break;
            default:
                arr = TABLE_FILTER_CONDITION.foperator.text;
                break;
        }
        return TABLE_FILTER_CONDITION.operator.filter((_, i3) => arr.includes(i3))
    }
    const options = filteredOperators();
    useEffect(() => {
        if (value === '=' && options[0]?.value) {
            handleOnChange(options[0].value)
        }
        // eslint-disable-next-line
    }, [fieldData])
    return (
        <Select
            showSearch
            optionFilterProp="children"
            className="w-100 mr-2"
            dropdownClassName="zi-upModal"
            onChange={handleOnChange}
            value={value}
        >
            {
                options.map((x, index) =>
                    <Option
                        key={index}
                        value={x.value}
                        title={x.label}
                    >
                        {x.label}
                    </Option>
                )
            }
        </Select>
    )
}
export const RenderFieldValueEditor = props => {
    const {
        type,
        handleOnChange,
        value = [],
        validation = {},
    } = props
    const onChange = (val, config) => {
        handleOnChange([valueType, val, config])
    }
    const { date_format, datetime_format, time_format, generateDateTime, 
        toLocalMoment, toUTCDateTimeStr, toUTCTimeStr, toValidMoment } = useTimeZone();
    const isUserField = type !== 'user'
    const [valueType, valueExpression, config] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , '', {}
        ]
        : value
    if (valueType === 'field') {
        return (
            <RenderValueEditorFieldsOption {...props} />
        )
    }
    switch (type) {
        case "calculation-text":
        case "text":
            return (
                <Row className="w-100">
                    <Input
                        className={"w-100 text-left "+ !validation?.valid ? "is-invalid" : ""}
                        type="text"
                        required
                        name="other"
                        title=""
                        value={valueExpression}
                        onChange={e => onChange(e.target.value)}
                    />
                    {!validation?.valid &&
                        <div className="invalid-feedback">{validation?.reasons[0]}</div>
                    }
                </Row>
            );
        case "dropdown":
        case "aggregation":
        case "sequence-number":
            return (
                <Input
                    className={"w-100 text-right"}
                    type="text"
                    required
                    name="other"
                    title=""
                    value={valueExpression}
                    onChange={e => onChange(e.target.value)}
                />
            );
        case "calculation-number":
        case "number":
        case "currency":
        case "rating":
            return (
                <InputNumber
                    className={"w-100"}
                    autoComplete="off"
                    type="text"
                    name="other"
                    inputMode="decimal"
                    step={type === "currency" ? "0.11" : "1"}
                    value={valueExpression}
                    onChange={value => onChange(value)}
                />
            );
        case "time":
            const timePickerValue = toLocalMoment(generateDateTime({ value: valueExpression, isActual: config?.now }));
            return (
                <TimePicker
                    className={"w-100"}
                    popupClassName="zi-upModal"
                    placeholder={time_format}
                    format={time_format}
                    defaultValue={timePickerValue}
                    onChange={(val, timeStr) => {
                        const newVal = toUTCTimeStr(timeStr);
                        const isActual = isActualDateTime(500, val?.valueOf())
                        onChange(newVal, { ["now"]: isActual })
                    }}
                    onFocus={onFocusDatePicker}
                    onBlur={onBlurDatePicker}
                />
            );
        case "date":
        case "datetime":
        case "calculation-date":
        case "calculation-date-time":
            const showTime = type.includes("time");
            const format = showTime ? datetime_format : date_format;
            const flag = showTime ? 'today' : 'now';
            const generatedValue = generateDateTime({ value: valueExpression, isActual: config?.[flag] })
            const defaultPickerValue = showTime ? toLocalMoment(generatedValue) : toValidMoment(generatedValue);
            return (
                <DatePicker
                    className={"w-100"}
                    dropdownClassName="zi-upModal"
                    showToday
                    showTime={showTime}
                    name="other"
                    placeholder={format}
                    format={format}
                    defaultValue={defaultPickerValue}
                    onChange={(val, dateStr) => {
                        const treshhold = showTime ? 500 : 5000;
                        const newVal = showTime ? toUTCDateTimeStr(dateStr) : dateStr;
                        const isActual = isActualDateTime(treshhold, val?.valueOf())
                        onChange(newVal, { [flag]: isActual })
                    }}
                    onFocus={onFocusDatePicker}
                    onBlur={onBlurDatePicker}
                />
            );
        case "switch":
            return (
                <Select
                    className="otherfields custom-select custom-select-sm w-100"
                    value={valueExpression}
                    onChange={onChange}
                >
                    {[
                        {t: "Yes", v: 1},
                        {t: "No", v: 0}
                    ].map(v =>
                        <Option key={v.t} value={v.v}>{v.t}</Option>
                    )}
                </Select>
            );
        case "email":
            return (
                <Input required title=""
                       className={"w-100"}
                       type="email"
                       autoComplete="off"
                       name="other"
                       value={valueExpression ?? ""}
                       onChange={e => onChange(e.target.value)}
                />
            );
        case "workflow_status":
            return (
                <Select
                    className="otherfields custom-select custom-select-sm w-100"
                    value={valueExpression}
                    onChange={onChange}
                >
                    {WORKFLOW_STATUSES.map(v =>
                        <Option key={v} value={v}>{v}</Option>
                    )}
                </Select>
            );
        default:
            return (
                <Row className="w-100">
                    <Input
                        className={!validation?.valid ? "is-invalid" : ""}
                        type="text"
                        required
                        autoComplete="off"
                        name="other"
                        title=""
                        value={valueExpression}
                        onChange={e => onChange(e.target.value)}
                    />
                    {!validation?.valid &&
                        <div className="invalid-feedback">{validation?.reasons[0]}</div>
                    }
                </Row>
            );
    }
}

export const RenderFilterFieldValueEditor = props => {
    const {
        fieldData,
        handleOnChange,
        operator,
        value = [],
        validation = {},
    } = props
    const onChange = val => {
        handleOnChange([valueType, val, valueFieldType])
    }    
    const { datetime_format, toLocalMoment, toUTCMoment } = useTimeZone();
    const [valueType, valueExpression, valueFieldType] = !value
        ? ['value', '', '']
        : value
    if (valueType === 'field') {
        return (
            <RenderFilterValueEditorFieldsOption {...props} />
        )
    }
    switch (fieldData) {
        case "addedOn":
        case "lastSignedIn":
            const format = datetime_format;
            return (
                <DatePicker
                    className={"w-100"}
                    dropdownClassName="zi-upModal"
                    showToday
                    showTime
                    name="other"
                    placeholder={format}
                    format={format}
                    defaultValue={valueExpression ? toLocalMoment(valueExpression) : null}
                    onChange={(date) =>{
                        let dateStr = toUTCMoment(date).format(format);
                         onChange(dateStr)
                    }}
                    onFocus={onFocusDatePicker}
                    onBlur={onBlurDatePicker}
                />
            );
        default:
            return (
                !["isempty","isnotempty"].includes(operator) ?
                <Row className="w-100">
                    <Input
                        className={!validation?.valid ? "is-invalid" : ""}
                        type="text"
                        required
                        autoComplete="off"
                        name="other"
                        title=""
                        value={valueExpression}
                        onChange={e => onChange(e.target.value)}
                    />
                    {!validation?.valid &&
                        <div className="invalid-feedback">{validation?.reasons[0]}</div>
                    }
                </Row>
                : <div className="w-100"></div>
            );
    }
}

export const RenderTableFilterValueEditor = props => {
    const {
        type,
        fieldData,
        handleOnChange,
        operator,
        value = [],
        validation = {},
    } = props
    const onChange = val => {
        handleOnChange([valueType, val])
    }    

    const { date_format, datetime_format, time_format, toLocalMoment, toUTCMoment, toValidMoment, generateDateTime, toUTCTimeStr } = useTimeZone();
    const [valueType, valueExpression, config] = !value
        ? ['value', '', '']
        : value
    if (["isempty", "isnotempty"].includes(operator)) {
        return (<div className="w-100"></div>);
    }
    if (["eq", "neq"].includes(operator)) {
        switch (type) {
            case "lookup":
            case "calculation-lookup":
            case "calculation-reference-dynamic":
                return (
                    <LookupDropdown
                        config={fieldData?.config}
                        currentValue={valueExpression}
                        onChange={onChange}
                        disabled={false}
                        formValues={{}}
                        isEditable={true}
                        className={"w-100"}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        removeOptionsFilter={true}
                    />
                    )
            case "user":
            case "calculation-user":
                return <UserLookupDropdown
                config={fieldData?.config}
                currentValue={valueExpression}
                onChange={onChange}
                disabled={false}
                formValues={{}}
                className={"w-100"}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                formik={{values:""}}
                menuPortalTarget={document.body}
            />   
           
        }
    }
    switch (type) {
        case "date":
        case "datetime":
        case "calculation-date":
        case "calculation-date-time":
            const showTime = type.includes("time");
            const displayFormat = showTime ? datetime_format : date_format;
            const systemFormat = `YYYY-MM-DD${showTime?' HH:mm:ss':''}`;
            let defaultPickerValue = showTime ? toLocalMoment(valueExpression) : toValidMoment(valueExpression)
            switch (operator) {
                case "between":
                case "notbetween":                    
                    const { RangePicker } = DatePicker;
                    const v = value && value[1].split(',');
                    defaultPickerValue = value ?
                        showTime ? [toLocalMoment(v[0]), toLocalMoment(v[1])] :
                            [toValidMoment(v[0]), toValidMoment(v[1])]
                        : null

                    return (
                        <RangePicker
                            className={"w-100"}
                            dropdownClassName="zi-upModal"
                            showToday
                            showTime={showTime}
                            name="other"
                            placeholder={displayFormat}
                            format={displayFormat}
                            defaultValue={defaultPickerValue}
                            onChange={(date) => {
                                const dtFrom = showTime ? toUTCMoment(date[0]) : toValidMoment(date[0]).utc(true);
                                const dtTo = showTime ? toUTCMoment(date[1]) : toValidMoment(date[1]).utc(true);
                                const val = `${dtFrom.format(systemFormat)},${dtTo.format(systemFormat)}`;
                                onChange(val)
                            }}
                            onFocus={onFocusDatePicker}
                            onBlur={onBlurDatePicker}
                        />
                    );
                default:
                    return (
                        <DatePicker
                            className={"w-100"}
                            dropdownClassName="zi-upModal"
                            showToday
                            showTime={showTime}
                            name="other"
                            placeholder={displayFormat}
                            format={displayFormat}
                            defaultValue={defaultPickerValue}
                            onChange={(date) => {
                                let newVal = showTime ? toUTCMoment(date) : toValidMoment(date).utc(true);
                                onChange(newVal.toISOString())
                            }}
                            onFocus={onFocusDatePicker}
                            onBlur={onBlurDatePicker}
                        />
                    );
            }
        case "time":
            const timePickerValue = toLocalMoment(generateDateTime({ value: valueExpression, isActual: config?.now }));
            return (
                <TimePicker
                    className={"w-100"}
                    popupClassName="zi-upModal"
                    placeholder={time_format}
                    format={time_format}
                    defaultValue={timePickerValue}
                    onChange={(val, timeStr) => {
                        const newVal = toUTCTimeStr(timeStr);
                        const isActual = isActualDateTime(500, val?.valueOf())
                        onChange(newVal, { ["now"]: isActual })
                    }}
                    onFocus={onFocusDatePicker}
                    onBlur={onBlurDatePicker}
                />
            );
        case "number":
        case "calculation-number":
            return (
                <Input
                    className={"w-100"}
                    type="number"
                    required
                    autoComplete="off"
                    name="other"
                    title=""
                    value={valueExpression}
                    onChange={e => onChange(Number(e.target.value))}
                />
            );
        case "schedule-frequency":
            return (
                <Select
                    className="otherfields w-100"
                    value={valueExpression}
                    onChange={(val)=>{
                        handleOnChange([valueType, val])
                    }}
                >
                    {
                        SCHEDULE_FREQUENCY.map((opt, optId) => (
                            <Select.Option key={optId} value={opt.replace(' ', '_').toLowerCase()}>{ opt }</Select.Option>
                        ))
                    }
                </Select>
            );
        default:
            return (
                <Row className="w-100">
                    <Input
                        className={!validation?.valid ? "is-invalid" : ""} 
                        type="text"
                        required
                        autoComplete="off"
                        name="other"
                        title=""
                        value={valueExpression}
                        onChange={e => onChange(e.target.value)}
                    />
                    {!validation?.valid &&
                        <div className="invalid-feedback">{validation?.reasons[0]}</div>
                    }
                </Row>
            );
    }
}
export const RenderValidationFieldValueEditor = props => {
    const {
        type,
        handleOnChange,
        value = [],
        operator,
        validation = {},
    } = props
    const onChange = (val, config) => {
        handleOnChange([valueType, val, valueMessage, config])
    }    
    const { date_format, datetime_format, time_format, generateDateTime, toLocalMoment, toUTCMoment, toValidMoment, toUTCTimeStr } = useTimeZone();
    const isUserField = type !== 'user'
    const [valueType, valueExpression, valueMessage, config] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , ''
            , ''
            , {}
        ]
        : value
    if (valueType === 'field') {
        return (
            <RenderValidationValueEditorFieldsOption {...props} />
        )
    }
    switch (type) {
        case 'date':
        case 'datetime':
        case 'calculation-date':
        case 'calculation-date-time':
            const showTime = type.includes("time");
            const format = showTime ? datetime_format : date_format;
            const flag = showTime ? 'now' : 'today';
            const generatedValue = generateDateTime({ value: valueExpression, isActual: config?.[flag] });
            const defaultPickerValue = showTime ? toLocalMoment(generatedValue) : toValidMoment(generatedValue);
            return (
                <DatePicker
                    className="d-flex"
                    dropdownClassName="zi-upModal"
                    showToday
                    showTime={showTime}
                    placeholder={format}
                    format={format}
                    defaultValue={defaultPickerValue}
                    onChange={(date, dateStr) => {
                        const treshhold = showTime ? 500 : 5000
                        const isActual = isActualDateTime(treshhold, date?.valueOf())
                        let newVal = showTime ? toUTCMoment(dateStr) :toValidMoment(dateStr).utc(true);
                        newVal = newVal ? newVal.format(format) : null;
                        onChange(newVal, { [flag]: isActual })
                    }}
                />
            );
        case "time":
            const timePickerValue = toLocalMoment(generateDateTime({ value: valueExpression, isActual: config?.now }));
            return (
                <TimePicker
                    className={"w-100"}
                    popupClassName="zi-upModal"
                    placeholder={time_format}
                    format={time_format}
                    defaultValue={timePickerValue}
                    onChange={(val, timeStr) => {
                        const newVal = toUTCTimeStr(timeStr);
                        const isActual = isActualDateTime(500, val?.valueOf())
                        onChange(newVal, { ["now"]: isActual })
                    }}
                    onFocus={onFocusDatePicker}
                    onBlur={onBlurDatePicker}
                />
            );
        case 'switch':
            return (
                <Select
                    onChange={e => onChange(e.target ? e.target.value : e)}
                    value={valueExpression}
                    className="custom-select custom-select-sm"
                >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </Select>
            );
        default:
            const isUploadField = ["multi-file", "file", "image"].includes(type);
            
            return (
                <Row className="w-100">
                    <Input
                        onChange={e => onChange(e.target.value)}
                        value={valueExpression}
                        type={isUploadField ? "number" : "text"}
                        min={isUploadField ? 1 : undefined}
                        max={isUploadField ? 6291456 : undefined}
                        placeholder="Value"
                        className={"inputValidationValue "+ !validation?.valid ? "is-invalid" : ""}
                        addonAfter={
                            operator === VALIDATION_TYPES[33]
                                ? 'MB'
                                : null
                        }
                    />
                    {!validation?.valid &&
                        <div className="invalid-feedback">{validation?.reasons[0]}</div>
                    }
                </Row>
            );
    }
}

export const RenderValueEditor = props => {
    const {
        type,
        handleOnChange,
        operator,
        value = [],
    } = props

    const isUserField = type !== 'user'
    const [valueType] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , ''
        ]
        : value
    const fieldTypes = [
        ...isUserField
            ? [{
                value: 'value',
                label: 'Value',
            }]
            : [],
        {
            value: 'field',
            label: 'Field',
        }
    ]
    return (
        <Col span={8}>
             {
                !["Is_empty", "Is_not_empty"].includes(operator)
                    ? 
                <Row className="rule-value mx-0 ml-1 flex-grow-1" gutter={10}>
                    <Col span={12}>
                        <Select
                            name="valueType"
                            id="valueType"
                            value={valueType}
                            className="w-100"
                            onChange={val => {
                                handleOnChange([val, ''])
                            }}
                        >
                            {
                                fieldTypes.map(field => (
                                    <Option key={field?.value} value={field?.value}>{field?.label}</Option>
                                ))
                            }
                        </Select>
                    </Col>
                    <Col span={12}>
                        <RenderFieldValueEditor {...props} />
                    </Col>
                </Row>
                :
                null
            }
        </Col>
    )
}

export const RenderFilterValueEditor = props => {
    const {
        handleOnChange,
        value = [],
        operator,
    } = props
    const [valueType] = !value
        ? ['value', '', '']
        : value
    const fieldTypes = [
        {
            value: 'value',
            label: 'Value',
        },
        {
            value: 'field',
            label: 'Field',
        }
    ]
    return (
        <Col span={10}>
            {
                !["Is empty", "Is not empty"].includes(operator)
                    ? <Row className="rule-value mx-0 ml-1 flex-grow-1" gutter={10}>
                        <Col span={12}>
                            <Select
                                name="valueType"
                                id="valueType"
                                value={valueType}
                                className="w-100"
                                onChange={val => {
                                    handleOnChange([val, '', ''])
                                }}
                            >
                                {
                                    fieldTypes.map(field => (
                                        <Option key={field?.value} value={field?.value}>{field?.label}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                        <Col span={12}>
                            <RenderFilterFieldValueEditor {...props} />
                        </Col>
                    </Row>
                    : null
            }
        </Col>
    )
}

export const RenderValidationValueEditor = props => {
    const {
        type,
        handleOnChange,
        value = [],
        operator,
    } = props
    const isUserField = type !== 'user'
    const [valueType, valueExpression, valueMessage] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , ''
            , ''
        ]
        : value
    const fieldTypes = [
        ...isUserField
            ? [{
                value: 'value',
                label: 'Value',
            }]
            : [],
        {
            value: 'field',
            label: 'Field',
        }
    ]
    const parseValue = val => Array.isArray(val) ? val : [];
    return (
        <Col span={16}>
            <Row className="rule-value mx-0 ml-1 flex-grow-1" gutter={10}>
                <Col span={12}>
                    {
                        operator === VALIDATION_TYPES[32]
                            ? (
                                <Select
                                    showSearch
                                    showArrow
                                    mode="multiple"
                                    className={`mnw-80px text-uppercase fs-select-tag`}
                                    dropdownClassName="zi-upModal text-uppercase"
                                    value={parseValue(valueExpression)}
                                    onChange={val => {
                                        handleOnChange([valueType, val, valueMessage])
                                    }}
                                >
                                    {
                                        (
                                            ['multi-file', 'file'].includes(type)
                                                ? [...EXT_IMG, ...EXT_DOC]
                                                : EXT_IMG
                                        ).map((v, i) => (
                                            <Select.Option key={i} value={v}>
                                                {v}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            )
                            : (
                                FIELD_VALUE_VALIDATIONS.includes(operator) ||
                                OTHER_VALIDATIONS.includes(operator)
                            ) && operator !== VALIDATION_TYPES[40]
                                ? (
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <Select
                                                name="valueType"
                                                id="valueType"
                                                value={valueType}
                                                className="w-100"
                                                onChange={val => {
                                                    handleOnChange([val, '', ''])
                                                }}
                                            >
                                                {
                                                    fieldTypes.map(field => (
                                                        <Option key={field?.value} value={field?.value}>
                                                            {field?.label}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={12}>
                                            <RenderValidationFieldValueEditor {...props} />
                                        </Col>
                                    </Row>
                                )
                                : null
                    }
                </Col>
                {
                    operator !== VALIDATION_TYPES[32]
                        ? (
                            <>
                                <Col span={12}>
                                    <Input
                                        placeholder="Message to user"
                                        value={valueMessage}
                                        onChange={e => {
                                            handleOnChange([valueType, valueExpression, e?.target?.value])
                                        }}
                                    />
                                </Col>
                            </>
                        )
                        : null
                }
            </Row>
        </Col>
    )
}

export const RenderSecurityValueEditor = props => {
    const {getActivityList} = useWorkflowActions();
    const activityList = getActivityList().map(act => ({
        value: act.activityId,
        label: act.activityName,
    }))
    const {
        handleOnChange,
        value = [],
    } = props
    return (
        <>
            <Col span={5}>
                Process step equals to
            </Col>
            <Col span={9}>
                <Select
                    name="valueType"
                    id="valueType"
                    value={value}
                    className="w-100"
                    onChange={handleOnChange}
                >
                    {
                        activityList.map(field => (
                            <Option key={field?.value} value={field?.value}>{field?.label}</Option>
                        ))
                    }
                </Select>
            </Col>
        </>
    )
}

export const RenderRequestValueEditor = props => {
    const {handleOnChange, value, context} = props;
    const {sections, id, fid, type = 'headers'} = context.config || {}
    const [valueName, valueType, valueExpression, valueDataKey, valueDataColumnId] = !value
        ? ['', 'value', '', '', '']
        : value
    const sectionFields = sections.map(section => {
        const currentSectionFields = section.rows.reduce((acc, row) => {
            return [
                ...acc,
                ...row.fields,
            ]
        }, [])
        return {
            isTable: section.isTable,
            fields: currentSectionFields,
        }
    })
    const nonTableSectionFields = sectionFields.filter(section => !section.isTable).reduce((acc, section) => {
        return [
            ...acc,
            ...section.fields,
        ]
    }, []).filter((f) => f.fieldType !== 'empty-col')
    const currentSection = sectionFields.find(section => section.fields.find(field => {
        return field.id === id || field.fid === fid
    }))
    const siblingFields = (currentSection && currentSection.isTable
        ? [...currentSection.fields, ...nonTableSectionFields] // currentSection.fields
        : nonTableSectionFields)
        .filter(field => FIELDS_AS_PARAMS.includes(field.fieldType))
        .filter(field => field.id && field.id !== id)
    const fieldOptions = siblingFields.map(field => ({
        value: field.id,
        label: field.fieldName,
    }));
    const optionTypes = [
        ...['body', 'params'].includes(type)
            ? [{
                value: 'field',
                label: 'Field',
            }]
            : [],
        {
            value: 'value',
            label: 'Value',
        }, {
            value: 'data',
            label: 'Data',
        }]
    const valueProps = {value, handleOnChange, fieldOptions}

    return (
        <Col span={22}>
            <div className="form-row">
                <div className="col-md-4 form-group">
                    <label className="d-block">Name</label>
                    <Input
                        className={"w-100"}
                        type="text"
                        required
                        name="other"
                        title=""
                        value={valueName}
                        onChange={e => {
                            handleOnChange([
                                e?.target?.value,
                                valueType,
                                valueExpression,
                                valueDataKey,
                                valueDataColumnId,
                            ])
                        }}
                    />
                </div>
                <div className="col form-group">
                    <label className="d-block">Type</label>
                    <Select
                        className="w-100"
                        value={valueType}
                        onChange={opt => {
                            handleOnChange([
                                valueName,
                                opt,
                                '',
                                '',
                                '',
                            ])
                        }}
                    >
                        {
                            optionTypes?.map((x, index) => {
                                return (
                                    <Option
                                        key={index}
                                        value={x.value}
                                        title={x.label}
                                        className={"align-items-center before-muted q-mr"}
                                    >
                                        {x.label}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </div>
                <div className="col-md-4 form-group">
                    <label className="d-block">Value</label>
                    <RenderRequestValueForm {...valueProps} />
                </div>
            </div>

            {
                valueType === 'data'
                    ? (
                        <div className="form-row">
                            <div className="offset-md-4 col form-group">
                                <label className="d-block">Dataset Key</label>
                                <Select
                                    className="w-100"
                                    value={valueDataKey}
                                    onChange={opt => {
                                        handleOnChange([
                                            valueName,
                                            valueType,
                                            valueExpression,
                                            opt,
                                            valueDataColumnId,
                                        ])
                                    }}
                                >
                                    {
                                        fieldOptions?.map((x, index) => {
                                            return (
                                                <Option
                                                    key={index}
                                                    value={x.value}
                                                    title={x.label}
                                                    className={"align-items-center before-muted q-mr"}
                                                >
                                                    {x.label}
                                                </Option>
                                            );
                                        })
                                    }
                                </Select>
                            </div>
                            <div className="col-md-4 form-group">
                                <label className="d-block">Dataset Column ID</label>
                                <Input
                                    className={"w-100"}
                                    type="text"
                                    required
                                    name="other"
                                    title=""
                                    value={valueDataColumnId}
                                    onChange={e => {
                                        handleOnChange([
                                            valueName,
                                            valueType,
                                            valueExpression,
                                            valueDataKey,
                                            e?.target?.value,
                                        ])
                                    }}
                                />
                            </div>
                        </div>
                    )
                    : null
            }
        </Col>
    )
}

export const RenderValueEditorFieldsOption = props => {
    const {
        type,
        field,
        context,
        value,
        handleOnChange,
        operator,
    } = props
    const {fields = []} = context
    const isUserField = type !== 'user'
    const [valueType, valueExpression] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , ''
        ]
        : value
    const onChange = val => {
        handleOnChange([valueType, val])
    }
    const getFieldsOptions = () => {
        const idx = VISIBILITY_CONDITION.operator.find(({value}) => value === operator)?.i;
        let options = [];
        let arrFilter = [];
        const defFields = VISIBILITY_CONDITION.field.filter(f => f?.value !== field);
        const addOptionsFromDefaults = predicate => {
            options = [
                ...options,
                ...defFields.filter(predicate),
            ]
        }

        switch (type) {
            case "calculation-text":
            case "text":
                // Equal to
                // Not equal to
                if ([0, 1].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType.includes('text') || x.fieldType === 'aggregation')
                    arrFilter = ['text', 'calculation-text', 'dropdown', 'aggregation'];
                    // Contains
                    // Does not contain
                    // Part of
                    // Not part of
                } else if ([2, 3, 10, 11].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType === 'text')
                    arrFilter = ['text', 'calculation-text', 'textarea', 'dropdown', 'aggregation'];
                    // Min Length
                    // Max Length
                } else if ([8, 9].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType.includes('number'))
                    arrFilter = ['number', 'calculation-number'];
                }
                break;
            case "user":
                // Equal to
                // Not equal to
                if ([0, 1].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType === 'user')
                    // Part of
                    // Not part of
                } else if ([10, 11].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType === 'user')
                }
                options.push({value: 'defCurrent_User', label: 'Current User'});
                break;
            case "time":
            case "date":
            case "datetime":
            case "calculation-date":
            case "calculation-date-time":
                // Equal to
                // Not equal to
                // Greater than
                // Less than
                // Greater than or equal to
                // Less than or equal to
                addOptionsFromDefaults(x => x.fieldType.includes('time' || 'date' || 'datetime' || 'date-time'))
                arrFilter = ['date', 'datetime','calculation-date', 'calculation-date-time', 'time'];
                break;
            case "rating":
            case "calculation-number":
            case "number":
            case "slider":
                // Equal to
                // Not equal to
                // Greater than
                // Less than
                // Greater than or equal to
                // Less than or equal to
                addOptionsFromDefaults(x => x.fieldType.includes('number') || x.fieldType === 'rating')
                arrFilter = ['number','calculation-number', 'aggregation'];
                break;
            case "currency":
                // Equal to
                // Not equal to
                // Greater than
                // Less than
                // Greater than or equal to
                // Less than or equal to
                addOptionsFromDefaults(x => x.fieldType === 'currency')
                arrFilter = ['currency'];
                break;
            case "switch":
                // Equal to
                // Not equal to
                addOptionsFromDefaults(x => x.fieldType === 'switch')
                arrFilter = ['switch'];
                break;
            case "dropdown":
                // Equal to
                // Not equal to
                addOptionsFromDefaults(x => x.fieldType === 'text')
                arrFilter = ['text', 'textarea'];
                break;
            case "checkbox":
                // Contains
                // Not Contains
                addOptionsFromDefaults(x => x.fieldType === 'text')
                arrFilter = ['text', 'textarea', 'dropdown'];
                break;
            case "checklist":
                // Contains
                // Not Contains
                if ([2, 3].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType === 'text')
                    arrFilter = ['text', 'textarea', 'dropdown'];
                }
                    // Selected count equal to
                    // Selected count not equal to
                    // Selected count greater than
                    // Selected count lesser than
                    // Selected count greater than or equal to
                // Selected count lesser than or equal to
                else if ([26, 27, 28, 29, 30, 31].includes(idx)) {
                    addOptionsFromDefaults(x => x.fieldType === 'number')
                    arrFilter = ['number', 'aggregation'];
                }
                break;
            default:
                break;
        }

        fields.filter((x, i) => i > 9).forEach((x) => {
            if (arrFilter.includes(x.valueEditorType)) {
                options.push(x);
            }
        })

        return options.map(f => ({
            name: f?.name || f?.value,
            valueEditorType: f?.valueEditorType || value?.type,
            label: f?.label,
        }))
    }
    return (
        <Select
            optionFilterProp="children"
            className="w-100"
            dropdownClassName="zi-upModal"
            value={valueExpression}
            onChange={value => onChange(value)}
        >
            {
                getFieldsOptions()?.map((x, index) => {
                    return (
                        <Option
                            key={index}
                            value={x.name}
                            title={x.label}
                            className={"align-items-center before-muted q-mr"}
                        >
                            {x.label}
                        </Option>
                    );
                })
            }
        </Select>
    )
}

export const RenderFilterValueEditorFieldsOption = props => {
    const {
        context,
        value,
        handleOnChange,
        operator,
    } = props
    const {config} = context
    const {filterFieldValues, restrictFilterField} = config || {}
    const [valueType, valueExpression] = !value
        ? ['value', '', '']
        : value
    const getFieldsOptions = () => {
        const isLengthFilter = operator === 'Min length' || operator === 'Max length'
        const lengthFields = ['number', 'currency']
        const restrictFields = ['text']
        const options = filterFieldValues
            ? filterFieldValues
                .filter(f => {
                    return isLengthFilter
                        ? lengthFields.includes(f.fieldType)
                        : true
                })
                .filter(f => {
                    return restrictFilterField
                        ? restrictFields.includes(f.fieldType)
                        : true
                })
            : [];

        return options.map(f => ({
            name: f?.id,
            valueEditorType: f?.fieldType,
            label: f?.fieldName,
        }))
    }
    const valueOptions = getFieldsOptions()
    const onChange = val => {
        const valField = valueOptions.find(opt => opt?.name === val)
        handleOnChange([valueType, val, valField?.valueEditorType])
    }
    return (
        <Select
            optionFilterProp="children"
            className="w-100"
            dropdownClassName="zi-upModal"
            value={valueExpression}
            onChange={value => onChange(value)}
        >
            {
                valueOptions?.map((x, index) => {
                    return (
                        <Option
                            key={index}
                            value={x.name}
                            title={x.label}
                            className={"align-items-center before-muted q-mr"}
                        >
                            {x.label}
                        </Option>
                    );
                })
            }
        </Select>
    )
}

export const RenderValidationValueEditorFieldsOption = props => {
    const {
        type,
        fieldData,
        context,
        value,
        handleOnChange,
        operator,
    } = props
    const {fields = []} = context
    const isUserField = type !== 'user'
    const [valueType, valueExpression, valueMessage] = !value
        ? [
            isUserField
                ? 'value'
                : 'field'
            , ''
        ]
        : value
    const onChange = val => {
        handleOnChange([valueType, val, valueMessage])
    }
    const getFieldsOptions = () => {
        let filteredFields = [];
        let defFields = CONDITIONS.defFields;

        let filterType = [];
        switch (type) {
            case "calculation-text":
            case 'text':
                filterType = ['text'];
                break;
            case 'textarea':
            case 'dropdown':
                filterType = ['text', 'textarea'];
                break;
            case "calculation-number":
            case 'number':
                filterType = ['number'];
                break;
            case 'currency':
                filterType = ['number', 'currency'];
                break;
            case "calculation-date":
            case 'date':
                filterType = ['date'];
                break;
            case "calculation-date-time":
            case 'datetime':
                filterType = ['date', 'datetime'];
                break;
            case 'switch':
                filterType = ['switch'];
                break;
            case 'image':
                filterType = ['number'];
                break;
            case 'user':
                filterType = ['user'];
                break;
            default:
                break;
        }

        switch (operator) {
            case VALIDATION_TYPES[2]:
            case VALIDATION_TYPES[5]:
            case VALIDATION_TYPES[33]:
            case VALIDATION_TYPES[34]:
            case VALIDATION_TYPES[35]:
            case VALIDATION_TYPES[36]:
            case VALIDATION_TYPES[37]:
                filterType = ['number'];
                break;
            case VALIDATION_TYPES[3]:
            case VALIDATION_TYPES[4]:
            case VALIDATION_TYPES[7]:
                filterType = ['text', 'textarea'];
                break;
            case VALIDATION_TYPES[38]:
            case VALIDATION_TYPES[39]:
                filterType = ['text', 'textarea', 'dropdown'];
                break;
            default:
                break;
        }

        defFields.forEach(x => {
            if (filterType.includes(x.type)) {
                filteredFields.push(x);
            }
        });
        fields.filter(x => x.name && x.name !== fieldData.name).forEach(y => {
            if (filterType.includes(y.valueEditorType)) {
                filteredFields.push(y);
            }
        });
        return filteredFields.map(f => ({
            name: f?.name || f?.value,
            valueEditorType: f?.valueEditorType || value?.type,
            label: f?.label,
        }));
    }
    return (
        <Select
            optionFilterProp="children"
            className="w-100"
            dropdownClassName="zi-upModal"
            value={valueExpression}
            onChange={value => onChange(value)}
        >
            {
                getFieldsOptions()?.map((x, index) => {
                    return (
                        <Option
                            key={index}
                            value={x.name}
                            title={x.label}
                            className={"align-items-center before-muted q-mr"}
                        >
                            {x.label}
                        </Option>
                    );
                })
            }
        </Select>
    )
}

const FieldValueSelector = props => {
    const {
        className,
        handleOnChange,
        options,
        value,
        title,
        disabled,
        multiple,
        listsAsArrays,
        // Props that should not be in extraProps
        testID: _testID,
        rule: _rule,
        rules: _rules,
        level: _level,
        path: _path,
        context: _context,
        validation: _validation,
        operator: _operator,
        field: _field,
        fieldData: _fieldData,
        schema: _schema,
        ...extraProps
    } = props

    return (
        <span title={title} className={className}>
            <Select
                showSearch
                className={className}
                popupMatchSelectWidth={false}
                disabled={disabled}
                value={value}
                onChange={handleOnChange}
                options={options.map((item)=> ({...item, value: item.name}))}
                {...extraProps}
            />
        </span>
    )
}

export const RenderFieldSelector = props => (
    <Col span={8} className="field-selector-container">
        <FieldValueSelector className="w-100 mr-2" {...props} />
    </Col>
)

export const RenderFilterFieldSelector = props => (
    <Col span={6} className="field-selector-container">
        <FieldValueSelector className="w-100 mr-2" {...props} />
    </Col>
)