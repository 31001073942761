import React, { useRef, useCallback, useEffect } from "react"
import { components } from "react-select"
import { VariableSizeList as List } from "react-window"

const Row = ({ data, index, setSize }) => {
  const rowRef = useRef()

  useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height)
  }, [setSize, index])

  return <div ref={rowRef}>{data[index]}</div>
}

export const MenuList = ({ children, maxHeight }) => {
  const listRef = useRef()
  const sizeMap = useRef({})

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, [])

  const getSize = (index) => sizeMap.current[index] || 35
  const height = Math.min(maxHeight, children?.length * 35)
  return (
    <List
      ref={listRef}
      className="fs-r-select__menu-list"
      height={height}
      itemCount={children?.length}
      itemSize={getSize}
    >
      {({ index, style }) => (
        <div style={{ ...style, height: "auto" }}>
          <Row data={children} index={index} setSize={setSize} />
        </div>
      )}
    </List>
  )
}

const DropdownOptionObject = ({ children, data, ...props }) => {
  const { valueKeys = [] } = props
  const { label } = data
  return (
    <components.Option {...props}>
      {typeof label === "string"
        ? label
        : valueKeys.map((item) => {
            return (
              <div key={item}>
                <span className="text-dark">{label[item]}</span>
              </div>
            )
          })}
    </components.Option>
  )
}

export default DropdownOptionObject
