const VISIBILITY_FIELD = [
    /*  0 */ {value:'defName', label:'Name', fieldType:'text'},
    /*  1 */ {value:'defCreated_By', label:'Created By', fieldType:'user'}, // change fieldtype for testing purpose
    /*  2 */ {value:'defModified_By', label:'Modified By', fieldType:'user'},
    /*  3 */ {value:'defCreated_At', label:'Created At', fieldType:'datetime'},
    /*  4 */ {value:'defModified_At', label:'Modified At', fieldType:'datetime'},
    /*  6 */ {value:'defCompleted_At', label:'Completed At', fieldType:'datetime'},
    /*  8 */ {value:'defSubmitted_At', label:'Submitted At', fieldType:'datetime'},
    /* 10 */ {value:'defIs_Public_Form', label:'Is Public Form', fieldType:'switch'},
    /* 12 */ {value:'defExpire_At', label:'Expire At', fieldType:'datetime'},
    /* 12 */ {value:'workflow_status', label:'Workflow Status', fieldType:'workflow_status'},
];
const VISIBILITY_OPERATOR = [
    /* 0 */ {i:0, value:'Equal', label:'Equal'},
    /* 1 */ {i:1, value:'Not_equal_to', label:'Not equal to'},
    /* 2 */ {i:2, value:'Contains', label:'Contains'},
    /* 3 */ {i:3, value:'Does_not_Contain', label:'Does not Contain'},
    /* 4 */ {i:4, value:'Start_with', label:'Start with'},
    /* 5 */ {i:5, value:'End_with', label:'End with'},
    /* 6 */ {i:6, value:'Domain_equal_to', label:'Domain equal to'},
    /* 7 */ {i:7, value:'Domain_not_equal_to', label:'Domain not equal to'},
    /* 8 */ {i:8, value:'Min_length', label:'Min length'},
    /* 9 */ {i:9, value:'Max_length', label:'Max length'},
    /* 10 */ {i:10, value:'Part_of', label:'Part of'},
    /* 11 */ {i:11, value:'Not_part_of', label:'Not part of'},
    /* 12 */ {i:12, value:'Greater_than', label:'Greater than'},
    /* 13 */ {i:13, value:'Less_than', label:'Less than'},
    /* 14 */ {i:14, value:'Greater_than_or_equal_to', label:'Greater than or equal to'},
    /* 15 */ {i:15, value:'Less_than_or_equal_to', label:'Less than or equal to'},
    /* 16 */ {i:16, value:'Value_equal_to', label:'Value equal to'},
    /* 17 */ {i:17, value:'Value_not_equal_to', label:'Value not equal to'},
    /* 18 */ {i:18, value:'Value_greater_than', label:'Value greater than'},
    /* 19 */ {i:19, value:'Value_less_than', label:'Value less than'},
    /* 20 */ {i:20, value:'Value_greater_than_or_equal_to', label:'Value greater than or equal to'},
    /* 21 */ {i:21, value:'Value_less_than_or_equal_to', label:'Value less than or equal to'},
    /* 22 */ {i:22, value:'Type_is_equal_to', label:'Type is equal to'},
    /* 23 */ {i:23, value:'Type_is_not_equal_to', label:'Type is not equal to'},
    /* 24 */ {i:24, value:'Is_empty', label:'Is empty'},
    /* 25 */ {i:25, value:'Is_not_empty', label:'Is not empty'},

    /* 26 */ {i:26, value:'Selected_count_equal_to', label:'Selected count equal to'},
    /* 27 */ {i:27, value:'Selected_count_not_equal_to', label:'Selected count not equal to'},
    /* 28 */ {i:28, value:'Selected_count_greater_than', label:'Selected count greater than'},
    /* 29 */ {i:29, value:'Selected_count_lesser_than', label:'Selected count lesser than'},
    /* 30 */ {i:30, value:'Selected_count_greater_than_or_equal_to', label:'Selected count greater than or equal to'},
    /* 31 */ {i:31, value:'Selected_count_lesser_than_or_equal_to', label:'Selected count lesser than or equal to'},
];

const VISIBILITY_TYPE = [
    /* 0 */'Value',
    /* 1 */'Field'
];

const FILTERED_TEXT = [0,1,2,3,8,9,10,11,24,25]; // text
const FILTERED_NUMBER = [0,1,12,13,14,15,24,25]; // number
const FILTERED_CURRENCY = [0,1,12,13,14,15,16,17,18,19,20,21,22,23,24,25]; // currency
const FILTERED_DATE = [0,1,12,13,14,15,24,25]; // date
const FILTERED_DATETIME = [0,1,12,13,14,15,24,25]; // datetime
const FILTERED_EMAIL = [0,1,2,3,4,5,6,7,24,25]; // email
const FILTERED_SWITCH = [0,1]; // switch
const FILTERED_RATING = [0,1,12,13,14,15]; // rating
const FILTERED_DROPDOWN = [0,1,24,25]; // dropdown
const FILTERED_USER = [0,1,10,11,24,25]; // user
const FILTERED_AGGREGATION = [0,1,2,3,8,9,10,11,24,25]; // aggregation
const FILTERED_SEQUENCENUMBER = [0,1,2,3,8,9,10,11,24,25]; // sequence-number
const FILTERED_CHECKBOX = [2,3,24,25]; // checkbox
const FILTERED_SLIDER = [0,1,12,13,14,15]; // slider
const FILTERED_CHECKLIST = [2,3,26,27,28,29,30,31,24,25]; // checklist
const FILTERED_WORKFLOW_STATUS = [0,1];

const FILTERED_OPERATOR = {
    text: FILTERED_TEXT,
    number: FILTERED_NUMBER,
    currency: FILTERED_CURRENCY,
    date: FILTERED_DATE,
    datetime: FILTERED_DATETIME,
    time: FILTERED_DATETIME,
    email: FILTERED_EMAIL,
    switch: FILTERED_SWITCH,
    rating: FILTERED_RATING,
    dropdown: FILTERED_DROPDOWN,
    user: FILTERED_USER,
    aggregation: FILTERED_AGGREGATION,
    sequencenumber: FILTERED_SEQUENCENUMBER,
    checkbox: FILTERED_CHECKBOX,
    slider: FILTERED_SLIDER,
    checklist: FILTERED_CHECKLIST,
    workflow_status: FILTERED_WORKFLOW_STATUS,
};

const INCLUDED_FIELD_TYPE = [
    'text',
    'number',
    'currency',
    'date',
    'datetime',
    'email',
    'switch',
    'rating',
    'dropdown',
    'user',
    'aggregation',
    'sequence-number',
    'checkbox',
    'slider',
    'checklist',
    'calculation-text',
    'calculation-number',
    'time'
];

const VISIBILITY_CONDITION = {
    field: VISIBILITY_FIELD,
    operator: VISIBILITY_OPERATOR,
    type: VISIBILITY_TYPE,
    foperator: FILTERED_OPERATOR,
    includedType: INCLUDED_FIELD_TYPE
};

export default VISIBILITY_CONDITION;