// Date utils
// DD/MM/YYYY
// DD/MM/YYYY, HH:mm
import moment from "moment";

export const iso2utc = (date, time) => {
  let year = date.getUTCFullYear(), // getFullYear
      month = date.getUTCMonth() + 1, // getMonth
      dt = date.getUTCDate(); // getDate

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  let res = dt + "/" + month + "/" + year;

  if(time){
    res += ", " + date.getUTCHours() + ":" + date.getUTCMinutes();
  }

  return res;
}

export const generateDateTimeValue = ({ format, value, isActual }) => {
  const defaultValue = isActual
      ? new Date()
      : value
  return moment(defaultValue, format)
}

export const isActualDateTime = (maxDiff, value) => {
  const offset = Date.now();
  const backDiff = offset - value;
  const forwardDiff = value - offset;
  return (backDiff >= 0 && backDiff < maxDiff) || (backDiff < 0 && forwardDiff < maxDiff);
}

export default {
  iso2utc,
  generateDateTimeValue,
}

