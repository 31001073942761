import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Skeleton } from "antd";
import CustomSelect from "../../components/form-editor/components/Workflow/form-components/CustomSelect";
import DropdownOption from "../../components/dropdown-components/DropdownOption";
import CustomFormControl from "@components/form-editor/components/Workflow/form-components/CustomFormControl";
import { useAssigneeQuery, useAssigneeByRecordQuery, useInvalidateAssigneeByRecordQueries } from "@states/Assignee.state";
import { useParams } from "react-router-dom";
import { ModuleContext } from '../../../providers/ModuleProvider';
import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";


const ReassignForm = ({ loading, assignee, reassignFrom }) => {
    return (
        <>
            <Form.Group>
                {
                    reassignFrom && reassignFrom.length > 0? <>
                        <Form.Label>Reassign From</Form.Label>
                        <Field
                            name="reassignFrom"
                            component={CustomSelect}
                            className="flex-grow-1"
                            options={reassignFrom}
                            components={{
                                Option: DropdownOption,
                            }}
                            isMulti={false}
                            isLoading={loading}
                        />
                        <br/>
                    </>: null
                }
                
                <Form.Label>Assign To</Form.Label>
                <Field
                    name="assignTo"
                    component={CustomSelect}
                    className="flex-grow-1"
                    options={assignee}
                    components={{
                        Option: DropdownOption,
                    }}
                    isMulti={false}
                    isLoading={loading}
                />
                <br/>
                <Form.Label>Comments</Form.Label>
                <Field
                    name="comments"
                    component={CustomFormControl}
                    controlAs="textarea"
                    className="flex-grow-1"
                />
            </Form.Group>
        </>
    )
}
const ModalReassign = props => {
    const { resolveCalculation, resolving = false } = useContext(ModuleContext) || {}
    const { moduleId } = useParams()
    const { show, onClose, onSubmit, recordId } = props
    const queryConfig = useMemo(() => ({ moduleId, enabled: show }), [moduleId, show])
    const { isLoading, data: assignee } = useAssigneeQuery(queryConfig)
    const { isLoading: isLoadingReassignFrom, data: reassignFrom } = useAssigneeByRecordQuery({ moduleId, enabled: show, recordId })
    const refreshReassignFrom = useInvalidateAssigneeByRecordQueries(recordId);
    const onSubmitForm = (values, {resetForm}) => {
        const callback = async () => {
            onClose && onClose()
            await onSubmit(values);
            refreshReassignFrom()
            resetForm({
                reassignFrom: '',
                assignTo: '',
                comments: ''
            });
        }
        // If user is reassigning from a record detail,
        // wait until formula evaluation is resolved,
        // otherwise, call the callback immediately.
        if (typeof resolveCalculation === 'function') {
            resolveCalculation(callback)
        } else {
            callback()
        }
    }
    const handleOnClose = (resetForm)=>{
        resetForm()
        onClose()
    }

    return (
        <Formik
            initialValues={{
                reassignFrom: '',
                assignTo: '',
                comments: ''
            }}
            validationSchema={Yup.object({
                reassignFrom: reassignFrom && reassignFrom.length > 0? 
                    Yup.string().required('Reassign From is required'): 
                    Yup.string(),
                assignTo: Yup.string().required('Assign To is required'),
                comments: Yup.string().nullable()
                    .test("comments", "Comments cannot contain special characters", (val) => !(SPECIAL_CHAR_REGEX.test(val)))
                    .test("comments", "Comments cannot start with a space", (val) => !(/^ /.test(val))),
            })}
            onSubmit={onSubmitForm}
            enableReinitialize
        >
            {({ handleSubmit, setFieldValue, values, resetForm }) => (
                <Modal
                    show={show}
                    onHide={onClose}
                    size="lg"
                    centered
                >
                    <Modal.Header className="align-items-center">
                        <Modal.Title>Reassign Process</Modal.Title>
                        <button onClick={()=> handleOnClose(resetForm)} className="close ml-0" title="Close" type="button">&times;</button>
                    </Modal.Header>
                    <Modal.Body>
                        <Skeleton active loading={isLoading || isLoadingReassignFrom}>
                            <Form>
                                <ReassignForm 
                                    setFieldValue={setFieldValue} 
                                    values={values} 
                                    loading={isLoading || isLoadingReassignFrom}
                                    assignee={assignee}
                                    reassignFrom={reassignFrom}
                                />
                            </Form>
                        </Skeleton>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={isLoading || isLoadingReassignFrom || resolving} onClick={()=> handleOnClose(resetForm)}>Cancel</Button>
                        <Button disabled={isLoading || isLoadingReassignFrom || resolving} onClick={handleSubmit}>Save</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    )
}

export default ModalReassign

ModalReassign.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
}