import { initRequest } from "@utils/actionMethods";
import { parseJsonString } from "@utils/json"

const setTimeZone = (payload) => {
    return dispatch => dispatch({
        type: 'SET_TIME_ZONE',
        payload
    })
}
const setQuickLaunch = (payload) => {
    return dispatch => dispatch({
        type: 'SET_QUICK_APP_MODULE',
        payload
    })
}
const setUserInfo = (data) => {
    data.preference = parseJsonString(data.preference)
    return dispatch => dispatch({
        type: 'SET_USER_INFO',
        payload: data
    })
}
const getUserPreference = () => {
    return async dispatch => {
        const request = initRequest(dispatch)
        let result = { date_format:null, time_zone:null};
        await request( 'getUserInfo',null,
            ({ data }) => {
                result.date_format = data?.user?.date_format; 
                dispatch(setUserInfo(data?.user));
            }
        )
        await request('getOrgTimeZone',null,
            ({ data }) => {
                const { date_format, time_zone } = data || {};
                result.date_format = result.date_format || date_format;
                result.time_zone = (!time_zone || time_zone === "GMT Standard Time") ? "Etc/UTC" : time_zone;
            }
        )
        dispatch(setTimeZone(result));        
    }
}

const getQuickLaunchApps = () => {
    return async dispatch => {
        const request = initRequest(dispatch)
        await request( 'getQuickLaunchApps',null,
            ({ data }) => {
                if(data){   
                    dispatch(setQuickLaunch(data));
                }
            }
        )  
    }
}
const preferenceActions = {
    setTimeZone,
    setQuickLaunch,
    getUserPreference,
    getQuickLaunchApps
}

export default preferenceActions