import { initRequest } from "@utils/actionMethods";
import govHeaders from "@utils/govHeaders";

const optionsActions = {
    getOptions(optionKey, params = {}) {
        const requestMap = {
            'datasetFields': 'getDatasetFields',
        }
        const dataMap = {
            'datasetFields': option => ({
                value: option.Id,
                label: option.Name,
            })
        }
        return async dispatch => {
            const request = initRequest(dispatch)
            return await request(
                requestMap[optionKey],
                params,
                (data = []) => {
                    const payload = data.map(dataMap[optionKey])
                    dispatch({
                        type: 'SET_OPTIONS',
                        payload: {
                            optionKey,
                            optionList: payload,
                        },
                    })
                },
            )
        }
    },
    getGovHeaders() {
        return async (dispatch, getState) => {
            const request = initRequest(dispatch)
            const { auth } = getState()
            const { username } = auth?.config?.userInfo || {}
            const frontendHeaders = await govHeaders(username) || {}
            const backendHeaders = await request('getGovHeaders')
            let headers = frontendHeaders
            if (backendHeaders?.data && Array.isArray(backendHeaders.data)) {
                const beHeaders = backendHeaders.data
                headers = [
                    ...headers,
                    ...beHeaders,
                ]
            }

            if (headers) {
                dispatch({
                    type: 'SET_GOV_HEADERS',
                    payload: headers,
                })
            }
        }
    },
}

export default optionsActions