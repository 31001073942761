import { useEffect } from "react";

const useRemoveSubheader = () => {
    useEffect(() => {
        document.body.classList.add('page--no-subheader')
        document.body.classList.remove('subheader-enabled')
        return () => {
            document.body.classList.remove('page--no-subheader')
            document.body.classList.add('subheader-enabled')
        }
    }, [])
}

export default useRemoveSubheader