import { FunctionPlugin } from "hyperformula";
import { initRequest } from "@utils/actionMethods";
export class GetSummaryApp extends FunctionPlugin {
    request = initRequest()
    validateValue (result, summaryType) {
        const defaultValue = result ?? ""
        return summaryType !== 'concatenate' && isNaN(result) ? 0 : defaultValue
    }
    aggregateValue(config, filters = '', matchesAll) {
        const encodeConfig = btoa(JSON.stringify(config))
        let localStorageKey = matchesAll != undefined && filters
            ? `${encodeConfig},"${filters}", "${matchesAll}"`
            : filters? `${encodeConfig},"${filters}"`
            : encodeConfig
        localStorageKey = `sumappValue_${localStorageKey}`
        const summaryType = config.aggregationType.toLowerCase()
        const result = localStorage.getItem(localStorageKey)
        return this.validateValue(result, summaryType)
    }

    getSummaryApp(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('GETSUMMARYAPP'), (...args) => {
            const [moduleId, tableName, fieldToAggregate, aggregationType, filters, matchesAll] = args
            const isTableLookup = tableName === undefined || tableName === 'undefined'? false: true
            const result = this.aggregateValue({ 
                moduleId, 
                tableName: isTableLookup? tableName: undefined, 
                fieldToAggregate, 
                aggregationType, 
                isTableLookup: isTableLookup,
            }, filters, matchesAll)
            return result
          })
    }
}

GetSummaryApp.implementedFunctions = {
    'GETSUMMARYAPP': {
        method: 'getSummaryApp',
        parameters: [
            {argumentType: "STRING"},
        ],
        repeatLastArgs: 1,
        isVolatile: true,
    }
}