import { initRequest } from "@utils/actionMethods";
import { aggregateLookupPayload } from "@utils/form-editor/normalize-payload";

const LOADING = 'LOADING'

const generateSumAppStorageKey = (config, filter, fieldValueMap) => {
    const identifier = 'sumappValue_'
    const { encodedFilter } = encodeFilters(filter, fieldValueMap)
    const localStorageKey = `${btoa(JSON.stringify(config))}${encodedFilter}`
    return `${identifier}${localStorageKey}`
}

const calculateAggregation = async (formula, fieldValueMap) => {
    const summaryApp = formula.match(/SUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/i)
    const summaryAppWithFilter = formula.match(/DSUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, ]*([\w_.]+[!=<>]+["]*[\w_ ]+["]*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/i)
    const summaryAppWithMultiFilter = formula.match(/DSUMAPP\(([\w_]+)\.+([\w_]+)\.+([\w_]+)[, ]*((and|or)*\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+[, ]*)+\)*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/i)
    const summaryAppSectionWithFilter = formula.match(/DSUMAPP\(([\w_]+)\.+([\w_]+)[, ]*([\w_.]+[!=<>]+["]*[\w_ ]+["]*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/i)
    const summaryAppSectionWithMultiFilter = formula.match(/DSUMAPP\(([\w_]+)\.+([\w_]+)[, ]*((and|or)*\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+[, ]*)+\)*)*[, "]*(concatenate|sum|min|max|average|count)*[ "]*\)/i)
    const matchConfig = summaryApp || summaryAppWithFilter || summaryAppWithMultiFilter || summaryAppSectionWithFilter || summaryAppSectionWithMultiFilter
    if (matchConfig && Array.isArray(matchConfig)) {
        let moduleId, tableName, fieldToAggregate, aggregationType = 'sum', filter
        if (summaryApp) {
            [, moduleId, tableName, fieldToAggregate, aggregationType = 'sum'] = matchConfig
        } else if(summaryAppWithFilter) {
            [, moduleId, tableName, fieldToAggregate, filter, aggregationType = 'sum'] = matchConfig
        } else if(summaryAppWithMultiFilter){
            [, moduleId, tableName, fieldToAggregate, filter, , , , aggregationType = 'sum'] = matchConfig
        } else if(summaryAppSectionWithFilter){
            [, moduleId, fieldToAggregate, filter, aggregationType = 'sum'] = matchConfig
        } else if(summaryAppSectionWithMultiFilter){
            [, moduleId, fieldToAggregate, filter, , , , aggregationType = 'sum'] = matchConfig
        }
        const config = { 
            moduleId, 
            tableName, 
            fieldToAggregate, 
            aggregationType, 
            isTableLookup: tableName? true: false,
        }
        const request = initRequest()
        const { rawFilter, matchesAll } = encodeFilters(filter, fieldValueMap)
        const localStorageKey = generateSumAppStorageKey(config, filter, fieldValueMap)
        let result = localStorage.getItem(localStorageKey)
        if (!result) {
            localStorage.setItem(localStorageKey, LOADING)
            try {
                const payload = {...config}
                payload.filters = rawFilter
                    ? rawFilter
                    : []
                const response = await request(
                    'aggregateLookup',
                    { data: aggregateLookupPayload(payload, matchesAll) },
                )
                const { data, success } = response || {}
                if (success && data != undefined) {
                    localStorage.setItem(localStorageKey, data)
                }
            } catch (e) {
                console.error(e)
            }
        }
    }
}

const operatorMap = {
    '=': 'Equal to',
    '!=': 'Not equal to',
    '<': 'Less than',
    '>': 'Greater than',
    '<=': 'Less than or equal to',
    '>=': 'Greater than or equal to',
}

const generateRawFilterObject = (filter, fieldValueMap) => {
    try{
        const isString = filter && filter.includes(`"`)
        const criterias = filter && filter.match(/([\w_.]+)+([!=<>]+)"*([\w_ ]+)"*/i)
        if (criterias) {
            let [, cField, cOperator, cValue] = criterias
            if(!isString && !isNaN(Number(cValue))){
                cValue = Number(cValue)
            }
            if(!isString && isNaN(Number(cValue))){
                // from current form field
                if(fieldValueMap && cValue){
                    cValue = fieldValueMap[cValue]
                }
            }
            if(cField && cField.match(/([\w_]+)\.+([\w_]+)\.+([\w_])+/i)){
                cField = cField.split('.').pop()
            }
            if(cField && cField.match(/([\w_]+)\.+([\w_])+/i)){
                cField = cField.split('.').pop()
            }
            return {
                field: cField,
                operator: operatorMap[cOperator],
                value: cValue,
            }
        }
    } catch(_) {}
    return null
}

export const encodeFilters = (filter, fieldValueMap) => {
    let encodedFilter = ''
    let rawFilter = null
    let matchesAll = true
    try{
        if(filter && filter.match(/((and|or)+\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+,*)+\)*)/i)){
            let filterList = (filter.replace(/(and|or)+/i, '').replaceAll('(', '').replaceAll(')', '')).split(',')
            if(filter && filter.match(/((or)+\(*(([\w_.]+[!=<>]+["]*[\w_ ]+["]*)+,*)+\)*)/i)){
                matchesAll = false
            }
            if(filterList && Array.isArray(filterList)){
                rawFilter = []
                filterList.forEach(item => {
                    const rawFilterObj = generateRawFilterObject(item, fieldValueMap)
                    if(rawFilterObj) rawFilter.push(rawFilterObj)
                });
                encodedFilter = `,"${btoa(JSON.stringify(rawFilter))}", "${matchesAll}"`
            }
        } else {
            const rawFilterObj = generateRawFilterObject(filter, fieldValueMap)
            if(rawFilterObj){
                rawFilter = [rawFilterObj]
                encodedFilter = `,"${btoa(JSON.stringify(rawFilter))}"`
            }
        }
    } catch(_) {}
    return {
        rawFilter,
        encodedFilter,
        matchesAll,
    }
}

export default calculateAggregation