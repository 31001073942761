import { useQuery, useQueryClient } from "@tanstack/react-query"
import useApiRequest from "@utils/hooks/useApiRequest"

export const getAssigneeQueryKey = (moduleId) => ['assignee', moduleId]
export const getAssigneeByRecordQueryKey = (recordId) => ['assigneeByRecord', recordId]

export const fetchAssignee = (moduleId, request) => {
    return request('getAssignee', { moduleId })
}
export const fetchAssigneeByRecord = (moduleId, recordId, request) => {
    return request('getAssigneeByRecord', { moduleId, recordId })
}

export const useAssigneeQuery = ({ moduleId, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = getAssigneeQueryKey(moduleId)
    return useQuery(
        queryKey,
        () => {
            if (enabled) {
                return fetchAssignee(moduleId, request)
            }
            return []
        },
        {
            select: (response) => {
                const { data } = response || {}
                const list = data?.items || []
                return list.map(a => ({
                    value: a?.id,
                    label: a?.name,
                    type: a?.type,
                }))
            },
            suspense: false,
            enabled: typeof moduleId !== undefined && enabled,
        }
    )
}

export const useAssigneeByRecordQuery = ({ moduleId, recordId, enabled }) => {
    const { request } = useApiRequest()
    const queryKey = getAssigneeByRecordQueryKey(recordId)
    return useQuery(
        queryKey,
        () => {
            if (enabled && recordId) {
                return fetchAssigneeByRecord(moduleId, recordId, request)
            }
            return []
        },
        {
            select: (response) => {
                const { data } = response || {}
                const list = data?.items || []
                return list.map(a => ({
                    value: a?.id,
                    label: a?.name,
                    type: a?.type,
                }))
            },
            suspense: false,
            enabled: typeof moduleId !== undefined && enabled,
        }
    )
}

export const useInvalidateAssigneeByRecordQueries = (recordId) => {
    const queryClient = useQueryClient();
    const queryKey = getAssigneeByRecordQueryKey(recordId)
    return () => {
      queryClient.invalidateQueries(queryKey);
    };
  };
  