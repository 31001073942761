import { FunctionPlugin } from "hyperformula";

export class DuplicateCheck extends FunctionPlugin {
    duplicateCheck(ast, state) {
        return this.runFunction(ast.args, state, this.metadata('DUPLICATECHECK'), (...args) => {
            const [...columnValues] = args
            let result = false
            try {
                const filterValue = columnValues.filter(x => x)
                result = filterValue.length > 0
            } catch (e) { }
            return result
        })
    }
}

DuplicateCheck.implementedFunctions = {
    'DUPLICATECHECK': {
        method: 'duplicateCheck',
        parameters: [
            {argumentType: "STRING"},
        ],
        repeatLastArgs: 1,
    }
}