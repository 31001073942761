import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import CloseButton from "@components/CloseButton";
import Flex from '@components/Flex';
import Img from '@components/Img';
import NoImage from '@components/NoImage';
import Carousel from 'react-bootstrap/Carousel';
import { EXT_IMG } from '@constants/FILE_EXT';

const ModalAttachment = props => {
    const { onClose, show, value } = props;
    const [slide, setSlide] = useState(0);
    
    useEffect(() => {
      if (value !== null) {
        setSlide(value?.selected)
      }
    }, [value?.selected])
    
    const renderArrow = i => <i className={"text-dark txt-outline-fff fa-2x fal fa-chevron-circle-" + i} />;
    return (
        <Modal 
            centered 
            size="lg" 
            restoreFocus={false} 
            className="modal-full-sm" // fs-modalFilePreview
            show={show} 
            onHide={onClose} 
        >
            <Modal.Header className="py-3 px-4">
                <h5>Preview File</h5>
                <CloseButton onClick={onClose} ariaLabel="Close" className="ml-auto" />
            </Modal.Header>
            <div className="modal-body p-0">
                {value && 
                    <Carousel 
                        interval={null} 
                        indicators={false} 
                        slide={false} 
                        wrap={false} 
                        prevIcon={renderArrow("left")} 
                        nextIcon={renderArrow("right")} 
                        activeIndex={slide} 
                        onSelect={(idx) => setSlide(idx)} 
                    >
                    {(Array.isArray(value?.files) ? value?.files : [value?.files]).map((v, i) =>
                        <Carousel.Item key={i} className="h-85vh">
                            <Flex dir="column" className="w-100 h-85vh">
                                {EXT_IMG.includes(v?.name?.split(".").pop().toLowerCase()) ? 
                                    <Img frame fluid frameClass="w-100" //  h-100 flex-grow-1
                                        wrapProps={{ style: { height: 'calc(100% - 39px)' } }} 
                                        className="d-block w-100 h-75vh of-con" 
                                        alt="Preview" 
                                        src={v.url || v || window.URL.createObjectURL(v?.originFileObj)} 
                                        onLoad={e => v.status !== "done" && window.URL.revokeObjectURL(e.target.src)} 
                                    />
                                : 
                                    <NoImage val={v} size={5} iconClass={null} />
                                }
                                <div className="p-3 flexno text-center">
                                    <a className="d-inline-block mx-3" download={v.name} 
                                        href={v.url || v || window.URL.createObjectURL(v?.originFileObj)} 
                                    >
                                        <i className="far fa-cloud-download d-inline-block align-middle"/>
                                        <span className="d-inline-block align-middle ml-2">Download</span>
                                    </a>
                                    <a className="d-inline-block mx-3" href={v?.url} target="_blank" rel="noopener noreferrer">
                                        <i className="far fa-external-link-square d-inline-block align-middle"/>
                                        <span className="d-inline-block align-middle ml-2">Open in new tab</span>
                                    </a>
                                </div>
                            </Flex>
                        </Carousel.Item>
                    )}
                    </Carousel>
                }
            </div>
        </Modal>
    );
}

ModalAttachment.propTypes = {
    onClose: PropTypes.func,
};

export default ModalAttachment;
