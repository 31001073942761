export const FIELD_VALUE_VALIDATIONS = [
    /*  0 */'Contains',
    /*  1 */'Equal to',
    /*  2 */'Min length',
    /*  3 */'Not part of',
    /*  4 */'Does not contain',
    /*  5 */'Max length',
    /*  6 */'Not equal to',
    /*  7 */'Part of',
    /*  8 */'Greater than or equal to',
    /*  9 */'Less than or equal to',
    /* 10 */'Greater than',
    /* 11 */'Less than',
    /* 12 */'Value Equal to',
    /* 13 */'Value Greater than or equal to',
    /* 14 */'Value Less than or equal to',
    /* 15 */'Value Greater than',
    /* 16 */'Value Less than',
    /* 17 */'Value Not equal to',
    /* 18 */'Domain equal to',
    /* 19 */'Domain not equal to',
    /* 20 */'End with',
    /* 21 */'Start with',
];
export const PLAIN_VALIDATIONS = [
    /* 22 */'URL format',
    /* 23 */'Letters only',
    /* 24 */'No special characters',
    /* 25 */'Number only',
    /* 26 */'Email format',
    /* 27 */'In the past',
    /* 28 */'Not in the past',
    /* 29 */'In the future',
    /* 30 */'Yes',
    /* 31 */'No',
];
export const OTHER_VALIDATIONS = [
    /* 32 */'Attachment file type',
    /* 33 */'Max attachment size',
    /* 34 */'Max number of attachments',
    /* 35 */'Min number of attachments',
    /* 36 */'Max number of selections',
    /* 37 */'Min number of selections',
    /* 38 */'Selection should contain',
    /* 39 */'Selection should not contain',
    /* 40 */'Select all'
];

export const NEW_PLAIN_VALIDATIONS = [ 
    /* 41 */'Not in the future',
]
const VALIDATION_TYPES = [
    ...FIELD_VALUE_VALIDATIONS,
    ...PLAIN_VALIDATIONS,
    ...OTHER_VALIDATIONS,
    ...NEW_PLAIN_VALIDATIONS
];
export default VALIDATION_TYPES;